import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { estimateRequestReducer } from './request/reducer';
import estimateRequestSaga from './request/sagas';
import { EstimateRequestState } from './request/types';

export interface EstimateState {
    readonly estimateRequest: EstimateRequestState;
}

export const EstimateReducer: ReducersMapObject<EstimateState, AnyAction> = {
    estimateRequest: estimateRequestReducer
};

export function* estimateSagas() {
    yield all([fork(estimateRequestSaga)]);
}
