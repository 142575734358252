import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { compose } from 'redux';
import DelayedSlide from '../../../../components/animation/delayed-slide/delayed-slide';
import StepProgress from '..//step-progress/step-progress';
import {
    COLLAPSE_TIMEOUT,
    SLIDE_IN_DEFAULT,
    SLIDE_IN_DELAY,
    SLIDE_IN_FAST,
    SLIDE_OUT_DEFAULT,
    SLIDE_STOP
} from '../animation/animation-constants';
import { withContactContext } from '../contact/contact-context';
import { useEmailContext, withEmailContext } from '../edit-email/edit-email-context';
import { withIdentificationContext } from '../identification-step/identification-context';
import { StepsEnum, useSteps, withStepsContext } from './invite-steps-context';
import './invite-steps.scss';
import ResponsiveStep from './responsive-step';

export interface InviteStepsProps {
    stepsRecord: Record<StepsEnum, React.ReactNode>;
    token: string;
}

export const InviteSteps = (props: InviteStepsProps): JSX.Element => {
    const steps = useSteps();
    const emailContext = useEmailContext();

    React.useEffect(() => {
        emailContext.setToken(props.token);
    }, [emailContext, props.token]);

    return (
        <>
            {steps.isMobile && (
                <>
                    {steps.current === StepsEnum.WELCOME && props.stepsRecord[StepsEnum.WELCOME]}
                    {steps.current !== StepsEnum.WELCOME && (
                        <div className="responsive-step">
                            <div className="responsive-step--text">
                                <ResponsiveStep
                                    label="accept-invite.step.identification"
                                    isOpen={
                                        steps.current === StepsEnum.IDENTIFICATION_USER ||
                                        steps.current === StepsEnum.IDENTIFICATION_PERSON ||
                                        steps.current === StepsEnum.EDIT_EMAIL
                                    }
                                    isCompleted={steps.isCompleted(StepsEnum.IDENTIFICATION_USER)}
                                    onEdit={() => steps.toStep(StepsEnum.IDENTIFICATION_USER)}
                                >
                                    <Collapse
                                        in={
                                            steps.current === StepsEnum.IDENTIFICATION_USER ||
                                            steps.current === StepsEnum.IDENTIFICATION_PERSON ||
                                            steps.current === StepsEnum.EDIT_EMAIL
                                        }
                                        timeout={COLLAPSE_TIMEOUT}
                                        unmountOnExit
                                    >
                                        <DelayedSlide
                                            direction="right"
                                            delay={
                                                steps.previous === StepsEnum.EDIT_EMAIL ||
                                                steps.current === StepsEnum.EDIT_EMAIL ||
                                                steps.previous === StepsEnum.IDENTIFICATION_PERSON ||
                                                steps.current === StepsEnum.IDENTIFICATION_PERSON
                                                    ? SLIDE_IN_DELAY
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.IDENTIFICATION_USER}
                                            timeout={{
                                                enter:
                                                    steps.previous === StepsEnum.EDIT_EMAIL ||
                                                    steps.previous === StepsEnum.IDENTIFICATION_PERSON
                                                        ? SLIDE_IN_DEFAULT
                                                        : SLIDE_IN_FAST,
                                                exit:
                                                    steps.current === StepsEnum.EDIT_EMAIL ||
                                                    steps.current === StepsEnum.IDENTIFICATION_PERSON
                                                        ? SLIDE_OUT_DEFAULT
                                                        : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.IDENTIFICATION_USER]}</div>
                                        </DelayedSlide>
                                        <DelayedSlide
                                            direction="left"
                                            delay={
                                                steps.previous === StepsEnum.IDENTIFICATION_USER ||
                                                steps.current === StepsEnum.IDENTIFICATION_USER
                                                    ? SLIDE_IN_DELAY
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.IDENTIFICATION_PERSON}
                                            timeout={{
                                                enter: steps.previous === StepsEnum.IDENTIFICATION_USER ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                                                exit: steps.current === StepsEnum.IDENTIFICATION_USER ? SLIDE_OUT_DEFAULT : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.IDENTIFICATION_PERSON]}</div>
                                        </DelayedSlide>
                                        <DelayedSlide
                                            direction="left"
                                            delay={SLIDE_IN_DELAY}
                                            in={steps.current === StepsEnum.EDIT_EMAIL}
                                            timeout={{
                                                enter: SLIDE_IN_DEFAULT,
                                                exit: SLIDE_OUT_DEFAULT
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.EDIT_EMAIL]}</div>
                                        </DelayedSlide>
                                    </Collapse>
                                </ResponsiveStep>
                            </div>
                            <div className="responsive-step--text">
                                <ResponsiveStep
                                    label="accept-invite.step.password"
                                    isOpen={steps.current === StepsEnum.PASSWORD_ONLY || steps.current === StepsEnum.CONFIRM_ONLY}
                                    isCompleted={steps.isCompleted(StepsEnum.CONFIRM_ONLY)}
                                    onEdit={() => steps.toStep(StepsEnum.PASSWORD_ONLY)}
                                >
                                    <Collapse
                                        in={steps.current === StepsEnum.PASSWORD_ONLY || steps.current === StepsEnum.CONFIRM_ONLY}
                                        timeout={COLLAPSE_TIMEOUT}
                                        unmountOnExit
                                    >
                                        <DelayedSlide
                                            direction="right"
                                            delay={
                                                steps.previous === StepsEnum.CONFIRM_ONLY || steps.current === StepsEnum.CONFIRM_ONLY
                                                    ? SLIDE_IN_DELAY
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.PASSWORD_ONLY}
                                            timeout={{
                                                enter: steps.previous === StepsEnum.CONFIRM_ONLY ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                                                exit: steps.current === StepsEnum.CONFIRM_ONLY ? SLIDE_OUT_DEFAULT : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.PASSWORD_ONLY]}</div>
                                        </DelayedSlide>
                                        <DelayedSlide
                                            direction="left"
                                            delay={
                                                steps.previous === StepsEnum.PASSWORD_ONLY || steps.current === StepsEnum.PASSWORD_ONLY
                                                    ? SLIDE_IN_DELAY
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.CONFIRM_ONLY}
                                            timeout={{
                                                enter: steps.previous === StepsEnum.PASSWORD_ONLY ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                                                exit: steps.current === StepsEnum.PASSWORD_ONLY ? SLIDE_OUT_DEFAULT : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.CONFIRM_ONLY]}</div>
                                        </DelayedSlide>
                                    </Collapse>
                                </ResponsiveStep>
                            </div>
                            <div className="responsive-step--text">
                                <ResponsiveStep
                                    label="accept-invite.step.contact"
                                    isOpen={steps.current === StepsEnum.CONTACT_INFORMATIVE || steps.current === StepsEnum.CONTACT_PHONE}
                                    isCompleted={steps.isCompleted(StepsEnum.CONTACT_INFORMATIVE)}
                                    onEdit={() => steps.toStep(StepsEnum.CONTACT_INFORMATIVE)}
                                >
                                    <Collapse
                                        in={steps.current === StepsEnum.CONTACT_INFORMATIVE || steps.current === StepsEnum.CONTACT_PHONE}
                                        timeout={COLLAPSE_TIMEOUT}
                                        unmountOnExit
                                    >
                                        <DelayedSlide
                                            direction="right"
                                            delay={
                                                steps.previous === StepsEnum.CONTACT_PHONE || steps.current === StepsEnum.CONTACT_PHONE
                                                    ? SLIDE_IN_DEFAULT * 0.5
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.CONTACT_INFORMATIVE}
                                            timeout={{
                                                enter: steps.previous === StepsEnum.CONTACT_PHONE ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                                                exit: steps.current === StepsEnum.CONTACT_PHONE ? SLIDE_OUT_DEFAULT : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.CONTACT_INFORMATIVE]}</div>
                                        </DelayedSlide>
                                        <DelayedSlide
                                            direction="left"
                                            delay={
                                                steps.previous === StepsEnum.CONTACT_INFORMATIVE ||
                                                steps.current === StepsEnum.CONTACT_INFORMATIVE
                                                    ? SLIDE_IN_DELAY
                                                    : SLIDE_IN_FAST
                                            }
                                            in={steps.current === StepsEnum.CONTACT_PHONE}
                                            timeout={{
                                                enter: steps.previous === StepsEnum.CONTACT_INFORMATIVE ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                                                exit: steps.current === StepsEnum.CONTACT_INFORMATIVE ? SLIDE_OUT_DEFAULT : SLIDE_STOP
                                            }}
                                            unmountOnExit
                                        >
                                            <div>{props.stepsRecord[StepsEnum.CONTACT_PHONE]}</div>
                                        </DelayedSlide>
                                    </Collapse>
                                </ResponsiveStep>
                            </div>
                            <div className="responsive-step--text">
                                <ResponsiveStep
                                    label="accept-invite.step.terms-of-use"
                                    isOpen={steps.current === StepsEnum.TERMS_OF_USE}
                                    isCompleted={steps.isCompleted(StepsEnum.TERMS_OF_USE)}
                                    onEdit={() => steps.toStep(StepsEnum.TERMS_OF_USE)}
                                >
                                    <Collapse in={steps.current === StepsEnum.TERMS_OF_USE} timeout={COLLAPSE_TIMEOUT} unmountOnExit>
                                        {props.stepsRecord[StepsEnum.TERMS_OF_USE]}
                                    </Collapse>
                                </ResponsiveStep>
                            </div>
                        </div>
                    )}
                </>
            )}

            {!steps.isMobile && (
                <>
                    <StepProgress />
                    {props.stepsRecord[StepsEnum[steps.current]]}
                </>
            )}
        </>
    );
};

export default compose(
    withStepsContext(),
    withIdentificationContext(),
    withEmailContext(),
    withContactContext()
)(InviteSteps) as React.ComponentType<any>;
