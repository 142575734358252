import React from 'react';
import { FixedSizeList } from 'react-window';
import { SIMULATION_INVOICES_HEIGHT, SIMULATION_INVOICES_ITEM_HEIGHT, SIMULATION_INVOICES_WIDTH } from '../constants';
import { useSimulationInfoContext } from '../simulation-info/simulation-info-context';
import InvoiceListItem from './invoice-list-item';

export const InvoiceList = () => {
    const { estimates } = useSimulationInfoContext();

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const itemCount: number = estimates && estimates.length;

    if (!estimates || estimates.length === 0) {
        return <></>;
    }

    return (
        <FixedSizeList
            className="invoice-list__overflow"
            itemData={estimates}
            itemCount={itemCount}
            itemKey={itemKey}
            height={SIMULATION_INVOICES_HEIGHT}
            width={SIMULATION_INVOICES_WIDTH}
            itemSize={SIMULATION_INVOICES_ITEM_HEIGHT}
        >
            {p => <InvoiceListItem {...p} />}
        </FixedSizeList>
    );
};

export default InvoiceList;
