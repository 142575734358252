import React from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../components/loading/loading';
import { Authority } from '../../../../model/enums/authorities';
import { ErrorType } from '../../../../model/enums/error-constants';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import HttpStatus from '../../../../model/enums/httpStatus';
import { GiroApiError } from '../../../../model/error';
import { hasAcceptedTermsRequest, hasAcceptedTermsResetState } from '../../../../reducer/account/terms-of-use/actions';
import { userAccountRequest, userAccountResetState } from '../../../../reducer/account/user-account/actions';
import { customizeErrorHandling } from '../../../../reducer/application/error/actions';
import { defaultCompanyResetState } from '../../../../reducer/company/default/actions';
import { useAuthenticationState, useRootDispatch, useTermsOfUseState, useUserAccountState } from '../../../../reducer/hooks';
import AuthUtil from '../../../../services/api/authUtil';
import { useHandleUserNotActivated } from '../../../../shared/hooks/user-not-activated';
import './auto-login.scss';

const useAccount = () => {
    const dispatch = useRootDispatch();
    const authenticationState = useAuthenticationState();
    const [isAuthenticated] = React.useState<boolean>(AuthUtil.isAuthenticated());

    React.useEffect(() => {
        if (isAuthenticated) {
            dispatch(userAccountRequest());
        }
    }, [isAuthenticated, authenticationState.status, dispatch]);
};

const useValidateTerms = () => {
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    React.useEffect(() => {
        if (
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.account?.activated &&
            userAccountState.account?.authorities?.find(it => it === Authority.ROLE_PROVIDER)
        ) {
            dispatch(hasAcceptedTermsRequest());
        }
    }, [userAccountState, dispatch]);
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(dispatch(customizeErrorHandling({ defaultType: ErrorType.BLOCKING })));
    }, [dispatch]);
};

const useErrorDetection = () => {
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();
    const [hasError, setError] = React.useState<boolean>(false);
    const [httpStatus, setHttpStatus] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.ERROR || termsOfUseState.status === HttpRequestStatus.ERROR) {
            setError(true);
            const accountStatus: number | false | undefined =
                userAccountState.status === HttpRequestStatus.ERROR && (userAccountState.error as GiroApiError).httpStatus;
            const termsStatus: number | false | undefined =
                termsOfUseState.status === HttpRequestStatus.ERROR && (termsOfUseState.error as GiroApiError).httpStatus;
            setHttpStatus(accountStatus || termsStatus || undefined);
        } else {
            setError(false);
            setHttpStatus(undefined);
        }
    }, [userAccountState, termsOfUseState]);

    return { hasError, httpStatus };
};

export const AutoLogin = () => {
    const history = useHistory();
    const dispatch = useRootDispatch();

    useErrorConfiguration();
    useAccount();
    useValidateTerms();
    useHandleUserNotActivated();

    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    const { hasError, httpStatus } = useErrorDetection();

    React.useEffect(() => {
        if (
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            !userAccountState.account?.authorities?.find(it => it === Authority.ROLE_PROVIDER)
        ) {
            history.push('/giro-api');
        }
    }, [userAccountState, history]);

    React.useEffect(() => {
        if (hasError && [HttpStatus.BAD_REQUEST, HttpStatus.INTERNAL_SERVER_ERROR].find(it => it === httpStatus)) {
            AuthUtil.removeToken();
            dispatch(userAccountResetState());
            dispatch(hasAcceptedTermsResetState());
            dispatch(defaultCompanyResetState());
            history.replace('/');
        }
    }, [hasError, httpStatus, history, dispatch]);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.SUCCESS && termsOfUseState.status === HttpRequestStatus.SUCCESS) {
            history.push(termsOfUseState.terms?.acceptedTerms ? '/dashboard' : '/accept-terms-of-use');
        }
    }, [userAccountState.status, termsOfUseState, history]);

    return (
        <div className="auto-login__loading">
            <Loading />
        </div>
    );
};

export default AutoLogin;
