import React from 'react';
import DelayedSlide from '../../../../components/animation/delayed-slide/delayed-slide';
import { SLIDE_IN_DEFAULT, SLIDE_IN_DELAY, SLIDE_IN_FAST, SLIDE_OUT_DEFAULT } from '../../animation-constants';
import ConfirmOnly from '../confirm-only/confirm-only';
import PasswordOnly from '../password-only/password-only';

export interface ResetResponsiveProps {
    username: string;
    isLoading: boolean;
    onFinish: (password: string, confirm: string) => void;
}

enum Steps {
    PASSWORD = 'PASSWORD',
    CONFIRM = 'CONFIRM'
}

export const ResetResponsive = (props: ResetResponsiveProps) => {
    const [step, setStep] = React.useState<Steps>(Steps.PASSWORD);
    const [password, setPassword] = React.useState<string>('');

    const handleNext = (_password: string) => {
        setPassword(_password);
        setStep(Steps.CONFIRM);
    };

    const handlePrevious = () => {
        setStep(Steps.PASSWORD);
    };

    return (
        <>
            <DelayedSlide
                direction="right"
                delay={SLIDE_IN_DELAY}
                in={step === Steps.PASSWORD}
                timeout={{
                    enter: password ? SLIDE_IN_DEFAULT : SLIDE_IN_FAST,
                    exit: SLIDE_OUT_DEFAULT
                }}
                unmountOnExit
            >
                <div>
                    <PasswordOnly username={props.username} password={password} onNext={handleNext} />
                </div>
            </DelayedSlide>
            <DelayedSlide
                direction="left"
                delay={SLIDE_IN_DELAY}
                in={step === Steps.CONFIRM}
                timeout={{
                    enter: SLIDE_IN_DEFAULT,
                    exit: SLIDE_OUT_DEFAULT
                }}
                unmountOnExit
            >
                <div>
                    <ConfirmOnly
                        username={props.username}
                        password={password}
                        isLoading={props.isLoading}
                        onPrevious={handlePrevious}
                        onFinish={props.onFinish}
                    />
                </div>
            </DelayedSlide>
        </>
    );
};

export default ResetResponsive;
