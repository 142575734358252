import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './header.scss';

export const SimpleHeader = () => {
    return (
        <div className="container__header">
            <Container>
                <Row>
                    <Col md="12" xs="12">
                        <div className="header">
                            <div className="header--logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SimpleHeader;
