import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserEmailValidation } from '../../../model/user';
import userApi from '../../../services/api/userApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { validateEmailError, validateEmailRequest, validateEmailSuccess } from './actions';
import { ValidateEmailActionTypes } from './types';


function* handleValidateEmail(action: ReturnType<typeof validateEmailRequest>) {
  try {
    const result: AxiosResponse<UserEmailValidation> = yield call(userApi.validateEmail, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(validateEmailError(mapped));
      return;
    }
    yield put(validateEmailSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(validateEmailError(mapped));
  }
}

function* watchValidateEmail() {
  yield takeEvery(ValidateEmailActionTypes.VALIDATE_EMAIL_REQUEST, handleValidateEmail);
}



function* validateEmailSaga() {
  yield all([
    fork(watchValidateEmail),
  ]);
}

export default validateEmailSaga;
