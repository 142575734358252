import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_ZA from '../i18n/custom_organization/en-za.json';
import en from '../i18n/custom_organization/en.json';
import pt_BR from '../i18n/custom_organization/pt-br.json';
import pt_PT from '../i18n/custom_organization/pt-pt.json';
import { DEFAULT_LANGUAGE } from './constants';

/**
 *
 * Make sure to reflect changes in custom organization configuration
 * @see /custom/organization/config.js
 * Should use hifen if need, avoid underscore
 * @example pt-BR instead of pt_BR
 * @link https://github.com/i18next/i18next/issues/1061#issuecomment-395528467
 */
const resources = {
    en: { translation: en },
    'en-ZA': { translation: en_ZA },

    'pt-BR': { translation: pt_BR },
    'pt-PT': { translation: pt_PT }
};

const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANGUAGE,
    debug: true,

    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
