import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ValidateEmailActionTypes, ValidateEmailState } from './types';

export const initialState: ValidateEmailState = {
  status: HttpRequestStatus.NOOP,
  user: undefined,
  error: undefined
};

const reducer: Reducer<ValidateEmailState> = (state = initialState, action): ValidateEmailState => {
  switch (action.type) {
    case ValidateEmailActionTypes.VALIDATE_EMAIL_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, user: undefined, error: undefined };
    }
    case ValidateEmailActionTypes.VALIDATE_EMAIL_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
    }
    case ValidateEmailActionTypes.VALIDATE_EMAIL_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    case ValidateEmailActionTypes.VALIDATE_EMAIL_CLEAN_STATUS: {
      return { ...state, status: HttpRequestStatus.NOOP }
    }
    case ValidateEmailActionTypes.VALIDATE_EMAIL_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as validateEmailReducer };

