import React from 'react';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { UserToChangePassword } from '../../model/user';
import { changePasswordRequest } from '../../reducer/account/change-password/actions';
import { useChangePasswordState, useRootDispatch } from '../../reducer/hooks';

export interface ProfileContextProps {
    isEditing: boolean;
    showValidation: boolean;
    changePassword: UserToChangePassword;
    isUpdating: boolean;
    updateSuccess: boolean;
    handlePasswordChange: (update: Partial<UserToChangePassword>) => void;
    setIsEditing: (value: boolean) => void;
    handleSave: () => void;
    messageVisibility: boolean;
    setMessageVisibility: (value: boolean) => void;
    disabled: string;
    setShowValidation: (value: boolean) => void;
}

export const ProfileContext = React.createContext<ProfileContextProps>({} as ProfileContextProps);

export const ProfileProvider: React.FunctionComponent = props => {
    const dispatch = useRootDispatch();
    const changePasswordState = useChangePasswordState();

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [changePassword, setChangePassword] = React.useState<UserToChangePassword>({} as UserToChangePassword);
    const [showValidation, setShowValidation] = React.useState<boolean>(false);
    const [messageVisibility, setMessageVisibility] = React.useState<boolean>(false);

    const isUpdating = changePasswordState.status === HttpRequestStatus.ON_GOING;
    const updateSuccess = changePasswordState.status === HttpRequestStatus.SUCCESS;

    const disabled = isUpdating ? 'disabled' : ''

    const handleSave = () => {
        setShowValidation(true);
        const invalid = document.querySelectorAll('[aria-invalid="true"]').length !== 0;
        const emptyInputs = document.querySelectorAll('input[value=""]').length !== 0;

        if (invalid || emptyInputs) return false;
        dispatch(changePasswordRequest(changePassword));        
    };

    const handlePasswordChange = (update: Partial<UserToChangePassword>) => setChangePassword(ps => ({ ...ps, ...update }));

    const value: ProfileContextProps = {
        isEditing,
        showValidation,
        changePassword,
        isUpdating,
        updateSuccess,
        setIsEditing,
        handleSave,
        handlePasswordChange,
        messageVisibility,
        setMessageVisibility,
        disabled,
        setShowValidation
    };
    return <ProfileContext.Provider value={value}>{props.children}</ProfileContext.Provider>;
};

export const useProfileContext = () => React.useContext(ProfileContext);

export const withProfileContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <ProfileProvider>
            <WrapperComponent {...props} />
        </ProfileProvider>
    );
};
