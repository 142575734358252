import configuration from './custom_organization/config';

const config = {
    VERSION: process.env.VERSION
};

export default config;

export const BASE_HREF = process.env.REACT_APP_BASE_HREF ?? '/';

export const SERVER_API_URL = process.env.REACT_APP_API_URL;
export const GIRO_API_URL: string = process.env.REACT_APP_OLD_FRONTEND ?? 'https://app.giro.tech';
export const INVITE_REDICT_URL: string = process.env.REACT_APP_INVITE_REDIRECT ?? GIRO_API_URL;
export const GIRO_TECH_URL: string = process.env.REACT_APP_SITE_URL ?? '#';
export const GIRO_APP_LINK: string = process.env.REACT_APP_APP_LINK ?? '#';

export const GIRO_API_TERMS_WEBVIEW: string = `${GIRO_API_URL}#/accept-terms-webview`;

export const GIRO_API_ACCOUNT_MANAGE: string = `${GIRO_API_URL}#/dashboard/company-bank-account-manage`;
export const GIRO_API_ADDRESS_MANAGE: string = `${GIRO_API_URL}#/dashboard/company-address-manage`;
export const GIRO_API_SIGNER: string = `${GIRO_API_URL}#/dashboard/signer/`;
export const GIRO_API_FILES_MANAGE: string = `${GIRO_API_URL}#/dashboard/company-files-manage`;
export const GIRO_API_HISTORIC: string = `${GIRO_API_URL}#/dashboard/credit-approved-detail-provider`;
export const GIRO_API_CONSULT: string = `${GIRO_API_URL}#/dashboard/provider-titles/report`;

export const EMAIL_REGEX: RegExp = /^[\w-]+(?:\.[\w-]+)*@[a-z0-9-]+(?:\.[a-z0-9]+)*(?:\.[a-z]{2,})$/i;

export const INTERCOM_WEBCHAT_URL = 'https://widget.intercom.io/widget/';
export const JIVO_WEBCHAT_URL = '//code.jivosite.com/widget/';
export const DIGISAC_WEBCHAT_URL = 'https://webchat.digisac.app/embedded.js';
export const MOVIDESK_WEBCHAT_URL = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
export const MOVIDESK_WEBCHAT_KEY = '8A0FDF6A8C22460F8FF9834F7F82740D';

// parameterized
export const GIRO_PHONE_CONTACT: string = configuration?.contact?.phone ?? '';

export const DEFAULT_LANGUAGE: string = configuration?.localization?.i18n?.default_language ?? 'en';
export const MOMENT_LOCALE: string = configuration?.localization?.moment?.locale ?? 'en-us';
export const INTL_LOCALE: string = configuration?.localization?.Intl?.locale ?? 'en-US';
export const INTL_CURRENCY: string = configuration?.localization?.Intl?.currency ?? 'USD';

export const PHONE_REGEX: RegExp = configuration?.format?.phone?.regex ?? /.*/;

export const INDIVIDUAL_IDENTIFICATION_REGEX: RegExp = configuration?.format?.identification?.individual?.regex ?? /(.*)/g;
export const INDIVIDUAL_IDENTIFICATION_MASK: string = configuration?.format?.identification?.individual?.mask ?? '$1';

export const CORPORATION_IDENTIFICATION_REGEX: RegExp = configuration?.format?.identification?.corporation?.regex ?? /(.*)/g;
export const CORPORATION_IDENTIFICATION_MASK: string = configuration?.format?.identification?.corporation?.mask ?? '$1';
