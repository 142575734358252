import React from 'react';
import FormatUtils from '../../../shared/util/format-utils';

export const formatPercent = FormatUtils.formatPercent;

export interface PercentProps {
    value?: number;
}

export const Percent = (props: PercentProps) => {
    return <>{formatPercent(props.value)}</>;
};

export default Percent;
