import Slide, { SlideProps } from '@material-ui/core/Slide';
import React from 'react';

export interface DelayedSlideProps extends SlideProps {
    delay?: number;
}

export const DelayedSlide: React.FunctionComponent<DelayedSlideProps> = props => {
    const { delay, ...rest } = props;

    const [hide, setHide] = React.useState<boolean>(!!props.delay && props.delay > 0);

    React.useEffect(() => {
        const timer: number = rest.in ? setTimeout(() => setHide(false), delay) : 0;
        return () => {
            clearTimeout(timer);
        };
    }, [delay, rest.in]);

    const handleExited = () => {
        setHide(true);
    };

    return (
        <Slide {...rest} in={!hide && props.in} onExited={handleExited}>
            {props.children}
        </Slide>
    );
};

export default DelayedSlide;
