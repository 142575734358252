import { Token } from '../../model/authentication';

class AuthUtil {
    private AUTH_TOKEN_KEY = 'giro-authenticationToken';

    isAuthenticated(): boolean {
        const _auth: string | null = localStorage.getItem(this.AUTH_TOKEN_KEY);
        if (_auth === null) return false;
        try {
            const _token: Token = JSON.parse(_auth);
            if (!_token.access_token) {
                this.removeToken();
                return false;
            }
            return true;
        } catch (_error) {
            this.removeToken();
            return false;
        }
    }

    getToken(): Token | null {
        const _auth: string | null = localStorage.getItem(this.AUTH_TOKEN_KEY);
        if (_auth === null) return null;
        try {
            const _token: Token = JSON.parse(_auth);
            return _token;
        } catch (_error) {
            this.removeToken();
            return null;
        }
    }

    setToken(token: Token): void {
        const expiresAt = new Date();
        expiresAt.setSeconds(expiresAt.getSeconds() + token.expires_in);
        token.expires_at = expiresAt.getTime();
        const _auth = JSON.stringify(token);
        localStorage.setItem(this.AUTH_TOKEN_KEY, _auth);
    }

    removeToken(): void {
        localStorage.removeItem(this.AUTH_TOKEN_KEY);
    }
}

export default new AuthUtil();
