import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserSimple } from '../../../model/user';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { forgotPasswordError, forgotPasswordRequest, forgotPasswordSuccess } from './actions';
import { ForgotPasswordActionTypes } from './types';


function* handleForgotPassword(action: ReturnType<typeof forgotPasswordRequest>) {
  try {
    const result: AxiosResponse<UserSimple> = yield call(accountApi.requestPasswordReset, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(forgotPasswordError(mapped));
      return;
    }
    yield put(forgotPasswordSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(forgotPasswordError(mapped))
  }
}

function* watchForgotPassword() {
  yield takeEvery(ForgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
}



function* forgotPasswordSaga() {
  yield all([
    fork(watchForgotPassword),
  ]);
}

export default forgotPasswordSaga;
