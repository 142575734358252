import * as Sentry from '@sentry/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import App from './App';
// import DevTools from './config/devtools';
import i18n from './config/i18n';
import './index.css';
import { IRootState } from './reducer';
import configureStore from './reducer/configureStore';
import * as serviceWorker from './serviceWorker';
import ErrorFallbackPage from './shared/error/error-fallback-page';
import 'react-toastify/dist/ReactToastify.css';

// const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

const initialState = window.INITIAL_REDUX_STATE;
const store: Store<IRootState> = configureStore(initialState);

export type RootDispatcher = typeof store.dispatch;

const rootEl = document.getElementById('root');

Sentry.init({
    dsn: 'https://6a4cb15be7d84e318def0fe3a5b0a3e2@o493129.ingest.sentry.io/5561617',
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS'
    ],
    beforeSend: (event: Sentry.Event) => {
        const ignore_origin = event?.exception?.values?.find(v =>
            v?.stacktrace?.frames?.find(f => f?.filename?.includes('webchat.digisac'))
        );
        if (ignore_origin) {
            return null;
        }
        return event;
    }
});

ReactDOM.render(
    <Sentry.ErrorBoundary
        fallback={({ error, componentStack, eventId }) => (
            <ErrorFallbackPage error={error} componentStack={componentStack} eventId={eventId} />
        )}
    >
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <>
                    {/* If this slows down the app in dev disable it and enable when required  */}
                    {/* {devTools} */}
                    <App />
                </>
            </I18nextProvider>
        </Provider>
    </Sentry.ErrorBoundary>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
