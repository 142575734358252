import Arrow from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { GIRO_API_HISTORIC } from '../../../config/constants';
import { ProgramToList } from '../../../model/program';
import ContextRibbon from '../../context-ribbon/context-ribbon';
import './anticipation-invoices.scss';
import InvoiceSelection from './components/invoice-selection/invoice-selection';
import OrganizationNotifications from './components/organization-notifications/organization-notifications';
import ProgramSelection from './components/program-selection/program-selection';


export interface AnticipationInvoicesLocation {
    program: ProgramToList;
}

export const AnticipationInvoices = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation<AnticipationInvoicesLocation>();

    React.useEffect(() => {
        if (!location.state?.program) {
            history.replace('/dashboard');
        }
    }, [location, history]);

    if (!location.state?.program) {
        return <></>;
    }

    return (
        <div className="container__invoices">
            <ContextRibbon />
            <Container>
                <Row style={{ marginTop: 19 }}>
                    <OrganizationNotifications program={location.state.program} />
                </Row>
                <Row>
                    <Col className="container__invoices--title" xs="8">
                        {location.state.program.name}
                    </Col>
                    <Col className="container__invoices--link" xs="4">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${GIRO_API_HISTORIC}?providerId=${location.state.program.providerId}&programId=${location.state.program.id}`}
                        >
                            {t('anticipation-invoices.historic')}
                            <Arrow className="link--arrow" />
                        </a>
                    </Col>
                </Row>
                {false && <ProgramSelection />}
                <InvoiceSelection />
            </Container>
        </div>
    );
};

export default AnticipationInvoices;
