import { AxiosResponse } from 'axios';
import { ProgramConfigurationToProvider, ProgramSimulationExample, ProgramToList } from '../../model/program';
import { api } from './api';

const BASE_URL = 'api/webapp/programs';

export const programs = () => {
    const listPrograms = (_limit?: number): Promise<AxiosResponse<ProgramToList[]>> => {
        return api.get<ProgramToList[]>(`${BASE_URL}/me/default`, { params: { limit: _limit } });
    };

    const getSimulationExample = (programId: string): Promise<AxiosResponse<ProgramSimulationExample>> => {
        return api.get<ProgramSimulationExample>(`${BASE_URL}/${programId}/simulation/example`);
    };

    const getConfigurationToProvider = (programId: string): Promise<AxiosResponse<ProgramConfigurationToProvider>> => {
        return api.get<ProgramConfigurationToProvider>(`${BASE_URL}/${programId}/configuration/provider`);
    };

    return {
        listPrograms,
        getSimulationExample,
        getConfigurationToProvider
    };
};

export default programs();
