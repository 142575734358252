import { CompanyDefault } from '../../../model/company';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum DefaultCompanyActionTypes {
  GET_DEFAULT_COMPANY_REQUEST = '@@defaultCompany/GET_COMPANY_DEFAULT_REQUEST',
  GET_DEFAULT_COMPANY_SUCCESS = '@@defaultCompany/GET_DEFAULT_COMPANY_SUCCESS',
  GET_DEFAULT_COMPANY_ERROR = '@@defaultCompany/GET_DEFAULT_COMPANY_ERROR',
  CHANGE_COMPANY_DEFAULT_REQUEST = '@@defaultCompany/CHANGE_COMPANY_DEFAULT_REQUEST',
  CHANGE_COMPANY_DEFAULT_SUCCESS = '@@defaultCompany/CHANGE_COMPANY_DEFAULT_SUCCESS',
  CHANGE_COMPANY_DEFAULT_ERROR = '@@defaultCompany/CHANGE_COMPANY_DEFAULT_ERROR',

  DEFAULT_COMPANY_RESET_STATE = '@@defaultCompany/DEFAULT_COMPANY_RESET_STATE'

}

export interface DefaultCompanyState extends BaseStoreState {
  readonly company?: CompanyDefault;
}
