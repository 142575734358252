import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ListProgramsActionTypes, ListProgramsState } from './types';

export const initialState: ListProgramsState = {
  status: HttpRequestStatus.NOOP,
  programs: [],
  error: undefined
};

const reducer: Reducer<ListProgramsState> = (state = initialState, action): ListProgramsState => {
  switch (action.type) {
    case ListProgramsActionTypes.LIST_PROGRAMS_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, programs: [], error: undefined };
    }
    case ListProgramsActionTypes.LIST_PROGRAMS_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, programs: action.payload, error: undefined };
    }
    case ListProgramsActionTypes.LIST_PROGRAMS_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }


    default: {
      return state;
    }
  }
};

export { reducer as listProgramsReducer };

