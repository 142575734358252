import React from 'react';
import { CompanyToList } from '../../../../model/company';
import { useMyCompaniesState } from '../../../../reducer/hooks';

export interface CompanyFilterContextProps {
    filter?: string;
    companies: CompanyToList[];
    changeFilter: (newFilter: string) => void;
    cleanFilter: () => void;
}

export const CompanyFilterContext = React.createContext<CompanyFilterContextProps>({} as CompanyFilterContextProps);

export const CompanyFilterProvider: React.FunctionComponent = props => {
    const [filter, setFilter] = React.useState<string>('');
    const { companies: unfilteredCompanies } = useMyCompaniesState();

    const changeFilter = (newFilter: string) => {
        if (newFilter !== filter) {
            setFilter(newFilter);
        }
    };

    const filterCompany = (company: CompanyToList): boolean => {
        const digits = filter.replace(/\D/g, '');
        return (
            (digits && !!company.identification?.match(new RegExp(`^${digits}.*$`))) ||
            !!company.name?.match(new RegExp(`^.*${filter}.*$`, 'gi'))
        );
    };

    const cleanFilter = () => setFilter('');

    const companies = (filter ? unfilteredCompanies?.filter(filterCompany) : unfilteredCompanies) ?? [];

    const value: CompanyFilterContextProps = {
        filter,
        companies,
        changeFilter,
        cleanFilter
    };

    return <CompanyFilterContext.Provider value={value}>{props.children}</CompanyFilterContext.Provider>;
};

export const useCompanyFilterContext = () => React.useContext(CompanyFilterContext);

export const withCompanyFilterContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <CompanyFilterProvider>
            <WrapperComponent {...props} />
        </CompanyFilterProvider>
    );
};
