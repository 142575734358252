import { CreditEstimateToRequest } from '../../../model/estimate';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EstimateRequestActionTypes {
  REQUEST_ESTIMATE_REQUEST = '@@estimateRequest/REQUEST_ESTIMATE_REQUEST',
  REQUEST_ESTIMATE_SUCCESS = '@@estimateRequest/REQUEST_ESTIMATE_SUCCESS',
  REQUEST_ESTIMATE_ERROR = '@@estimateRequest/REQUEST_ESTIMATE_ERROR',

  REQUEST_ALL_ESTIMATE_REQUEST = '@@estimateRequest/REQUEST_ALL_ESTIMATE_REQUEST',
  REQUEST_ALL_ESTIMATE_SUCCESS = '@@estimateRequest/REQUEST_ALL_ESTIMATE_SUCCESS',
  REQUEST_ALL_ESTIMATE_ERROR = '@@estimateRequest/REQUEST_ALL_ESTIMATE_ERROR',

  REQUEST_ESTIMATE_RESET_STATE = '@@estimateRquest/REQUEST_ESTIMATE_RESET_STATE',
}

export interface EstimateRequestState extends BaseStoreState {
  readonly estimate?: CreditEstimateToRequest;
}
