import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { ProgramConfigurationToProvider } from '../../../model/program';
import { ProgramConfigurationToProviderActionTypes } from './types';

export const programConfigurationToProviderRequest = (programId: string) =>
    action(ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_REQUEST, programId);
export const programConfigurationToProviderSuccess = (configuration: ProgramConfigurationToProvider) =>
    action(ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_SUCCESS, configuration);
export const programConfigurationToProviderError = (error: GiroWebappError) =>
    action(ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_ERROR, error);
export const programConfigurationToProviderResetState = () =>
    action(ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_RESET_STATE);
