import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { personProfileReducer } from './profile/reducer';
import personProfileSaga from './profile/sagas';
import { PersonProfileState } from './profile/types';
import { providerByInviteReducer } from './provider-invite/reducer';
import createProviderSaga from './provider-invite/sagas';
import { ProviderByInviteState } from './provider-invite/types';

export interface PersonState {
    readonly providerByInvite: ProviderByInviteState;
    readonly personProfile: PersonProfileState;
}

export const PersonReducer: ReducersMapObject<PersonState, AnyAction> = {
    providerByInvite: providerByInviteReducer,
    personProfile: personProfileReducer
};

export function* personSagas() {
    yield all([fork(personProfileSaga), fork(createProviderSaga)]);
}
