import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { programConfigurationToProviderReducer } from './configuration-to-provider/reducer';
import programConfigurationToProviderSaga from './configuration-to-provider/sagas';
import { ProgramConfigurationToProviderState } from './configuration-to-provider/types';
import { listProgramsReducer } from './list/reducer';
import listProgramsSaga from './list/sagas';
import { ListProgramsState } from './list/types';
import { programSimulationExampleReducer } from './simulation-example/reducer';
import programSimulationExampleSaga from './simulation-example/sagas';
import { ProgramSimulationExampleState } from './simulation-example/types';

export interface ProgramState {
    readonly listPrograms: ListProgramsState;
    readonly programSimulationExample: ProgramSimulationExampleState;
    readonly programConfigurationToProvider: ProgramConfigurationToProviderState;
}

export const ProgramReducer: ReducersMapObject<ProgramState, AnyAction> = {
    listPrograms: listProgramsReducer,
    programSimulationExample: programSimulationExampleReducer,
    programConfigurationToProvider: programConfigurationToProviderReducer
};

export function* programSagas() {
    yield all([fork(listProgramsSaga), fork(programSimulationExampleSaga), fork(programConfigurationToProviderSaga)]);
}
