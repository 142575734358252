// SLIDE_OUT
export const SLIDE_OUT_DEFAULT = 350;
export const SLIDE_OUT_FAST = 0;
export const SLIDE_STOP = 9999;

// SLIDE_IN
export const SLIDE_IN_DEFAULT = 750;
export const SLIDE_IN_FAST = 0;
export const SLIDE_IN_DELAY = SLIDE_OUT_DEFAULT;

// FOCUS
export const FOCUS_DELAY = 750;