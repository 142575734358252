import { UserEmailValidation } from '../../../model/user';
import { BaseStoreState } from '../../baseStoreState';

export enum ValidateEmailActionTypes {
  VALIDATE_EMAIL_REQUEST = '@@validateUserEmail/VALIDATE_EMAIL_REQUEST',
  VALIDATE_EMAIL_SUCCESS = '@@validateUserEmail/VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_ERROR = '@@validateUserEmail/VALIDATE_EMAIL_ERROR',
  VALIDATE_EMAIL_CLEAN_STATUS = '@@validateUserEmail/VALIDATE_EMAIL_CLEAN_STATUS',
  VALIDATE_EMAIL_RESET_STATE = '@@validateUserEmail/VALIDATE_EMAIL_RESET_STATE',
}

export interface ValidateEmailState extends BaseStoreState {
  readonly user?: UserEmailValidation;
}
