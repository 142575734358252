import React from 'react';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { EmailValidationRequest, UserEmailValidation } from '../../../../model/user';
import { useRootDispatch, useValidateEmailState } from '../../../../reducer/hooks';
import { validateEmailCleanStatus, validateEmailRequest } from '../../../../reducer/user/validate-email/actions';
import { ValidationResult } from '../../../../shared/util/validation-utils';
import { validateEmail } from '../validation/validation-constants';

export interface EmailContextProps {
    validateLocaly: (email: string) => ValidationResult;
    validateRemotely: (email: string) => ValidationResult;
    isLoading: boolean;
    setToken: (token: string) => void;
}

export const EmailContext = React.createContext<EmailContextProps>({} as EmailContextProps);

const mapServerValidation = (serverResult?: UserEmailValidation): ValidationResult => {
    return serverResult && serverResult.exists === false
        ? { isValid: true }
        : { isValid: false, errorMessage: 'accept-invite.identification.email.validation.registered' };
};

export const useValidationStateAwareness = () => {
    const state = useValidateEmailState();
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS || state.status === HttpRequestStatus.ERROR) {
            dispatch(validateEmailCleanStatus());
        }
    }, [state.status, dispatch]);

    const remoteUpdate = state.status === HttpRequestStatus.SUCCESS;

    return { remoteUpdate };
};

export const EmailProvider: React.FunctionComponent = props => {
    const dispatch = useRootDispatch();
    const state = useValidateEmailState();

    const isLoading = state.status === HttpRequestStatus.ON_GOING;

    const [token, setToken] = React.useState<string>('');

    const validateLocaly = (_email: string): ValidationResult => {
        const _validation = state.user;
        if (_validation && _validation.email && _validation.email === _email) {
            return mapServerValidation(state.user);
        } else {
            return validateEmail(_email);
        }
    };

    const validateRemotely = (_email: string): ValidationResult => {
        const _validation = state.user;
        if (_validation && _validation.email && _validation.email === _email) {
            return mapServerValidation(state.user);
        }
        const defaultValidation: ValidationResult = validateEmail(_email);
        if (!defaultValidation.isValid) {
            return defaultValidation;
        }
        const _token = token;
        const request: EmailValidationRequest = { email: _email, token: _token };
        dispatch(validateEmailRequest(request));
        return { isValid: false, errorMessage: '' };
    };

    const value: EmailContextProps = {
        validateRemotely,
        validateLocaly,
        isLoading,
        setToken
    };

    return <EmailContext.Provider value={value}>{props.children}</EmailContext.Provider>;
};

export const useEmailContext = () => React.useContext(EmailContext);

export const withEmailContext = () => <P extends object>(WrapperComponent: React.ComponentType) => (props: P) => {
    return (
        <EmailProvider>
            <WrapperComponent {...props} />
        </EmailProvider>
    );
};
