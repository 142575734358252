import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { PersonTermsAcceptance } from '../../../model/person';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { hasAcceptedTermsError, hasAcceptedTermsSuccess } from './actions';
import { TermsOfUseActionTypes } from './types';


function* handleHasAcceptedTerms() {
  try {
    const result: AxiosResponse<PersonTermsAcceptance> = yield call(accountApi.hasAcceptedTerms);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(hasAcceptedTermsError(mapped));
      return;
    }
    yield put(hasAcceptedTermsSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(hasAcceptedTermsError(mapped));
  }
}

function* watchHasAcceptedTermsRequest() {
  yield takeEvery(TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_REQUEST, handleHasAcceptedTerms);
}

function* termsOfUseSaga() {
  yield all([fork(watchHasAcceptedTermsRequest)]);
}

export default termsOfUseSaga;
