import React from 'react';
import './App.scss';
import { BASE_HREF } from './config/constants';
import AppRoutes from './shared/routes/routes';

export class App extends React.Component {
    render() {
        return (
            <div className="page-container">
                <AppRoutes basename={BASE_HREF} />
            </div>
        );
    }
}

export default App;
