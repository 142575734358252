import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { GIRO_PHONE_CONTACT } from '../../../../../config/constants';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { ProgramProviderStatus } from '../../../../../model/enums/program-provider-status';
import { useProgramConfigurationToProviderState, useRootDispatch } from '../../../../../reducer/hooks';
import {
    programConfigurationToProviderRequest,
    programConfigurationToProviderResetState
} from '../../../../../reducer/program/configuration-to-provider/actions';
import { ProgramConfigurationToProviderState } from '../../../../../reducer/program/configuration-to-provider/types';
import { isBlankString } from '../../../../../shared/util/string-utils';
import { AnticipationInvoicesLocation } from '../../anticipation-invoices';
import './provider-inactive-message.scss';

export const ProviderInactiveMessage = () => {
    const location = useLocation<AnticipationInvoicesLocation>();
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const state: ProgramConfigurationToProviderState = useProgramConfigurationToProviderState();

    const programId = location.state?.program?.id;
    const providerStatus = location.state?.program?.providerStatus;
    const isLoading = state.status !== HttpRequestStatus.SUCCESS && state.status !== HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.NOOP && programId && providerStatus !== ProgramProviderStatus.ACTIVATED) {
            dispatch(programConfigurationToProviderRequest(programId));
        }
    }, [dispatch, state.status, programId, providerStatus]);

    React.useEffect(() => {
        return () => {
            dispatch(programConfigurationToProviderResetState());
        };
    }, [dispatch]);

    if (providerStatus === ProgramProviderStatus.ACTIVATED) {
        return <></>;
    }

    if (isLoading) {
        return <></>;
    }

    if (isBlankString(state.configuration?.providerCanNotAnticipate)) {
        // fallback message
        return (
            <Row>
                <Col className="provider-inactive-message">
                    <div className="provider-inactive-message__text">
                        {t('anticipation-invoices.inactivated.message')}
                        <br />
                        {t('anticipation-invoices.inactivated.contact', { contact_number: GIRO_PHONE_CONTACT })}
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col className="provider-inactive-message">
                <div
                    className="provider-inactive-message__text"
                    dangerouslySetInnerHTML={{ __html: state.configuration?.providerCanNotAnticipate ?? '' }}
                />
            </Col>
        </Row>
    );
};

export default ProviderInactiveMessage;
