import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '.';
import { RootDispatcher } from '..';
import { AcceptTermsState } from './account/accept-terms/types';
import { ChangePasswordState } from './account/change-password/types';
import { ForgotPasswordState } from './account/forgot-password/types';
import { UserPasswordResetState } from './account/reset-password/types';
import { TermsOfUseState } from './account/terms-of-use/types';
import { UserAccountState } from './account/user-account/types';
import { ApplicationErrorState } from './application/error/types';
import { AuthenticationState } from './authentication/types';
import { BatchRequestState } from './batch/request/types';
import { DefaultCompanyState } from './company/default/types';
import { MyCompaniesState } from './company/myCompanies/types';
import { ProviderCreditsState } from './credit/provider/types';
import { UploadCreditInvoiceState } from './credit/upload-invoice-document/types';
import { EstimateRequestState } from './estimate/request/types';
import { InviteToAcceptState } from './invite/toAccept/types';
import { ActiveNotificationsState } from './notification/list-active/types';
import { OrganizationChatState } from './organization/chat/types';
import { PersonProfileState } from './person/profile/types';
import { ProviderByInviteState } from './person/provider-invite/types';
import { ProgramConfigurationToProviderState } from './program/configuration-to-provider/types';
import { ListProgramsState } from './program/list/types';
import { ProgramSimulationExampleState } from './program/simulation-example/types';
import { UserByResetKeyState } from './user/reset-key/types';
import { ValidateEmailState } from './user/validate-email/types';

export const useRootDispatch = () => useDispatch<RootDispatcher>();

export const useAuthenticationState = () => useSelector<IRootState, AuthenticationState>((state: IRootState) => state.authentication);
export const useApplicationErrorState = () => useSelector<IRootState, ApplicationErrorState>((state: IRootState) => state.applicationError);

// Account
export const usePasswordResetState = () => useSelector<IRootState, UserPasswordResetState>((state: IRootState) => state.passwordReset);
export const useUserAccountState = () => useSelector<IRootState, UserAccountState>((state: IRootState) => state.userAccout);
export const useTermsOfUseState = () => useSelector<IRootState, TermsOfUseState>((state: IRootState) => state.termsOfUse);
export const useChangePasswordState = () => useSelector<IRootState, ChangePasswordState>((state: IRootState) => state.changePassword);
export const useForgotPasswordState = () => useSelector<IRootState, ForgotPasswordState>((state: IRootState) => state.forgotPassword);
export const useAcceptTermsState = () => useSelector<IRootState, AcceptTermsState>((state: IRootState) => state.acceptTerms);

// Batch
export const useBatchRequestState = () => useSelector<IRootState, BatchRequestState>((state: IRootState) => state.batchRequest);

// Company
export const useCompanyDefaultState = () => useSelector<IRootState, DefaultCompanyState>((state: IRootState) => state.companyDefault);
export const useMyCompaniesState = () => useSelector<IRootState, MyCompaniesState>((state: IRootState) => state.myCompanies);

// Credit
export const useProviderCreditsState = () => useSelector<IRootState, ProviderCreditsState>((state: IRootState) => state.providerCredits);
export const useUploadCreditInvoiceState = () => useSelector<IRootState, UploadCreditInvoiceState>((state: IRootState) => state.invoice);


// Estimate
export const useEstimateRequestState = () => useSelector<IRootState, EstimateRequestState>((state: IRootState) => state.estimateRequest);

// Invite
export const useInviteToAcceptState = () => useSelector<IRootState, InviteToAcceptState>((state: IRootState) => state.inviteToAccept);

// Notification
export const useActiveNotificationsState = () =>
    useSelector<IRootState, ActiveNotificationsState>((state: IRootState) => state.activeNotifications);

// Organization
export const useOrganizationChatState = () => useSelector<IRootState, OrganizationChatState>((state: IRootState) => state.organizationChat);

// Person
export const usePersonProfileState = () => useSelector<IRootState, PersonProfileState>((state: IRootState) => state.personProfile);
export const useProviderByInviteState = () => useSelector<IRootState, ProviderByInviteState>((state: IRootState) => state.providerByInvite);

// Program
export const useListProgramsState = () => useSelector<IRootState, ListProgramsState>((state: IRootState) => state.listPrograms);
export const useProgramSimulationExampleState = () =>
    useSelector<IRootState, ProgramSimulationExampleState>((state: IRootState) => state.programSimulationExample);
export const useProgramConfigurationToProviderState = () =>
    useSelector<IRootState, ProgramConfigurationToProviderState>((state: IRootState) => state.programConfigurationToProvider);

// User
export const useUserByResetKeyState = () => useSelector<IRootState, UserByResetKeyState>((state: IRootState) => state.userByResetKey);
export const useValidateEmailState = () => useSelector<IRootState, ValidateEmailState>((state: IRootState) => state.validateEmail);
