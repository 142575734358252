import { UserSimple } from '../../../model/user';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UserPasswordResetActionTypes {
  USER_RESET_PASSWORD_REQUEST = '@@userResetPassword/USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_SUCCESS = '@@userResetPassword/USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_ERROR = '@@userResetPassword/USER_RESET_PASSWORD_ERROR'
}

export interface UserPasswordResetState extends BaseStoreState {
  readonly user?: UserSimple;
}
