import { AxiosResponse } from 'axios';
import { InviteToCreateProviderRequest, ProviderCreatedByInvite } from '../../model/invite';
import { PersonToProfile } from '../../model/person';
import { api } from './api';

const BASE_URL = 'api/webapp/persons';

export const persons = () => {
    const createProviderByInvite = (request: InviteToCreateProviderRequest): Promise<AxiosResponse<ProviderCreatedByInvite>> => {
        return api.post<ProviderCreatedByInvite>(`${BASE_URL}/provider/invite/${request.token}`, request.invite);
    }

    const getProfile = (): Promise<AxiosResponse<PersonToProfile>> => {
        return api.get<PersonToProfile>(`${BASE_URL}/me`);
    }

    return {
        createProviderByInvite,
        getProfile
    };
};

export default persons();
