import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import TermsOfUse from './terms-of-use';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 330,
            border: 'solid 1px',
            borderColor: '#e3e3e3',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
        },
        listSection: {
            backgroundColor: 'inherit',
            padding: '40px 15px 0px 10px'
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0
        }
    })
);

export const ScrollableTermsOfUse = () => {
    const classes = useStyles();

    return (
        <List className={classes.root} subheader={<li />}>
            {[1].map(sectionId => (
                <li key={`section-${sectionId}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                        {[1].map(item => (
                            <ListItem key={`item-${sectionId}-${item}`}>
                                <TermsOfUse />
                            </ListItem>
                        ))}
                    </ul>
                </li>
            ))}
        </List>
    );
};

export default ScrollableTermsOfUse;
