import React, { useEffect } from 'react';
import { ListChildComponentProps } from 'react-window';
import Currency from '../../../../../components/format/currency/Currency';
import FormattedDate from '../../../../../components/format/date/formatted-date';
import Loading from '../../../../../components/loading/loading';
import { CreditToList } from '../../../../../model/credit';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton, Tooltip } from '@material-ui/core';

import {
    INVOICE_SELECTION_ITEM_HEIGHT_PADDING,
    INVOICE_SELECTION_ITEM_LEFT_PADDING,
    INVOICE_SELECTION_ITEM_WIDTH,
    INVOICE_SELECTION_TOP_PADDING
} from '../constants';
import { useRootDispatch, useUploadCreditInvoiceState } from '../../../../../reducer/hooks';
import { uploadCreditInvoiceResetState } from '../../../../../reducer/credit/upload-invoice-document/actions';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { useToast } from '../../../../../shared/hooks/use-toast';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CheckIcon = ({ isSelected }) => {
    if (isSelected) {
        return <div className="icon-check2" />;
    }
    return <div className="icon-check" />;
};

const ADD_TO_STYLE = (property: string | number | undefined, value: number) => {
    if (property === null || property === undefined || isNaN(Number(property))) return;
    return Number(property) + value;
};

export interface InvoiceListItemProps {
    isSelected: (index: number) => boolean;
    onSelect: (credit: CreditToList) => void;
    credits: CreditToList[] | undefined;
    requestFirstPage: (sort?: string | undefined) => void;
}

export const InvoiceListItem = (props: ListChildComponentProps<InvoiceListItemProps>) => {
    const { index, data, style, } = props;
    const { isSelected, onSelect, credits } = data
    const dispatch = useRootDispatch();
    const { status: uploadStatus } = useUploadCreditInvoiceState();
    const { toastSuccess } = useToast();
    const { t } = useTranslation();

    const item: CreditToList | undefined = credits && credits[index];

    const checked: boolean = !!item && isSelected(index);
    const handleSelect = () => {
        if (!item) return;
        onSelect(item);
    };

    useEffect(() => {
        if (uploadStatus === HttpRequestStatus.SUCCESS) {
            toast.success(t(`anticipation-invoices.invoices.creditInvoice`), {
                toastId: 'Success'
            })
            dispatch(uploadCreditInvoiceResetState());

        }
    }, [uploadStatus, t, toastSuccess, dispatch]);

    if (!item) {
        return (
            <div style={style} className="anticipation-invoice-selection__invoice-list--loading">
                <Loading />
            </div>
        );
    }


    const NEW_STYLES: React.CSSProperties = {
        ...style,
        top: ADD_TO_STYLE(style.top, INVOICE_SELECTION_TOP_PADDING),
        left: ADD_TO_STYLE(style.left, INVOICE_SELECTION_ITEM_LEFT_PADDING),
        height: ADD_TO_STYLE(style.height, -INVOICE_SELECTION_ITEM_HEIGHT_PADDING),
        width: INVOICE_SELECTION_ITEM_WIDTH
    };

    return (
        <div
            // required https://www.npmjs.com/package/react-window#why-is-my-list-blank-when-i-scroll
            style={NEW_STYLES}
            className="invoice-list-item__items"
        >
            <div className="invoice-list-item__items--cell">{item.nfNumber}</div>
            <div className="invoice-list-item__items--cell--serial">{item.nfSerial}</div>
            <div className="invoice-list-item__items--cell--buyer">{item.buyer}</div>
            <div className="invoice-list-item__items--cell">
                {(item.orderDetail == null || item.orderDetail === 'null') ?
                    (
                        <Tooltip title={t('anticipation-invoices.invoices.noneOrderDetail') ?? "-"} placement={"left-start"}>
                            <IconButton >
                                <InfoIcon />
                            </IconButton >
                        </Tooltip>
                    )
                    :
                    ("-")
                }
            </div>
            <div className="invoice-list-item__items--cell">{item.installmentNumber ?? ''}</div>
            <div className="invoice-list-item__items--cell">
                <FormattedDate date={item.expireDate} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.originalValue} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.discountedValue} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.anticipationValue} />
            </div>
            <div className="invoice-list-item__items--cell" onClick={handleSelect}>
                <CheckIcon isSelected={checked} />
            </div>

        </div>
    );
};

export default InvoiceListItem;
