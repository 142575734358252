import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './footer.scss';

export const SimpleFooter = () => {
    const { t } = useTranslation();
    return (
        <div className="container__footer">
            <Container>
                <Row>
                    <Col md="12" xs="12">
                        <span className="footer--text">{t('footer.copyright')}</span>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SimpleFooter;
