import React from 'react';
import zxcvbn from 'zxcvbn';
import './password-strength.scss';

export interface PasswordStrengthProps {
    password: string;
}

export const PasswordStrength = (props: PasswordStrengthProps) => {
    const { score } = zxcvbn(props.password ?? '');
    return (
        <div className="password__strength--container">
            <div className="password__strength" />
            <div className="password__strength" data-showcolor={score > 0} />
            <div className="password__strength" data-showcolor={score > 1} />
            <div className="password__strength" data-showcolor={score > 2} />
            <div className="password__strength" data-showcolor={score > 3} />
        </div>
    );
};

export default PasswordStrength;
