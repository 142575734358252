import React from 'react';
import AuthUtil from '../../services/api/authUtil';
import AutoLogin from './components/auto-login/auto-login';
import UserLogin from './components/user-login/user-login';

export const Login = () => {
    const isAuthenticated = AuthUtil.isAuthenticated();

    if (isAuthenticated) {
        return <AutoLogin />;
    }
    return <UserLogin />;
};

export default Login;
