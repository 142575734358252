import React from 'react';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { CreditEstimateToList } from '../../../../../model/estimate';
import { useEstimateRequestState } from '../../../../../reducer/hooks';

export type Comparable = string | number | Date | undefined;
type SortOrder = 'asc' | 'desc' | undefined;

export interface SimulationInfoContextProps {
    estimates: CreditEstimateToList[];
    sortedProperty?: string;
    sortOrder: SortOrder;
    handleSort: (property: keyof CreditEstimateToList, order: SortOrder, mapper: (credit: CreditEstimateToList) => Comparable) => void;
}

export const SimulationInfoContext = React.createContext<SimulationInfoContextProps>({} as SimulationInfoContextProps);

const comparator = (a: Comparable, b: Comparable): number => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

const sortMap = (
    estimates: CreditEstimateToList[],
    mapper: (credit: CreditEstimateToList) => Comparable,
    order: 'asc' | 'desc'
): CreditEstimateToList[] => {
    return estimates.sort((a, b) => {
        const diff = comparator(mapper(a), mapper(b));
        return order === 'asc' ? diff : -diff;
    });
};

export const SimulationInfoProvider: React.FunctionComponent = props => {
    const estimateRequestState = useEstimateRequestState();
    const baseEstimates = estimateRequestState.estimate?.creditEstimates ?? [];

    const [estimates, setEstimates] = React.useState<CreditEstimateToList[]>([]);
    const [sortedProperty, setSortedProperty] = React.useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(undefined);

    const handleSort = (property: keyof CreditEstimateToList, order: SortOrder, mapper: (credit: CreditEstimateToList) => Comparable) => {
        if (property !== sortedProperty) {
            setEstimates(sortMap(baseEstimates, mapper, 'asc'));
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setEstimates(sortMap(baseEstimates, mapper, 'desc'));
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setEstimates(baseEstimates);
            setSortedProperty(undefined);
            setSortOrder(undefined);
        } else {
            setEstimates(sortMap(baseEstimates, mapper, 'asc'));
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    React.useEffect(() => {
        if (estimateRequestState.status === HttpRequestStatus.SUCCESS) {
            setEstimates(baseEstimates);
            setSortedProperty(undefined);
            setSortOrder(undefined);
        }
    }, [estimateRequestState.status, baseEstimates, setEstimates, setSortedProperty, setSortOrder]);

    const value: SimulationInfoContextProps = {
        estimates,
        sortedProperty,
        sortOrder,
        handleSort
    };

    return <SimulationInfoContext.Provider value={value}>{props.children}</SimulationInfoContext.Provider>;
};

export const useSimulationInfoContext = () => React.useContext(SimulationInfoContext);

export const withSimulationInfoContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <SimulationInfoProvider>
            <WrapperComponent {...props} />
        </SimulationInfoProvider>
    );
};
