import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import {
    CORPORATION_IDENTIFICATION_MASK,
    CORPORATION_IDENTIFICATION_REGEX,
    INDIVIDUAL_IDENTIFICATION_MASK,
    INDIVIDUAL_IDENTIFICATION_REGEX,
    INTL_CURRENCY,
    INTL_LOCALE,
    MOMENT_LOCALE
} from '../../config/constants';
import { PersonType } from '../../model/enums/person-type';

moment.locale(MOMENT_LOCALE);

const CURRENCY_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'currency',
    currency: INTL_CURRENCY,
    minimumFractionDigits: 2
});

const PERCENTUAL_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

const DECIMAL_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

const formatIdentification = (type?: PersonType, value?: string): string => {
    if (!value) {
        return '';
    } else if (type === PersonType.INDIVIDUAL) {
        return value?.replace(INDIVIDUAL_IDENTIFICATION_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    } else if (type === PersonType.CORPORATION) {
        return value?.replace(CORPORATION_IDENTIFICATION_REGEX, CORPORATION_IDENTIFICATION_MASK);
    }
    return value;
};

const formatCurrency = (value?: number) => CURRENCY_FORMATTER.format(value ?? 0);
const formatPercent = (value?: number) => PERCENTUAL_FORMATTER.format(value ?? 0);
const formatDecimal = (value?: number) => DECIMAL_FORMATTER.format(value ?? 0);

const formatDate = (date?: Date): string => {
    if (!date) return '';

    return moment(date).format('L');
};

export const FormatUtils = {
    formatIdentification,
    formatCurrency,
    formatPercent,
    formatDate,
    formatDecimal
};

export default FormatUtils;
