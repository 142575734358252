import React from 'react';

export enum ContactCards {
    INFORMATIVE = 'INFORMATIVE'
}
export const HIDE_CARDS = 'HIDE_CARDS';
export type ContactCardDisplay = ContactCards | typeof HIDE_CARDS;
export enum CardVisibility {
    SEEN,
    NOT_SEEN
}

const INITIAL_CARD_RECORD: Record<ContactCards, CardVisibility> = {
    INFORMATIVE: CardVisibility.NOT_SEEN
};

export interface ContactContextProps {
    showCard: ContactCardDisplay;
    cardRecord: Record<ContactCards, CardVisibility>;
    updateCards: (display: ContactCardDisplay, record?: Partial<Record<ContactCards, CardVisibility>>) => void;
}

export const ContactContext = React.createContext<ContactContextProps>({} as ContactContextProps);

export const ContactProvider = props => {
    const [showCard, setShowCard] = React.useState<ContactCardDisplay>(ContactCards.INFORMATIVE);
    const [cardRecord, setCardRecord] = React.useState<Record<ContactCards, CardVisibility>>(INITIAL_CARD_RECORD);
    const updateCards = (display: ContactCardDisplay, record?: Partial<Record<ContactCards, CardVisibility>>) => {
        if (showCard !== display) {
            setShowCard(display);
        }
        if (record && record.INFORMATIVE !== cardRecord.INFORMATIVE) {
            setCardRecord({ ...cardRecord, ...record });
        }
    };

    const value: ContactContextProps = {
        showCard,
        cardRecord,
        updateCards
    };
    return <ContactContext.Provider value={value}>{props.children}</ContactContext.Provider>;
};

export const useContactContext = () => React.useContext(ContactContext);
export const withContactContext = () => <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <ContactProvider>
            <WrappedComponent {...props} />
        </ContactProvider>
    );
};
