import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { useCompanyDefaultState, useUserAccountState } from '../../reducer/hooks';
import FormatUtils from '../../shared/util/format-utils';
import DashboardPrograms from './components/dashboard-programs/dashboard-programs';
import './dashboard.scss';

export const Dashboard = () => {
    const { t } = useTranslation();
    const { account } = useUserAccountState();

    const { company } = useCompanyDefaultState();

    if (!company?.canAnticipate) {
        return (
            <div className="container__dashboard">
                <Container>
                    <Row style={{ marginTop: 15 }}>
                        <Col>
                            <div className="dashboard--title">
                                <span>{t('dashboard.greetings', { name: account?.firstName ?? '' })}</span>
                                {t('dashboard.title')}
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 15 }}>
                        <Col>
                            <div className="dashboard--can-not-anticipate">
                                {t('dashboard.can-not-anticipate', {
                                    company_name: company?.name ?? '',
                                    company_identification: FormatUtils.formatIdentification(company?.type, company?.identification)
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <div className="container__dashboard">
            <Container>
                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <div className="dashboard--title">
                            <span>{t('dashboard.greetings', { name: account?.firstName ?? '' })}</span>
                            {t('dashboard.title')}
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md="6">
                        <WalletChart />
                    </Col>
                    <Col md="6">
                        <TableInvoices />
                    </Col>
                </Row> */}
                <DashboardPrograms />
            </Container>
        </div>
    );
};

export default Dashboard;
