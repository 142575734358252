import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-previous.scss';


export interface ButtonPreviousProps {
    onClick: () => void,
    style?: any 
}

export const ButtonPrevious = (props: ButtonPreviousProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--third" {...props}>
            <Button variant="contained" onClick={props.onClick}>
            <InputAdornment position="start"><div className="button--arrow" /></InputAdornment>
            {t('accept-invite.buttons.previous').toUpperCase()}
            </Button>
        </div>
    );
};

export default ButtonPrevious;