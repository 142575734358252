import { action } from 'typesafe-actions';
import { CreditToList, CreditToListRequest } from '../../../model/credit';
import { GiroWebappError } from '../../../model/error';
import { Page } from '../../../services/page';
import { ProviderCreditsActionTypes } from './types';

export const providerCreditsRequest = (request: CreditToListRequest) => action(ProviderCreditsActionTypes.PROVIDER_CREDITS_REQUEST, request);
export const providerCreditsSuccess = (credits: Page<CreditToList>) => action(ProviderCreditsActionTypes.PROVIDER_CREDITS_SUCCESS, credits);
export const providerCreditsError = (error: GiroWebappError) => action(ProviderCreditsActionTypes.PROVIDER_CREDITS_ERROR, error);

export const providerCreditsResetState = () => action(ProviderCreditsActionTypes.PROVIDER_CREDTIS_RESET_STATE);
