import { CompanyToList } from '../../../model/company';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum MyCompaniesActionTypes {
  GET_MY_COMPANIES_REQUEST = '@@getMyCompanies/GET_MY_COMPANIES_REQUEST',
  GET_MY_COMPANIES_SUCCESS = '@@getMyCompanies/GET_MY_COMPANIES_SUCCESS',
  GET_MY_COMPANIES_ERROR = '@@getMyCompanies/GET_MY_COMPANIES_ERROR'
}

export interface MyCompaniesState extends BaseStoreState {
  readonly companies?: CompanyToList[];
}
