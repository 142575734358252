import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/buttons/action-button/action-button';
import ModalSuccess from '../../../../components/modal-success/modal-success';
import { GIRO_APP_LINK } from '../../../../config/constants';
import { useIsMobile } from '../../../../shared/responsive/media-query';
import './reset-success.scss';

export interface ResetSuccessProps {
    open: boolean;
    onClose: () => void;
}

export const ResetSuccess = (props: ResetSuccessProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <ModalSuccess open={props.open}>
            <div className="content__modal-reset-password-success--itens">
                <div className="content__modal-reset-password-success--image" />
                <div className="content__modal-reset-password-success--title">{t('password-reset.success.title')}</div>
                <div className="content__modal-reset-password-success--message">{t('password-reset.success.message')}</div>
                {isMobile ? (
                    <a href={GIRO_APP_LINK} className="content__modal-reset-password-success--button">
                        {t('password-reset.success.open-app').toUpperCase()}
                    </a>
                ) : (
                    <ActionButton label={t('password-reset.success.action').toUpperCase()} onClick={props.onClose} />
                )}
            </div>
        </ModalSuccess>
    );
};

export default ResetSuccess;
