import { OrganizationChat } from '../../../model/organization';
import { BaseStoreState } from '../../baseStoreState';

export enum OrganizationChatActionTypes {
  ORGANIZATION_CHAT_REQUEST = '@@organizationChat/ORGANIZATION_CHAT_REQUEST',
  ORGANIZATION_CHAT_SUCCESS = '@@organizationChat/ORGANIZATION_CHAT_SUCCESS',
  ORGANIZATION_CHAT_ERROR = '@@organizationChat/ORGANIZATION_CHAT_ERROR'
}

export interface OrganizationChatState extends BaseStoreState {
  readonly organization?: OrganizationChat;
}
