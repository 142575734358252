import { UserSimple } from '../../../model/user';
import { BaseStoreState } from '../../baseStoreState';

export enum ForgotPasswordActionTypes {
  FORGOT_PASSWORD_REQUEST = '@@forgotPassword/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = '@@forgotPassword/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = '@@forgotPassword/FORGOT_PASSWORD_ERROR',
  FORGOT_PASSWORD_RESET_STATE = '@@forgotPassword/FORGOT_PASSWORD_RESET_STATE',
}

export interface ForgotPasswordState extends BaseStoreState {
  readonly user?: UserSimple;
}
