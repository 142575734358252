import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserAccount } from '../../../model/user';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { userAccountError, userAccountSuccess } from './actions';
import { UserAccountActionTypes } from './types';


function* handleUserAccount() {
  try {
    const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverUserAccount);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(userAccountError(mapped));
      return;
    }
    yield put(userAccountSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(userAccountError(mapped))
  }
}

function* watchUserAccountRequest() {
  yield takeEvery(UserAccountActionTypes.USER_ACCOUNT_REQUEST, handleUserAccount);
}

function* userAccountSaga() {
  yield all([fork(watchUserAccountRequest)]);
}

export default userAccountSaga;
