class NotificationStorage {
    private NOTIFICATION_KEY = 'giro-notifications';

    addNotification(id: string): string[] {
        const notifications = this.getNotifications();
        if (notifications.some(it => it === id)) return notifications;

        const update = [...notifications, id];
        localStorage.setItem(this.NOTIFICATION_KEY, JSON.stringify(update));
        return update;
    }

    getNotifications(): string[] {
        const value: string | null = localStorage.getItem(this.NOTIFICATION_KEY);
        if (value === null) return [];
        try {
            const _notifications: string[] = JSON.parse(value);
            return _notifications;
        } catch (_error) {
            return [];
        }
    }

    reset(): void {
        localStorage.removeItem(this.NOTIFICATION_KEY);
    }
}

export default new NotificationStorage() as NotificationStorage;
