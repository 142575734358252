import { CreditToList } from '../../../model/credit';
import { Page } from '../../../services/page';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum ProviderCreditsActionTypes {
  PROVIDER_CREDITS_REQUEST = '@@providerCredits/PROVIDER_CREDITS_REQUEST',
  PROVIDER_CREDITS_SUCCESS = '@@providerCredits/PROVIDER_CREDITS_SUCCESS',
  PROVIDER_CREDITS_ERROR = '@@providerCredits/PROVIDER_CREDITS_ERROR',
  PROVIDER_CREDTIS_RESET_STATE = '@@providerCredits/PROVIDER_CREDTIS_RESET_STATE',

}

export interface ProviderCreditsState extends BaseStoreState {
  readonly creditsPage?: Page<CreditToList>;
}
