import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { AccountReducer, accountSagas, AccountState } from './account/account.reducer';
import termsOfUseSaga from './account/terms-of-use/sagas';
import { applicationErrorReducer } from './application/error/reducer';
import { ApplicationErrorState } from './application/error/types';
import { authenticationReducer } from './authentication/reducer';
import authenticationSaga from './authentication/sagas';
import { AuthenticationState } from './authentication/types';
import { BatchReducer, batchSagas, BatchState } from './batch/batch.reducer';
import { CompanyReducer, companySagas, CompanyState } from './company/company.reducer';
import { CreditReducer, creditSagas, CreditState } from './credit/credit.reducer';
import { EstimateReducer, estimateSagas, EstimateState } from './estimate/estimate.reducer';
import { InviteReducer, inviteSagas, InviteState } from './invite/invite.reducer';
import { NotificationReducer, notificationSagas, NotificationState } from './notification/notification.reducer';
import { OrganizationReducer, organizationSagas, OrganizationState } from './organization/organization.reducer';
import { PersonReducer, personSagas, PersonState } from './person/person.reducer';
import { ProgramReducer, programSagas, ProgramState } from './program/program.reducer';
import { UserReducer, userSagas, UserState } from './user/user.reducer';

export interface IRootState
    extends AccountState,
        BatchState,
        CompanyState,
        CreditState,
        EstimateState,
        InviteState,
        NotificationState,
        OrganizationState,
        PersonState,
        ProgramState,
        UserState {
    readonly applicationError: ApplicationErrorState;
    readonly authentication: AuthenticationState;
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    applicationError: applicationErrorReducer,
    ...AccountReducer,
    ...BatchReducer,
    ...CompanyReducer,
    ...CreditReducer,
    ...EstimateReducer,
    ...InviteReducer,
    ...NotificationReducer,
    ...OrganizationReducer,
    ...PersonReducer,
    ...ProgramReducer,
    ...UserReducer
});

export type RootReducer = typeof rootReducer;

export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(termsOfUseSaga),
        fork(accountSagas),
        fork(batchSagas),
        fork(companySagas),
        fork(creditSagas),
        fork(estimateSagas),
        fork(inviteSagas),
        fork(notificationSagas),
        fork(organizationSagas),
        fork(personSagas),
        fork(programSagas),
        fork(userSagas)
    ]);
}

export default rootReducer;
