import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { compose } from 'redux';
import InvoiceHeader from '../invoice-header/invoice-header';
import InvoiceList from '../invoice-list/invoice-list';
import ProviderInactiveMessage from '../provider-inactive-message/provider-inactive-message';
import SimulateInvoices from '../simulate-invoices/simulate-invoices';
import { withInvoiceSelectionContext } from './invoice-selection-context';
import './invoice-selection.scss';

export const InvoiceSelection = () => {
    const { t } = useTranslation();

    return (
        <>
            <ProviderInactiveMessage />
            <Row>
                <Col className="container__invoices--subtitle">{t('anticipation-invoices.invoices.title')}</Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: '12px' }}>
                    <InvoiceHeader />
                </Col>
            </Row>
            <Row>
                <Col style={{ height: '50vh' }}>
                    <InvoiceList />
                </Col>
            </Row>
            <SimulateInvoices />
        </>
    );
};

export default compose(withInvoiceSelectionContext())(InvoiceSelection);
