import { Checkbox } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import ActionButton from '../../components/buttons/action-button/action-button';
import ScrollableTermsOfUse from '../../components/terms-of-use/scroollable-terms-of-use';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { acceptTermsRequest, acceptTermsResetState } from '../../reducer/account/accept-terms/actions';
import { useAcceptTermsState, useRootDispatch } from '../../reducer/hooks';
import './accept-terms-of-use.scss';

const useAcceptTermsAwareness = () => {
    const state = useAcceptTermsState();

    const isLoading = state.status === HttpRequestStatus.ON_GOING;
    const succeded = state.status === HttpRequestStatus.SUCCESS;

    return { isLoading, succeded };
};

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(acceptTermsResetState());
        };
    }, [dispatch]);
};

export const AcceptTermsOfUse = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    useCleanState();

    const [accept, setAccept] = React.useState<boolean>(false);

    const { isLoading, succeded } = useAcceptTermsAwareness();

    React.useEffect(() => {
        if (succeded) {
            history.push('/dashboard');
        }
    }, [succeded, history]);

    const handleAcceptChange = () => {
        setAccept(!accept);
    };

    const handleFinish = async () => {
        dispatch(acceptTermsRequest());
    };

    return (
        <Container>
            <Row>
                <div className="accept-terms-of-use">
                    <Row>
                        <Col md="9" style={{ margin: 'auto' }}>
                            <div className="accept-terms-of-use--title">
                                <span>{t('terms-of-use.description.start')}</span>
                                {t('terms-of-use.description.middle')}
                                <span>{t('terms-of-use.description.contrast')}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col md="9" style={{ margin: 'auto' }}>
                            <ScrollableTermsOfUse />
                        </Col>
                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col md="9" style={{ margin: 'auto' }}>
                            <div className="accept-terms-of-use__full-terms">
                                <Col className="accept-terms-of-use__full-terms--text">
                                    <Link to="/full-terms" target="_blank">
                                        {t('terms-of-use.full-version')}
                                    </Link>
                                </Col>
                                <Col className="accept-terms-of-use__full-terms--text" onClick={handleAcceptChange}>
                                    <Checkbox checked={accept} />
                                    <span>{t('terms-of-use.accept-terms')}</span>
                                </Col>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ margin: 'auto' }}>
                        <div className="accept-terms-of-use__actions">
                            {accept && <ActionButton label={t('terms-of-use.continue')} onClick={handleFinish} isLoading={isLoading} />}
                        </div>
                    </Row>
                </div>
            </Row>
        </Container>
    );
};

export default AcceptTermsOfUse;
