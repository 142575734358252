import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { BatchRequestActionTypes, BatchRequestState } from './types';

export const initialState: BatchRequestState = {
  status: HttpRequestStatus.NOOP,
  batch: undefined,
  error: undefined
};

const reducer: Reducer<BatchRequestState> = (state = initialState, action): BatchRequestState => {
  switch (action.type) {
    case BatchRequestActionTypes.REQUEST_BATCH_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, batch: undefined, error: undefined };
    }
    case BatchRequestActionTypes.REQUEST_BATCH_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, batch: action.payload, error: undefined };
    }
    case BatchRequestActionTypes.REQUEST_BATCH_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    case BatchRequestActionTypes.REQUEST_BATCH_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as batchRequestReducer };

