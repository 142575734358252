import React from 'react';
import { useEstimateRequestState } from '../../../../../reducer/hooks';
import { isBlankString } from '../../../../../shared/util/string-utils';
import './anticipation-term-session.scss';

export const AnticipationTermSession = () => {
    const { estimate } = useEstimateRequestState();

    if (estimate?.programFinancial?.active !== true) return <></>;
    if (isBlankString(estimate?.programFinancial?.financialConfiguration?.sessionTerm)) return <></>;

    return (
        <div className="anticipation-term-session">
            <div
                className="anticipation-term-session__text"
                dangerouslySetInnerHTML={{ __html: estimate?.programFinancial?.financialConfiguration?.sessionTerm ?? '' }}
            />
        </div>
    );
};

export default AnticipationTermSession;
