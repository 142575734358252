import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { BatchRequested } from '../../../model/batch';
import HttpStatus from '../../../model/enums/httpStatus';
import batchApi from '../../../services/api/batchApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { requestBatchError, requestBatchRequest, requestBatchSuccess } from './actions';
import { BatchRequestActionTypes } from './types';


function* handleBatchRequest(action: ReturnType<typeof requestBatchRequest>) {
  try {
    const result: AxiosResponse<BatchRequested> = yield call(batchApi.batchRequest, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(requestBatchError(mapped));
      return;
    }
    yield put(requestBatchSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(requestBatchError(mapped))
  }
}

function* watchBatchRequest() {
  yield takeEvery(BatchRequestActionTypes.REQUEST_BATCH_REQUEST, handleBatchRequest);
}



function* batchRequestSaga() {
  yield all([
    fork(watchBatchRequest),
  ]);
}

export default batchRequestSaga;
