import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ProgramConfigurationToProviderActionTypes, ProgramConfigurationToProviderState } from './types';

export const initialState: ProgramConfigurationToProviderState = {
    status: HttpRequestStatus.NOOP,
    configuration: undefined,
    error: undefined
};

const reducer: Reducer<ProgramConfigurationToProviderState> = (state = initialState, action): ProgramConfigurationToProviderState => {
    switch (action.type) {
        case ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, configuration: undefined, error: undefined };
        }
        case ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, configuration: action.payload, error: undefined };
        }
        case ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as programConfigurationToProviderReducer };
