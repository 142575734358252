import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ProviderCreatedByInvite } from '../../../model/invite';
import personApi from '../../../services/api/personApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { createProviderError, createProviderRequest, createProviderSuccess } from './actions';
import { ProviderByInviteActionTypes } from './types';


function* handleCreateProvider(action: ReturnType<typeof createProviderRequest>) {
  try {
    const result: AxiosResponse<ProviderCreatedByInvite> = yield call(personApi.createProviderByInvite, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(createProviderError(mapped));
      return;
    }
    yield put(createProviderSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(createProviderError(mapped));
  }
}

function* watchCreateProviderRequest() {
  yield takeEvery(ProviderByInviteActionTypes.CREATE_PERSON_REQUEST, handleCreateProvider);
}

function* createProviderSaga() {
  yield all([fork(watchCreateProviderRequest)]);
}

export default createProviderSaga;
