import { AxiosResponse } from 'axios';
import { NotificationToListActive } from '../../model/notification';
import { api } from './api';

const BASE_URL = 'api/webapp';

export const notifications = () => {
    const getAllActive = (programId: string): Promise<AxiosResponse<NotificationToListActive[]>> => {
        return api.get<NotificationToListActive[]>(`${BASE_URL}/notifications/programs/${programId}/active`);
    };

    return {
        getAllActive
    };
};

export default notifications();
