import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface ExternalRouteProps extends RouteProps {
    externalUrl: string;
}

export const ExternalRoute = (props: ExternalRouteProps) => {
    const { externalUrl, ...rest } = props;
    const redirect = () => {
        window.location.replace(externalUrl);
        return null;
    };
    return <Route render={redirect} {...rest} />;
};

export default ExternalRoute;
