import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ProgramToList } from '../../../model/program';
import programApi from '../../../services/api/programApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { listProgramsError, listProgramsRequest, listProgramsSuccess } from './actions';
import { ListProgramsActionTypes } from './types';


function* handleListPrograms(action: ReturnType<typeof listProgramsRequest>) {
  try {
    const result: AxiosResponse<ProgramToList[]> = yield call(programApi.listPrograms, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(listProgramsError(mapped));
      return;
    }
    yield put(listProgramsSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(listProgramsError(mapped));
  }
}

function* watchListPrograms() {
  yield takeEvery(ListProgramsActionTypes.LIST_PROGRAMS_REQUEST, handleListPrograms);
}


function* listProgramsSaga() {
  yield all([
    fork(watchListPrograms),
  ]);
}

export default listProgramsSaga;
