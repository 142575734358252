import { AxiosResponse } from 'axios';
import { OrganizationChat } from '../../model/organization';
import { api } from './api';

const BASE_URL = 'api/webapp/organizations';

export const organizations = () => {
    const organizationChat = (): Promise<AxiosResponse<OrganizationChat>> => {
        return api.get<OrganizationChat>(`${BASE_URL}/chat`);
    }
    return {
        organizationChat
    };
};

export default organizations();
