import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { EstimateRequestActionTypes, EstimateRequestState } from './types';

export const initialState: EstimateRequestState = {
  status: HttpRequestStatus.NOOP,
  estimate: undefined,
  error: undefined
};

const reducer: Reducer<EstimateRequestState> = (state = initialState, action): EstimateRequestState => {
  switch (action.type) {
    case EstimateRequestActionTypes.REQUEST_ESTIMATE_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, estimate: undefined, error: undefined };
    }
    case EstimateRequestActionTypes.REQUEST_ESTIMATE_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, estimate: action.payload, error: undefined };
    }
    case EstimateRequestActionTypes.REQUEST_ESTIMATE_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, estimate: undefined, error: undefined };
    }
    case EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, estimate: action.payload, error: undefined };
    }
    case EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case EstimateRequestActionTypes.REQUEST_ESTIMATE_RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as estimateRequestReducer };

