import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonCancel from '../../../../components/button-cancel/button-cancel';
import ButtonSave from '../../../../components/button-save/button-save';
import FieldBasic from '../../../../components/field-basic/field-basic';
import { InviteToCreateProvider } from '../../../../model/invite';
import { FOCUS_DELAY } from '../animation/animation-constants';
import { IdentificationCards, useIdentification } from '../identification-step/identification-context';
import { useSteps } from '../invite-steps/invite-steps-context';
import { useEmailContext, useValidationStateAwareness } from './edit-email-context';
import './edit-email.scss';

export interface EditEmailProps {
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const EditEmail = (props: EditEmailProps): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();
    const emailContext = useEmailContext();
    const identificationContext = useIdentification();

    const [email, setEmail] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    const { remoteUpdate } = useValidationStateAwareness();

    React.useEffect(() => {
        if (remoteUpdate) {
            if (emailContext.validateLocaly(email).isValid) {
                const newEmail = email;
                const update: Pick<InviteToCreateProvider, 'email'> = { email: newEmail };
                props.onChange(update);
                identificationContext.updateCards(IdentificationCards.NEW_EMAIL_CARD);
                steps.toNext();
            }
        }
    }, [remoteUpdate, steps, emailContext, email, props, identificationContext]);

    React.useEffect(() => {
        if (steps.isMobile) {
            const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
            return () => clearTimeout(timer);
        }
    }, [INPUT_REF, steps.isMobile]);

    const haltProgression = !emailContext.validateLocaly(email).isValid;

    const handleNext = async () => {
        setValidation(true);
        if (haltProgression) {
            return;
        }
        const validation = emailContext.validateRemotely(email);
        if (!validation.isValid) {
            return;
        }
        const newEmail = email;
        const update: Pick<InviteToCreateProvider, 'email'> = { email: newEmail };
        props.onChange(update);
        identificationContext.updateCards(IdentificationCards.NEW_EMAIL_CARD);
        steps.toNext();
    };

    const handleEnter = () => {
        if (steps.isMobile) handleNext();
    };

    const handleBlur = () => {
        setEmail(ps => ps?.toLowerCase());
    };

    return (
        <div className="body__edit-email">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div className="body__edit-email--title">{t('accept-invite.edit-email.title.start')}</div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <form className="form__style">
                        <FieldBasic
                            className="body__email-field--lowercase"
                            label={t('accept-invite.identification.email.label')}
                            value={''}
                            validate={emailContext.validateLocaly}
                            onChange={setEmail}
                            autoComplete="username"
                            onEnter={handleEnter}
                            inputRef={INPUT_REF}
                            onBlur={handleBlur}
                            helpText={t('accept-invite.edit-email.title.new-email')}
                            showValidation={showValidation}
                        />
                    </form>
                </Col>
            </Row>
            <Row>
                <div className="body__password--buttons">
                    <Col>
                        <div className="body__password--button-cancel">
                            <ButtonCancel onClick={steps.toPrevious} />
                        </div>
                    </Col>
                    <Col>
                        <div className="body__password--button-save">
                            <ButtonSave
                                onClick={handleNext}
                                disabled={showValidation && haltProgression}
                                isLoading={emailContext.isLoading}
                            />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default EditEmail;
