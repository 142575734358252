import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { PersonProfileActionTypes, PersonProfileState } from './types';

export const initialState: PersonProfileState = {
  status: HttpRequestStatus.NOOP,
  profile: undefined,
  error: undefined
};

const reducer: Reducer<PersonProfileState> = (state = initialState, action): PersonProfileState => {
  switch (action.type) {
    case PersonProfileActionTypes.PERSON_PROFILE_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, profile: undefined, error: undefined };
    }
    case PersonProfileActionTypes.PERSON_PROFILE_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, profile: action.payload, error: undefined };
    }
    case PersonProfileActionTypes.PERSON_PROFILE_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    case PersonProfileActionTypes.PERSON_PROFILE_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as personProfileReducer };

