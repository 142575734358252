import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CompanyDefault } from '../../../model/company';
import HttpStatus from '../../../model/enums/httpStatus';
import companyApi from '../../../services/api/companyApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { changeDefaultCompanyRequest, getDefaultCompanyError, getDefaultCompanySuccess } from './actions';
import { DefaultCompanyActionTypes } from './types';


function* handleGetCompanyDefault() {
  try {
    const result: AxiosResponse<CompanyDefault> = yield call(companyApi.getDefault);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(getDefaultCompanyError(mapped));
      return;
    }
    yield put(getDefaultCompanySuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(getDefaultCompanyError(mapped))
  }
}

function* watchGetCompanyDefault() {
  yield takeEvery(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_REQUEST, handleGetCompanyDefault);
}

function* handleChangeDefaultCompany(action: ReturnType<typeof changeDefaultCompanyRequest>) {
  try {
    const result: AxiosResponse<CompanyDefault> = yield call(companyApi.changeDefault, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(getDefaultCompanyError(mapped));
      return;
    }
    yield put(getDefaultCompanySuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(getDefaultCompanyError(mapped))
  }
}


function* watchChangeDefaultCompany() {
  yield takeEvery(DefaultCompanyActionTypes.CHANGE_COMPANY_DEFAULT_REQUEST, handleChangeDefaultCompany);
}

function* defaultCompanySaga() {
  yield all([
    fork(watchGetCompanyDefault),
    fork(watchChangeDefaultCompany)
  ]);
}

export default defaultCompanySaga;
