import React from 'react';
import { useLocation } from 'react-router-dom';
import { CreditToList, CreditToListRequest } from '../../../../../model/credit';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { ProgramProviderStatus } from '../../../../../model/enums/program-provider-status';
import { providerCreditsRequest, providerCreditsResetState } from '../../../../../reducer/credit/provider/actions';
import { ProviderCreditsState } from '../../../../../reducer/credit/provider/types';
import { useProviderCreditsState, useRootDispatch } from '../../../../../reducer/hooks';
import { Pageable } from '../../../../../services/pageable';
import { AnticipationInvoicesLocation } from '../../anticipation-invoices';
import { INVOICE_SELECTION_PAGE_SIZE } from '../constants';

const PAGE_SIZE = INVOICE_SELECTION_PAGE_SIZE;

export interface InvoiceSelectionContextProps {
    credits: CreditToList[] | undefined;
    isAllSelected: boolean;
    selected: string[];
    sort: string | undefined;
    isProviderActivated: boolean;
    creditCount: number;
    isLoading: boolean;
    disableSortAndSelect: boolean;
    hasError: boolean;
    isCreditLoaded: (index: number) => boolean;
    requestFirstPage: (sort?: string) => void;
    requestNextPage: () => void;
    handleSelect: (credit: CreditToList) => void;
    handleSelectAll: () => void;
    handleUnselectAll: () => void;
}

export const InvoiceSelectionContext = React.createContext<InvoiceSelectionContextProps>({} as InvoiceSelectionContextProps);

export const InvoiceSelectionProvider: React.FunctionComponent = props => {
    const location = useLocation<AnticipationInvoicesLocation>();
    const dispatch = useRootDispatch();

    const providerId = location.state?.program.providerId;
    const isProviderActivated = location.state?.program.providerStatus === ProgramProviderStatus.ACTIVATED;

    const state: ProviderCreditsState = useProviderCreditsState();

    const [credits, setCredits] = React.useState<CreditToList[] | undefined>(undefined);
    const [sort, setSort] = React.useState<string | undefined>(undefined);
    const [isAllSelected, setAllSelected] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<string[]>([]);

    const creditCount: number = state.creditsPage && !state.creditsPage.last ? (credits?.length ?? 0) + 1 : credits?.length ?? 1;
    const isLoading: boolean = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;
    const hasError: boolean = state.status === HttpRequestStatus.ERROR;
    const disableSortAndSelect: boolean =
        !isProviderActivated || state.status === HttpRequestStatus.ERROR || !credits || credits.length === 0;

    const isCreditLoaded = (index: number): boolean => !!(credits && credits[index]);

    const requestFirstPage = (_sort?: string) => {
        if (providerId) {
            setAllSelected(false);
            setSelected([]);
            setSort(_sort);
            const _pageable: Pageable = { page: 0, size: PAGE_SIZE, sort: _sort };
            const request: CreditToListRequest = { programProviderId: providerId, pageable: _pageable };
            dispatch(providerCreditsRequest(request));
        }
    };

    const requestNextPage = () => {
        const { creditsPage } = state;
        if (providerId && creditsPage && !creditsPage.last) {
            const _sort = sort;
            const _pageable: Pageable = { page: creditsPage.number + 1, size: PAGE_SIZE, sort: _sort };
            const request: CreditToListRequest = { programProviderId: providerId, pageable: _pageable };
            dispatch(providerCreditsRequest(request));
        }
    };

    const handleSelect = (credit: CreditToList) => {
        if (!isProviderActivated) {
            return;
        }
        if (isAllSelected) {
            setSelected([...(credits?.map(it => it.id).filter(it => it !== credit.id) ?? [])]);
        } else if (selected.find(it => it === credit.id)) {
            setSelected([...selected.filter(it => it !== credit.id)]);
        } else {
            setSelected([...selected, credit.id]);
        }
        setAllSelected(false);
    };

    const handleSelectAll = () => {
        setAllSelected(!isAllSelected);
        setSelected([]);
    };

    const handleUnselectAll = () => {
        setAllSelected(false);
        setSelected([]);
    };

    React.useEffect(() => {
        if (providerId) {
            const _pageable: Pageable = { page: 0, size: PAGE_SIZE };
            const request: CreditToListRequest = { programProviderId: providerId, pageable: _pageable };
            dispatch(providerCreditsRequest(request));
        }
        return () => {
            dispatch(providerCreditsResetState());
        };
    }, [dispatch, providerId]);

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS) {
            const newCredits = state.creditsPage?.content ?? [];
            const newPage = state.creditsPage?.number === 0;
            setCredits(oldCredits => [...(!newPage && oldCredits ? oldCredits : []), ...newCredits]);
        }
    }, [state.status, state.creditsPage]);

    const value: InvoiceSelectionContextProps = {
        credits,
        isAllSelected,
        selected,
        sort,
        isProviderActivated,
        creditCount,
        isLoading,
        disableSortAndSelect,
        hasError,
        isCreditLoaded,
        requestFirstPage,
        requestNextPage,
        handleSelect,
        handleSelectAll,
        handleUnselectAll
    };

    return <InvoiceSelectionContext.Provider value={value}>{props.children}</InvoiceSelectionContext.Provider>;
};

export const useInvoiceSelectionContext = () => React.useContext(InvoiceSelectionContext);

export const withInvoiceSelectionContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <InvoiceSelectionProvider>
            <WrapperComponent {...props} />
        </InvoiceSelectionProvider>
    );
};
