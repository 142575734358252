import { FileToSimple } from "../../../model/File";
import { BaseStoreState } from "../../baseStoreState";

export type ApiResponse = Record<string, any>;

export enum UploadCreditInvoiceTypes {
    UPLOAD_CREDIT_INVOICE_REQUEST = '@@uploadCreditInvoice/UPLOAD_CREDIT_INVOICE_REQUEST',
    UPLOAD_CREDIT_INVOICE_SUCCESS = '@@uploadCreditInvoice/UPLOAD_CREDIT_INVOICE_SUCCESS',
    UPLOAD_CREDIT_INVOICE_ERROR = '@@uploadCreditInvoice/UPLOAD_CREDIT_INVOICE_ERROR',
    UPLOAD_CREDIT_INVOICE_RESET_STATE = '@@uploadCreditInvoice/UPLOAD_CREDIT_INVOICE_RESET_STATE',
}

export interface UploadCreditInvoiceState extends BaseStoreState {
    readonly invoice?: FileToSimple;
}
