import React from 'react';
import { PersonType } from '../../../model/enums/person-type';
import FormatUtils from '../../../shared/util/format-utils';

export interface IdentificationProps {
    type?: PersonType;
    value?: string;
}

export const Idenfitication = (props: IdentificationProps) => {
    return <>{FormatUtils.formatIdentification(props.type, props.value)}</>;
};

export default Idenfitication;
