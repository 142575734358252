import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { CreditEstimateToRequest } from '../../../model/estimate';
import estimateApi from '../../../services/api/estimateApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { requestAllEstimateError, requestAllEstimateRequest, requestAllEstimateSuccess, requestEstimateError, requestEstimateRequest, requestEstimateSuccess } from './actions';
import { EstimateRequestActionTypes } from './types';


function* handleEstimateRequest(action: ReturnType<typeof requestEstimateRequest>) {
  try {
    const result: AxiosResponse<CreditEstimateToRequest> = yield call(estimateApi.estimateRequest, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(requestEstimateError(mapped));
      return;
    }
    yield put(requestEstimateSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(requestEstimateError(mapped))
  }
}

function* watchEstimateRequest() {
  yield takeEvery(EstimateRequestActionTypes.REQUEST_ESTIMATE_REQUEST, handleEstimateRequest);
}

function* handleEstimateAllRequest(action: ReturnType<typeof requestAllEstimateRequest>) {
  try {
    const result: AxiosResponse<CreditEstimateToRequest> = yield call(estimateApi.estimateAllRequest, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(requestAllEstimateError(mapped));
      return;
    }
    yield put(requestAllEstimateSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(requestAllEstimateError(mapped))
  }
}

function* watchEstimateAllRequest() {
  yield takeEvery(EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_REQUEST, handleEstimateAllRequest);
}


function* estimateRequestSaga() {
  yield all([
    fork(watchEstimateRequest),
    fork(watchEstimateAllRequest),
  ]);
}

export default estimateRequestSaga;
