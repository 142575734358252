import React from 'react';
import { ListChildComponentProps } from 'react-window';
import Currency from '../../../../../components/format/currency/Currency';
import FormattedDate from '../../../../../components/format/date/formatted-date';
import Loading from '../../../../../components/loading/loading';
import { CreditEstimateToList } from '../../../../../model/estimate';
import {
    SIMULATION_INVOICES_ITEM_HEIGHT_PADDING,
    SIMULATION_INVOICES_ITEM_LEFT_PADDING,
    SIMULATION_INVOICES_ITEM_WIDTH,
    SIMULATION_INVOICES_TOP_PADDING
} from '../constants';

export type InvoiceListItemProps = ListChildComponentProps;

const ADD_TO_STYLE = (property: string | number | undefined, value: number) => {
    if (property === null || property === undefined || isNaN(Number(property))) return;
    return Number(property) + value;
};

export const InvoiceListItem = (props: InvoiceListItemProps) => {
    const { index, data, style } = props;

    const item: CreditEstimateToList | undefined = data && data[index];

    if (!item) {
        return (
            <div style={style} className="anticipation-invoice-selection__invoice-list--loading">
                <Loading />
            </div>
        );
    }

    const NEW_STYLES: React.CSSProperties = {
        ...style,
        top: ADD_TO_STYLE(style.top, SIMULATION_INVOICES_TOP_PADDING),
        left: ADD_TO_STYLE(style.left, SIMULATION_INVOICES_ITEM_LEFT_PADDING),
        height: ADD_TO_STYLE(style.height, -SIMULATION_INVOICES_ITEM_HEIGHT_PADDING),
        width: SIMULATION_INVOICES_ITEM_WIDTH
    };

    return (
        <div
            // required https://www.npmjs.com/package/react-window#why-is-my-list-blank-when-i-scroll
            style={NEW_STYLES}
            className="invoice-list-item__items-second"
        >
            <div className="invoice-list-item__items-second--cell">{item.nfNumber}</div>
            <div className="invoice-list-item__items-second--cell">{item.nfSerial}</div>
            <div className="invoice-list-item__items-second--cell">{item.installmentNumber ?? ''}</div>
            <div className="invoice-list-item__items-second--cell">
                <FormattedDate date={item.expireDate} />
            </div>
            <div className="invoice-list-item__items-second--cell">
                <Currency value={item.originalValue} />
            </div>
            <div className="invoice-list-item__items-second--cell">
                <Currency value={item.discount} />
            </div>
            <div className="invoice-list-item__items-second--cell">
                <Currency value={item.anticipation} />
            </div>
        </div>
    );
};

export default InvoiceListItem;
