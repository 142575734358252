import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './terms-of-use.scss';

export const TermsOfUse = () => {
    const { t } = useTranslation();

    return (
        <div className="terms-of-use__text">
            <h3>{t('terms-of-use.title').toUpperCase()}</h3>
            <div>
                <p>{t('terms-of-use.terms.policy')}</p>
                <p>
                    {t('terms-of-use.terms.commitment.start')}
                    <Link to="/giro.tech" target="_blank">
                        {t('terms-of-use.terms.commitment.domain')}
                    </Link>

                    {t('terms-of-use.terms.commitment.end')}
                </p>
                <p>{t('terms-of-use.terms.company')}</p>
                <p>{t('terms-of-use.terms.brand')}</p>
                <p>{t('terms-of-use.terms.questions')}</p>
                <ul>
                    <li>{t('terms-of-use.terms.contacts.phone')}</li>
                    <li>
                        {t('terms-of-use.terms.contacts.email')}
                        <a href={`mailto:${t('terms-of-use.terms.contacts.email-address')}`}>
                            {t('terms-of-use.terms.contacts.email-address')}
                        </a>
                    </li>
                    <li>{t('terms-of-use.terms.contacts.chat')}</li>
                    <li>{t('terms-of-use.terms.contacts.attendance')}</li>
                </ul>
            </div>
        </div>
    );
};

export default TermsOfUse;
