import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNext from '../../../../components/button-next/button-next';
import Card from '../../../../components/card/card';
import FieldPassword from '../../../../components/field-password/field-password';
import PasswordStrength from '../../../../components/password-strength/password-strength';
import { FOCUS_DELAY } from '../../animation-constants';
import { validatePassword } from '../../validation-constants';
import './password-only.scss';

export interface PasswordOnlyProps {
    username: string;
    password: string;
    onNext: (password: string) => void;
}

export const PasswordOnly = (props: PasswordOnlyProps) => {
    const { t } = useTranslation();
    const [password, setPassword] = React.useState<string>(props.password);
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
        return () => clearTimeout(timer);
    }, [INPUT_REF]);

    const haltProgression = !validatePassword(password).isValid;

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) return;

        props.onNext(password);
    };

    return (
        <div className="body__reset-password-only">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div className="body__password--title">{t('password-reset.title')}</div>
                </Col>
            </Row>
            <Row>
                <Col style={{ margin: 'auto' }} className="password__strength--mobile">
                    <Card>
                        <div className="body__password-strength">{t('password-reset.strength').toUpperCase()}</div>
                        <PasswordStrength password={password ?? ''} />
                    </Card>
                </Col>
            </Row>
            <div className="body__reset-password-only--components">
                <Col md="6" offset="3">
                    <form className="form__style">
                        <input id="password-only-hidden-username" hidden readOnly value={props.username} autoComplete="username" />
                        <FieldPassword
                            className="password__input"
                            value={props.password}
                            label={t('password-reset.password.label')}
                            onChange={setPassword}
                            validate={validatePassword}
                            autoComplete="new-password"
                            onEnter={handleNext}
                            inputRef={INPUT_REF}
                            showValidation={showValidation}
                        />
                    </form>
                </Col>
            </div>
            <Row>
                <div className="body__reset-password-only--buttons">
                    <Col>
                        <div className="body__reset-password-only--button-next">
                            <ButtonNext onClick={handleNext} disabled={showValidation && haltProgression} />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default PasswordOnly;
