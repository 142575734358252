import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ChangePasswordActionTypes, ChangePasswordState } from './types';

export const initialState: ChangePasswordState = {
  status: HttpRequestStatus.NOOP,
  user: undefined,
  error: undefined
};

const reducer: Reducer<ChangePasswordState> = (state = initialState, action): ChangePasswordState => {
  switch (action.type) {
    case ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, user: undefined, error: undefined };
    }
    case ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
    }
    case ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    case ChangePasswordActionTypes.CHANGE_PASSWORD_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as changePasswordReducer };

