import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CreditInvoiceUploadAPIRequest } from '../../../model/credit';
import HttpStatus from '../../../model/enums/httpStatus';
import { FileToSimple } from '../../../model/File';
import creditApi from '../../../services/api/creditApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { uploadCreditInvoiceError, uploadCreditInvoiceRequest, uploadCreditInvoiceSuccess } from './actions';
import { UploadCreditInvoiceTypes } from './types';

function* handleUploadCreditInvoice(action: ReturnType<typeof uploadCreditInvoiceRequest>) {
    const _uploadCreditInvoice = action.payload;
    const formData = new FormData();

    formData.append('file', _uploadCreditInvoice?.file ?? '');

    const _request: CreditInvoiceUploadAPIRequest = {
        creditId: _uploadCreditInvoice.creditId,
        request: formData
    }

    try {
        const result: AxiosResponse<FileToSimple> = yield call(creditApi.uploadCreditInvoice, _request);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(uploadCreditInvoiceError(mapped));
            return;
        }
        yield put(uploadCreditInvoiceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(uploadCreditInvoiceError(mapped));
    }
}

function* watchUploadCreditInvoice() {
    yield takeEvery(UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_REQUEST, handleUploadCreditInvoice);
}

function* uploadCreditInvoiceSaga() {
    yield all([fork(watchUploadCreditInvoice)]);
}

export default uploadCreditInvoiceSaga;
