import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/buttons/action-button/action-button';
import ModalSuccess from '../../../components/modal-success/modal-success';
import './modal-forgot-password.scss';

export interface ModalForgotPasswordProps {
    open: boolean;
    closeModal: () => void;
}

export const ModalForgotPassword = (props: ModalForgotPasswordProps) => {
    const { t } = useTranslation();

    const handleClose = () => {
        props.closeModal();
    };

    return (
        <ModalSuccess open={props.open}>
            <div className="content__modal-forgot-password--itens">
                <div className="content__modal-forgot-password--image" />
                <div className="content__modal-forgot-password--title">{t('forgot-password.success.title')}</div>
                <div className="content__modal-forgot-password--message">{t('forgot-password.success.message')}</div>
                <ActionButton label={t('forgot-password.success.action').toUpperCase()} onClick={handleClose} />
            </div>
        </ModalSuccess>
    );
};

export default ModalForgotPassword;
