import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import BackButton from '../../../../components/buttons/back-button/back-button';
import Loading from '../../../../components/loading/loading';
import { CompanyToList } from '../../../../model/company';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { changeDefaultCompanyRequest } from '../../../../reducer/company/default/actions';
import { getMyCompaniesRequest } from '../../../../reducer/company/myCompanies/actions';
import { MyCompaniesState } from '../../../../reducer/company/myCompanies/types';
import { useMyCompaniesState, useRootDispatch } from '../../../../reducer/hooks';
import CompanyFilter from '../company-filter/company-filter';
import { useCompanyFilterContext, withCompanyFilterContext } from '../company-filter/company-filter-context';
import CompanyItem from '../company-item/company-item';
import './company-selection.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 350,
            border: 'none'
        },
        listSection: {
            backgroundColor: 'inherit',
            padding: '10px 15px 0px 10px'
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0
        }
    })
);

const useMyCompaniesRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(getMyCompaniesRequest());
    }, [dispatch]);
};

const useStateAwareness = () => {
    const state: MyCompaniesState = useMyCompaniesState();
    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;
    const hasError = state.status === HttpRequestStatus.ERROR;
    return { isLoading, hasError };
};

export interface CompanySelectionProps {
    onAccess: () => void;
}

export const CompanySelection = (props: CompanySelectionProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    useMyCompaniesRequest();
    const { filter, companies } = useCompanyFilterContext();
    const { isLoading, hasError } = useStateAwareness();

    const handleTryAgain = () => {
        dispatch(getMyCompaniesRequest());
    };

    const handleAccess = (company: CompanyToList) => {
        dispatch(changeDefaultCompanyRequest(company.id));
        props.onAccess();
    };

    if (isLoading) {
        return (
            <div className="modal-company__company-selection--loading">
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="modal-company__company-selection--error">
                <div className="company-selection__error-message">{t('modal-company.error-message')}</div>
                <BackButton label={t('modal-company.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    if (!filter && (!companies || companies.length === 0)) {
        return <div className="modal-company__company-selection--empty">{t('modal-company.no-companies')}</div>;
    }

    return (
        <div className="modal-company__company-selection">
            <CompanyFilter />
            {filter && (!companies || companies.length === 0) ? (
                <div className="modal-company__company-selection--empty-search">
                    <div className="company-selection__empty-message">{t('modal-company.empty-search')}</div>
                </div>
            ) : (
                <List className={classes.root} subheader={<li />}>
                    {[1].map(sectionId => (
                        <li key={`section-${sectionId}`} className={classes.listSection}>
                            <ul className={classes.ul}>
                                {companies.map(item => (
                                    <ListItem key={`item-${sectionId}-${item.id}`}>
                                        <CompanyItem company={item} onAccess={handleAccess} />
                                    </ListItem>
                                ))}
                            </ul>
                        </li>
                    ))}
                </List>
            )}
        </div>
    );
};

export default compose(withCompanyFilterContext())(CompanySelection) as React.ComponentType<any>;
