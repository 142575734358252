import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { EmailValidationRequest, UserEmailValidation } from '../../../model/user';
import { ValidateEmailActionTypes } from './types';

export const validateEmailRequest = (request: EmailValidationRequest) => action(ValidateEmailActionTypes.VALIDATE_EMAIL_REQUEST, request);
export const validateEmailSuccess = (user: UserEmailValidation) => action(ValidateEmailActionTypes.VALIDATE_EMAIL_SUCCESS, user);
export const validateEmailError = (error: GiroWebappError) => action(ValidateEmailActionTypes.VALIDATE_EMAIL_ERROR, error);
export const validateEmailResetState = () => action(ValidateEmailActionTypes.VALIDATE_EMAIL_RESET_STATE);
export const validateEmailCleanStatus = () => action(ValidateEmailActionTypes.VALIDATE_EMAIL_CLEAN_STATUS);
