import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import ProfileDisplay from './components/profile-display/profile-display';
import ProfileEditing from './components/profile-editing/profile-editing';
import ProfileLogout from './components/profile-logout/profile-logout';
import { ProfileProvider, useProfileContext, withProfileContext } from './profile-context';
import './profile.scss';

export const Profile = () => {
    const { t } = useTranslation();

    const parentRef = React.useRef<HTMLDivElement>(null);
    const [activeId, setActiveId] = React.useState(window.location.hash.slice(1));
    const { isEditing, setIsEditing, handleSave, disabled, setMessageVisibility, setShowValidation } = useProfileContext();

    React.useEffect(() => {
        activeId !== 'password' ? setIsEditing(false) : setIsEditing(true);
        !activeId && setActiveId('data');
    }, [activeId, setIsEditing]);

    React.useEffect(() => {
        setMessageVisibility(false);
        setShowValidation(false);
    }, [isEditing, setMessageVisibility, setShowValidation]);

    const handleCancel = () => {
        setActiveId('data');
        setIsEditing(false);
    }
    
    const saveEdition = (evt: React.MouseEvent) => {
        disabled ? evt.preventDefault() : handleSave()
    }

    return (
        <div id="profile" style={{ background: 'white' }} className="user-profile__items">
            <div className="personal-data__items">
                <div className="personal-data__items--back">
                    <Container>
                        <Row>
                            {isEditing && (
                                <>
                                    <Col style={{ paddingRight: '0' }} md={{ size: '2', offset: '8' }}>
                                        <Link to="#data" className="license-name__items--cancel" onClick={handleCancel}>
                                            {t('profile.cancel')}
                                        </Link>
                                    </Col>
                                    <Col style={{ paddingLeft: '0' }} md="2">
                                        <ProfileProvider>
                                            <div className={`license-name__items--save ${disabled}`} onClick={e => saveEdition(e)}>
                                                {t('profile.save')}
                                            </div>
                                        </ProfileProvider>
                                    </Col>
                                </>
                            )}
                            {/* (header - edit button)
                            { headerVisibility && (
                                <Col style={{ paddingLeft: '0' }} md={{ size: '2', offset: '10' }}>
                                    <div onClick={() => setIsEditing(true)}  className="license-name__items--edit">
                                        {t('profile.edit')}                                        
                                    </div>
                                </Col>
                            )} */}
                        </Row>
                    </Container>
                </div>
                <div className="container">
                    <Row>
                        <Col md="2">
                            <div className="personal-data__items--sidebar-one">
                                <div className="personal-data__items--sidebar-title">{t('profile.user.title')}</div>
                                <a href="#data" onClick={() => setActiveId('data')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'data' ? 'active' : ''}`}>
                                        {t('profile.user.data.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <a href="#password" onClick={() => setActiveId('password')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'password' ? 'active' : ''}`}>
                                        {t('profile.user.password.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <ProfileLogout />
                            </div>
                            <div className="personal-data__items--sidebar">
                                <div className="personal-data__items--sidebar-title">{t('profile.company.title')}</div>
                                <Link to="/company-address-manage" target="_blank" onClick={() => setActiveId('address')} id="address">
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'address' ? 'active' : ''}`}>
                                        {t('profile.company.address')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </Link>
                                <Link to="/company-bank-account-manage" target="_blank" onClick={() => setActiveId('account')} id="account">
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'account' ? 'active' : ''}`}>
                                        {t('profile.company.account')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </Link>

                                <Link to="/signer" target="_blank" onClick={() => setActiveId('signer')} id="signer">
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'signer' ? 'active' : ''}`}>
                                        {t('profile.company.signer')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </Link>
                                <Link to="/company-files-manage" target="_blank" onClick={() => setActiveId('documents')} id="documents">
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'documents' ? 'active' : ''}`}>
                                        {t('profile.company.documents')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                        <Col md="9" style={{ marginLeft: '-30px', paddingLeft: '78px' }}>
                            <div ref={parentRef}>{isEditing ? <ProfileEditing /> : <ProfileDisplay />}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default compose(withProfileContext())(Profile);
