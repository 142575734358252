import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import './action-button.scss';

export interface ActionButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
}

export const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const handleClick = () => {
        if (!(props.isLoading || props.disabled)) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--action">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled || props.isLoading}>
                <>
                    {props.label}
                    {props.adornment}
                </>
            </Button>
        </div>
    );
};

export default ActionButton;
