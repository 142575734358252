import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { acceptTermsReducer } from './accept-terms/reducer';
import acceptTermsSaga from './accept-terms/sagas';
import { AcceptTermsState } from './accept-terms/types';
import { changePasswordReducer } from './change-password/reducer';
import changePasswordSaga from './change-password/sagas';
import { ChangePasswordState } from './change-password/types';
import { forgotPasswordReducer } from './forgot-password/reducer';
import forgotPasswordSaga from './forgot-password/sagas';
import { ForgotPasswordState } from './forgot-password/types';
import { userPasswordResetReducer } from './reset-password/reducer';
import userPasswordResetSaga from './reset-password/sagas';
import { UserPasswordResetState } from './reset-password/types';
import { termsOfUseReducer } from './terms-of-use/reducer';
import termsOfUseSaga from './terms-of-use/sagas';
import { TermsOfUseState } from './terms-of-use/types';
import { userAccountReducer } from './user-account/reducer';
import userAccountSaga from './user-account/sagas';
import { UserAccountState } from './user-account/types';

export interface AccountState {
    readonly acceptTerms: AcceptTermsState;
    readonly changePassword: ChangePasswordState;
    readonly forgotPassword: ForgotPasswordState;
    readonly passwordReset: UserPasswordResetState;
    readonly termsOfUse: TermsOfUseState;
    readonly userAccout: UserAccountState;
}

export const AccountReducer: ReducersMapObject<AccountState, AnyAction> = {
    userAccout: userAccountReducer,
    passwordReset: userPasswordResetReducer,
    termsOfUse: termsOfUseReducer,
    changePassword: changePasswordReducer,
    forgotPassword: forgotPasswordReducer,
    acceptTerms: acceptTermsReducer
};

export function* accountSagas() {
    yield all([
        fork(userAccountSaga),
        fork(userPasswordResetSaga),
        fork(termsOfUseSaga),
        fork(changePasswordSaga),
        fork(forgotPasswordSaga),
        fork(acceptTermsSaga)
    ]);
}
