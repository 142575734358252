import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { UserAccount } from '../../../model/user';
import { UserAccountActionTypes } from './types';

export const userAccountRequest = () => action(UserAccountActionTypes.USER_ACCOUNT_REQUEST);
export const userAccountSuccess = (account: UserAccount) => action(UserAccountActionTypes.USER_ACCOUNT_SUCCESS, account);
export const userAccountError = (error: GiroWebappError) => action(UserAccountActionTypes.USER_ACCOUNT_ERROR, error);

export const userAccountResetState = () => action(UserAccountActionTypes.USER_ACCOUNT_RESET_STATE);
