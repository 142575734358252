import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../components/buttons/back-button/back-button';
import Currency from '../../../../../components/format/currency/Currency';
import Percent, { formatPercent } from '../../../../../components/format/percent/percent';
import Loading from '../../../../../components/loading/loading';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { ProgramToList } from '../../../../../model/program';
import { useProgramSimulationExampleState, useRootDispatch } from '../../../../../reducer/hooks';
import { programSimulationExampleRequest } from '../../../../../reducer/program/simulation-example/actions';
import './simulation-example.scss';

export interface SimulationExampleProps {
    onClose: () => void;
    program: ProgramToList;
}

export const SimulationExample = (props: SimulationExampleProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const state = useProgramSimulationExampleState();

    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;
    const hasError = state.status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.NOOP) {
            dispatch(programSimulationExampleRequest(props.program.id));
        }
    }, [state.status, dispatch, props.program.id]);

    const handleClose = () => props.onClose();
    const handleTryAgain = () => dispatch(programSimulationExampleRequest(props.program.id));

    if (isLoading) {
        return (
            <div className="simulation-example">
                <div className="simulation-example__container--loading">
                    <Loading />
                </div>
                <div className="simulation-example__close" onClick={handleClose} />
            </div>
        );
    }

    if (hasError || !state.example) {
        return (
            <div className="simulation-example">
                <div className="simulation-example__container--error">
                    <div className="simulation-example__error-message">{t('anticipation-simulation.simulation-example.error-message')}</div>
                    <BackButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
                <div className="simulation-example__close" onClick={handleClose} />
            </div>
        );
    }

    return (
        <div className="simulation-example">
            <div className="simulation-example__container">
                <div className="simulation-example__header">
                    <div className="simulation-example__header--image" />
                    <div className="simulation-example__header--title">{t('anticipation-simulation.simulation-example.title')}</div>
                </div>
                <div className="simulation-example__cost">
                    <div className="simulation-example__cost--title">{t('anticipation-simulation.simulation-example.cost.title')}</div>

                    <div className="simulation-example__fee-table">
                        <div className="simulation-example__fee-table--header">
                            <div className="simulation-example__fee-table--header-title">
                                {t('anticipation-simulation.simulation-example.cost.discount-fee.title')}
                            </div>
                            <div className="simulation-example__fee-table--header-value">
                                <span>
                                    <Percent value={state.example.investorRemuneration / 100} />
                                </span>
                                {t('anticipation-simulation.simulation-example.cost.discount-fee.value')}
                            </div>
                        </div>
                        <div className="simulation-example__fee-table--content">
                            {t('anticipation-simulation.simulation-example.cost.discount-fee.description')}
                        </div>
                    </div>

                    <div className="simulation-example__fee-table">
                        <div className="simulation-example__fee-table--header">
                            <div className="simulation-example__fee-table--header-title">
                                {t('anticipation-simulation.simulation-example.cost.transaction-fee.title')}
                            </div>
                            <div className="simulation-example__fee-table--header-value">
                                <span>
                                    <Percent value={state.example.platformRemuneration / 100} />
                                </span>
                                {t('anticipation-simulation.simulation-example.cost.transaction-fee.value')}
                            </div>
                        </div>
                        <div className="simulation-example__fee-table--content">
                            {t('anticipation-simulation.simulation-example.cost.transaction-fee.description')}
                        </div>
                    </div>
                </div>
                <div className="simulation-example__example">
                    <div className="simulation-example__example--title">
                        {t('anticipation-simulation.simulation-example.example.title')}
                    </div>

                    <div className="simulation-example__example-table">
                        <div className="simulation-example__example-table--header">
                            {t('anticipation-simulation.simulation-example.example.anticipation.start').toUpperCase()}
                            <span>
                                <Currency value={state.example.originalValue} />
                            </span>
                            {t('anticipation-simulation.simulation-example.example.anticipation.end')}
                        </div>
                        <div className="simulation-example__example-table--content">
                            <div className="simulation-example__example-table--content-row">
                                <div className="simulation-example__example-table--content-text">
                                    <span>{t('anticipation-simulation.simulation-example.example.discount-fee.discount')}</span>
                                    {t('anticipation-simulation.simulation-example.example.discount-fee.description', {
                                        discount_fee: formatPercent(state.example.investorRemuneration / 100)
                                    })}
                                </div>
                                <div className="simulation-example__example-table--content-value">
                                    <Currency value={state.example.investorDiscount} />
                                </div>
                            </div>
                            <div className="simulation-example__example-table--content-row">
                                <div className="simulation-example__example-table--content-text">
                                    <span>{t('anticipation-simulation.simulation-example.example.transaction-fee.discount')}</span>
                                    {t('anticipation-simulation.simulation-example.example.transaction-fee.description', {
                                        transaction_fee: formatPercent(state.example.platformRemuneration / 100)
                                    })}
                                </div>
                                <div className="simulation-example__example-table--content-value">
                                    <Currency value={state.example.platformDiscount} />
                                </div>
                            </div>
                        </div>
                        <div className="simulation-example__example-table--footer">
                            {t('anticipation-simulation.simulation-example.example.summary')}
                            <span>
                                <Currency value={state.example.anticipationValue} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="simulation-example__close" onClick={handleClose} />
        </div>
    );
};

export default SimulationExample;
