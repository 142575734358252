import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { OrganizationChat } from '../../../model/organization';
import organizationApi from '../../../services/api/organizationApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { organizationChatError, organizationChatSuccess } from './actions';
import { OrganizationChatActionTypes } from './types';


function* handleOrganizationChat() {
  try {
    const result: AxiosResponse<OrganizationChat> = yield call(organizationApi.organizationChat);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(organizationChatError(mapped));
      return;
    }
    yield put(organizationChatSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(organizationChatError(mapped));
  }
}

function* whatOrganizationChat() {
  yield takeEvery(OrganizationChatActionTypes.ORGANIZATION_CHAT_REQUEST, handleOrganizationChat);
}

function* organizationChatSaga() {
  yield all([fork(whatOrganizationChat)]);
}

export default organizationChatSaga;
