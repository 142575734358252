import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ActiveNotificationsActionTypes, ActiveNotificationsState } from './types';

export const initialState: ActiveNotificationsState = {
    status: HttpRequestStatus.NOOP,
    notifications: [],
    error: undefined
};

const reducer: Reducer<ActiveNotificationsState> = (state = initialState, action): ActiveNotificationsState => {
    switch (action.type) {
        case ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, notifications: [], error: undefined };
        }
        case ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, notifications: action.payload, error: undefined };
        }
        case ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as activeNotificationsReducer };
