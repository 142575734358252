import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { CreditEstimateToRequest, EstimateRequest } from '../../../model/estimate';
import { EstimateRequestActionTypes } from './types';

export const requestEstimateRequest = (request: EstimateRequest) => action(EstimateRequestActionTypes.REQUEST_ESTIMATE_REQUEST, request);
export const requestEstimateSuccess = (estimate: CreditEstimateToRequest) => action(EstimateRequestActionTypes.REQUEST_ESTIMATE_SUCCESS, estimate);
export const requestEstimateError = (error: GiroWebappError) => action(EstimateRequestActionTypes.REQUEST_ESTIMATE_ERROR, error);

export const requestAllEstimateRequest = (providerId: string) => action(EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_REQUEST, providerId);
export const requestAllEstimateSuccess = (estimate: CreditEstimateToRequest) => action(EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_SUCCESS, estimate);
export const requestAllEstimateError = (error: GiroWebappError) => action(EstimateRequestActionTypes.REQUEST_ALL_ESTIMATE_ERROR, error);

export const reqeustEstimateResetState = () => action(EstimateRequestActionTypes.REQUEST_ESTIMATE_RESET_STATE);
