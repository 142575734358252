import React from 'react';
import './footer.scss';
import SimpleFooter from './simple-footer';

export enum FooterType {
    SIMPLE = 'SIMPLE',
    NONE = 'NONE'
}

export interface FooterProps {
    type: FooterType;
}

export const Footer = (props: FooterProps) => {
    switch (props.type) {
        case FooterType.SIMPLE:
            return <SimpleFooter />;
        case FooterType.NONE:
        default:
            return <></>;
    }
};

export default Footer;
