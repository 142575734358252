import { NotificationToListActive } from '../../../model/notification';
import { BaseStoreState } from '../../baseStoreState';

export enum ActiveNotificationsActionTypes {
    ACTIVE_NOTIFICATIONS_REQUEST = '@@activeNotifications/ACTIVE_NOTIFICATIONS_REQUEST',
    ACTIVE_NOTIFICATIONS_SUCCESS = '@@activeNotifications/ACTIVE_NOTIFICATIONS_SUCCESS',
    ACTIVE_NOTIFICATIONS_ERROR = '@@activeNotifications/ACTIVE_NOTIFICATIONS_ERROR',
    ACTIVE_NOTIFICATIONS_RESET_STATE = '@@activeNotifications/ACTIVE_NOTIFICATIONS_RESET_STATE'
}

export interface ActiveNotificationsState extends BaseStoreState {
    readonly notifications?: NotificationToListActive[];
}
