import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CreditToList } from '../../../model/credit';
import HttpStatus from '../../../model/enums/httpStatus';
import creditApi from '../../../services/api/creditApi';
import { Page } from '../../../services/page';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { providerCreditsError, providerCreditsRequest, providerCreditsSuccess } from './actions';
import { ProviderCreditsActionTypes } from './types';


function* handleListProviderCredtis(action: ReturnType<typeof providerCreditsRequest>) {
  try {
    const result: AxiosResponse<Page<CreditToList>> = yield call(creditApi.listCredits, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(providerCreditsError(mapped));
      return;
    }
    yield put(providerCreditsSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(providerCreditsError(mapped));
  }
}

function* watchListProviderCredits() {
  yield takeEvery(ProviderCreditsActionTypes.PROVIDER_CREDITS_REQUEST, handleListProviderCredtis);
}


function* providerCreditsSaga() {
  yield all([
    fork(watchListProviderCredits),
  ]);
}

export default providerCreditsSaga;
