import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ActionButton from '../../../../components/buttons/action-button/action-button';
import FieldBasic from '../../../../components/field-basic/field-basic';
import FieldPassword from '../../../../components/field-password/field-password';
import { GIRO_PHONE_CONTACT } from '../../../../config/constants';
import { Authentication } from '../../../../model/authentication';
import { Authority } from '../../../../model/enums/authorities';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from '../../../../model/enums/error-constants';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../../../model/error';
import { hasAcceptedTermsRequest } from '../../../../reducer/account/terms-of-use/actions';
import { userAccountRequest } from '../../../../reducer/account/user-account/actions';
import { customizeErrorHandling } from '../../../../reducer/application/error/actions';
import { loginRequest } from '../../../../reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch, useTermsOfUseState, useUserAccountState } from '../../../../reducer/hooks';
import { validateEmail, validatePassword } from '../../validation-constants';
import Carousel from '../carousel/carousel';
import './user-login.scss';

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notfound': { message: { key: 'login.invalid' } },
            'error.invalidpassword': { message: { key: 'login.invalid' } },
            'error.user.notactive': {
                type: ErrorType.BLOCKING,
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
                action: { noAction: true }
            }
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

const useAccount = () => {
    const dispatch = useRootDispatch();
    const authenticationState = useAuthenticationState();

    React.useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.SUCCESS) {
            dispatch(userAccountRequest());
        }
    }, [authenticationState.status, dispatch]);
};

const useValidateTerms = () => {
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    React.useEffect(() => {
        if (
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.account?.activated &&
            userAccountState.account?.authorities?.find(it => it === Authority.ROLE_PROVIDER)
        ) {
            dispatch(hasAcceptedTermsRequest());
        }
    }, [userAccountState, dispatch]);
};

const useIsLoading = () => {
    const authenticationState = useAuthenticationState();
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    const [isLoading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.ON_GOING) {
            setLoading(true);
        } else if (authenticationState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [authenticationState.status]);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.ON_GOING) {
            setLoading(true);
        } else if (userAccountState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [userAccountState.status]);

    React.useEffect(() => {
        if (termsOfUseState.status === HttpRequestStatus.ON_GOING) {
            setLoading(true);
        } else if (termsOfUseState.status === HttpRequestStatus.ERROR || termsOfUseState.status === HttpRequestStatus.SUCCESS) {
            setLoading(false);
        }
    }, [termsOfUseState.status]);

    return isLoading;
};

export const UserLogin = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPasswrod] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const dispatch = useRootDispatch();
    const authenticationState = useAuthenticationState();
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    useErrorConfiguration();
    useAccount();
    useValidateTerms();
    const isLoading = useIsLoading();

    const haltProgression = !validateEmail(email).isValid || !validatePassword(password).isValid;

    React.useEffect(() => {
        if (
            authenticationState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            !userAccountState.account?.authorities?.find(it => it === Authority.ROLE_PROVIDER)
        ) {
            history.push('/giro-api');
        }
    }, [authenticationState.status, userAccountState, history]);

    React.useEffect(() => {
        if (
            authenticationState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            termsOfUseState.status === HttpRequestStatus.SUCCESS
        ) {
            history.push(termsOfUseState.terms?.acceptedTerms ? '/dashboard' : '/accept-terms-of-use');
        }
    }, [authenticationState.status, userAccountState.status, termsOfUseState, history]);

    const handleEnter = () => {
        setValidation(true);
        if (haltProgression) return;

        const _password = password;
        const request: Authentication = { username: email, password: _password };
        dispatch(loginRequest(request));
    };

    return (
        <div>
            <Row style={{ minHeight: '100vh', marginLeft: 0, marginRight: 0 }}>
                <Col md="6" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div className="login--logo" />
                    <Carousel />
                </Col>

                <Col style={{ margin: 'auto', paddingLeft: 0, paddingRight: 0 }} md="6">
                    <Row style={{ marginLeft: 0, marginRight: 0, height: '100vh', alignItems: 'center' }}>
                        <Col md={{ size: 6, offset: 3 }} sm="12" className="login--column-adjust">
                            <div className="login--form-logo">
                                <div className="login--form-logo-image" />
                            </div>
                            <form className="login--container">
                                <div className="login--title">
                                    <p> {t('login.title.start').toUpperCase()} </p>
                                    <p> {t('login.title.end').toUpperCase()} </p>
                                </div>
                                <div style={{ marginBottom: '30px' }} />
                                <FieldBasic
                                    label={t('login.email.label')}
                                    value={email}
                                    onChange={setEmail}
                                    validate={validateEmail}
                                    autoComplete="username"
                                    showValidation={showValidation}
                                    onEnter={handleEnter}
                                />
                                <div style={{ marginBottom: '22px' }} />
                                <div className="login--link">
                                    <Link to="/forgot-password">{t('login.password.forgot')} </Link>
                                </div>
                                <div style={{ marginBottom: '10px' }} />
                                <FieldPassword
                                    label={t('login.password.label')}
                                    value={password}
                                    onChange={setPasswrod}
                                    validate={validatePassword}
                                    autoComplete="current-password"
                                    showValidation={showValidation}
                                    onEnter={handleEnter}
                                />
                                <div style={{ marginBottom: '70px' }} />
                                <div className="login--buttons">
                                    <ActionButton
                                        label={t('login.actions.enter').toUpperCase()}
                                        onClick={handleEnter}
                                        disabled={showValidation && haltProgression}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </form>
                        </Col>
                        <div className="login--footer-logo">
                            <div className="login--footer-logo-image" />
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserLogin;
