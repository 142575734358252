import { BatchRequested } from '../../../model/batch';
import { BaseStoreState } from '../../baseStoreState';

export enum BatchRequestActionTypes {
  REQUEST_BATCH_REQUEST = '@@batchRequest/REQUEST_BATCH_REQUEST',
  REQUEST_BATCH_SUCCESS = '@@batchRequest/REQUEST_BATCH_SUCCESS',
  REQUEST_BATCH_ERROR = '@@batchRequest/REQUEST_BATCH_ERROR',
  REQUEST_BATCH_RESET_STATE = '@@batchRequest/REQUEST_BATCH_RESET_STATE',
}

export interface BatchRequestState extends BaseStoreState {
  readonly batch?: BatchRequested;
}
