import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { compose } from 'redux';
import { ProgramToList } from '../../../../../model/program';
import InvoiceHeader from '../invoice-header/invoice-header';
import InvoiceList from '../invoice-list/invoice-list';
import SimulationHeader from '../simulation-header/simulation-header';
import { withSimulationInfoContext } from './simulation-info-context';

export interface SimulationInfoProps {
    program: ProgramToList;
    onRequest: () => void;
    onExample: () => void;
}

export const SimulationInfo = (props: SimulationInfoProps) => {
    const { t } = useTranslation();

    return (
        <>
            <SimulationHeader onRequest={props.onRequest} onExample={props.onExample} />
            <Container>
                <Row>
                    <Col>
                        <div className="simulation--text">
                            <div>{props.program?.name?.toUpperCase()}</div>
                            <div style={{ marginLeft: 10, marginRight: 10 }}>|</div>
                            <span>{t('anticipation-simulation.credits.subtitle')}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InvoiceHeader />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InvoiceList />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default compose(withSimulationInfoContext())(SimulationInfo);
