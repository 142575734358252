import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { TermsOfUseActionTypes, TermsOfUseState } from './types';

export const initialState: TermsOfUseState = {
  status: HttpRequestStatus.NOOP,
  terms: undefined,
  error: undefined
};

const reducer: Reducer<TermsOfUseState> = (state = initialState, action): TermsOfUseState => {
  switch (action.type) {
    case TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING };
    }
    case TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, terms: action.payload, error: undefined };
    }
    case TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as termsOfUseReducer };

