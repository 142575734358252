import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { UserPasswordResetActionTypes, UserPasswordResetState } from './types';

export const initialState: UserPasswordResetState = {
  status: HttpRequestStatus.NOOP,
  user: undefined,
  error: undefined
};

const reducer: Reducer<UserPasswordResetState> = (state = initialState, action): UserPasswordResetState => {
  switch (action.type) {
    case UserPasswordResetActionTypes.USER_RESET_PASSWORD_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING };
    }
    case UserPasswordResetActionTypes.USER_RESET_PASSWORD_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
    }
    case UserPasswordResetActionTypes.USER_RESET_PASSWORD_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    default: {
      return state;
    }
  }
};

export { reducer as userPasswordResetReducer };

