import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNext from '../../../../components/button-next/button-next';
import ButtonPrevious from '../../../../components/button-previous/button-previous';
import { Card } from '../../../../components/card/card';
import WhatsappOptions from '../../../../components/whatsapp-options/whatsapp-options';
import { WhatsAppStatus } from '../../../../model/enums/whatsapp-status';
import { InviteToCreateProvider } from '../../../../model/invite';
import { StepsEnum, useSteps } from '../invite-steps/invite-steps-context';
import { CardVisibility, HIDE_CARDS, useContactContext } from './contact-context';
import './contact.scss';

export interface ContactInformativeProps {
    whatsappStatus: WhatsAppStatus;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const ContactInformative = (props: ContactInformativeProps) => {
    const { t } = useTranslation();
    const steps = useSteps();
    const { showCard, updateCards } = useContactContext();
    const [status, setStatus] = React.useState<WhatsAppStatus>(props.whatsappStatus ?? WhatsAppStatus.NOT_KNOWN);

    const handleWhatsappStatusChange = (newStatus: WhatsAppStatus) => {
        if (status !== newStatus) {
            setStatus(newStatus);
        }
    };

    const handleCardClose = () => {
        updateCards(HIDE_CARDS, { INFORMATIVE: CardVisibility.SEEN });
    };

    const handleNext = () => {
        props.onChange({ whatsAppStatus: status });
        if (status === WhatsAppStatus.RECEIVE) {
            steps.toNext();
        } else {
            steps.toStep(StepsEnum.TERMS_OF_USE);
        }
    };

    const haltProgression = !status || status === WhatsAppStatus.NOT_KNOWN;

    return (
        <div className="body__contact">
            <Row>
                <Col md="12" lg={{ size: 6, offset: 3 }}>
                    <Row>
                        <Col>
                            <div className="body__contact--title">
                                {t('accept-invite.contact.start')}
                                {t('accept-invite.contact.end')}
                            </div>
                        </Col>
                    </Row>
                    <WhatsappOptions whatsappStatus={props.whatsappStatus} onChange={handleWhatsappStatusChange} />
                    <Col>
                        {
                            {
                                INFORMATIVE: (
                                    <Card onClose={handleCardClose}>
                                        {t('accept-invite.contact.card.whatsapp.start')}
                                        <p>{t('accept-invite.contact.card.whatsapp.end')}</p>
                                    </Card>
                                ),
                                HIDE_CARDS: <></>
                            }[showCard]
                        }
                    </Col>
                    <Row>
                        <div className="body__contact--buttons">
                            <Col>
                                <div className="body__contact--button-previous">
                                    <ButtonPrevious onClick={steps.toPrevious} />
                                </div>
                            </Col>
                            <Col>
                                <div className="body__contact--button-next">
                                    <ButtonNext onClick={handleNext} disabled={haltProgression} />
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ContactInformative;
