import { ProgramConfigurationToProvider } from '../../../model/program';
import { BaseStoreState } from '../../baseStoreState';

export enum ProgramConfigurationToProviderActionTypes {
    PROGRAM_CONFIGURATION_TO_PROVIDER_REQUEST = '@@programConfigurationToProvider/PROGRAM_CONFIGURATION_TO_PROVIDER_REQUEST',
    PROGRAM_CONFIGURATION_TO_PROVIDER_SUCCESS = '@@programConfigurationToProvider/PROGRAM_CONFIGURATION_TO_PROVIDER_SUCCESS',
    PROGRAM_CONFIGURATION_TO_PROVIDER_ERROR = '@@programConfigurationToProvider/PROGRAM_CONFIGURATION_TO_PROVIDER_ERROR',
    PROGRAM_CONFIGURATION_TO_PROVIDER_RESET_STATE = '@@programConfigurationToProvider/PROGRAM_CONFIGURATION_TO_PROVIDER_RESET_STATE'
}

export interface ProgramConfigurationToProviderState extends BaseStoreState {
    readonly configuration?: ProgramConfigurationToProvider;
}
