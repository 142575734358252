import { AxiosResponse } from 'axios';
import { CreditInvoiceUploadAPIRequest, CreditToList, CreditToListRequest } from '../../model/credit';
import { FileToSimple } from '../../model/File';
import { Page } from '../page';
import { api } from './api';

const BASE_URL = 'api/webapp/credits';

export const credits = () => {

    const listCredits = (request: CreditToListRequest): Promise<AxiosResponse<Page<CreditToList>>> => {
        return api.get<Page<CreditToList>>(`${BASE_URL}/programProvider/${request.programProviderId}`, { params: { ...request.pageable } });
    }

    const uploadCreditInvoice = (data: CreditInvoiceUploadAPIRequest): Promise<AxiosResponse<FileToSimple>> => {
        return api.post<FileToSimple>(`api/credits/${data.creditId}/upload`, data.request, {
            headers: {
                'Content-Type': `multipart/form-data;`,
            },
            timeout: 2 * 60 * 1000,
        });
    };


    return {
        listCredits,
        uploadCreditInvoice
    };
};

export default credits();
