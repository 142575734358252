import MaterialCard from '@material-ui/core/Card';
import React, { FunctionComponent } from 'react';
import './card.scss';

export interface CardProps {
    onClose?: () => void;
}

export const Card: FunctionComponent<CardProps> = (props): JSX.Element => {
    return (
        <div className="card-component">
            <MaterialCard className="card__identify">
                {props.onClose && <div className="button--close" onClick={props.onClose} />}
                <div className="card__identify--text">{props.children}</div>
            </MaterialCard>
        </div>
    );
};

export default Card;
