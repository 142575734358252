import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { OrganizationChatActionTypes, OrganizationChatState } from './types';

export const initialState: OrganizationChatState = {
  status: HttpRequestStatus.NOOP,
  organization: undefined,
  error: undefined
};

const reducer: Reducer<OrganizationChatState> = (state = initialState, action): OrganizationChatState => {
  switch (action.type) {
    case OrganizationChatActionTypes.ORGANIZATION_CHAT_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, organization: undefined, error: undefined };
    }
    case OrganizationChatActionTypes.ORGANIZATION_CHAT_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, organization: action.payload, error: undefined };
    }
    case OrganizationChatActionTypes.ORGANIZATION_CHAT_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    default: {
      return state;
    }
  }
};

export { reducer as organizationChatReducer };

