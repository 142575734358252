import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './error-fallback-page.scss';
import * as stackTraceParser from 'stacktrace-parser';
import { GIRO_PHONE_CONTACT } from '../../config/constants';

export interface ErrorFallbackPageProps {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
}

export const ErrorFallbackPage = (props: ErrorFallbackPageProps) => {
    const { t } = useTranslation();

    const [isDetailsVisible, setIsDetailsVisible] = React.useState<boolean>(false);

    const toggleDetails = () => setIsDetailsVisible(ps => !ps);

    return (
        <div className="error-fallback__container">
            <div className="error-fallback__message">
                <div className="error-fallback__message-title">Ops!</div>
                <div className="error-fallback__message-sub-title">{t('global.error-fallback.sub-title')}</div>
                <div className="error-fallback__message-tips">{t('global.error-fallback.tips')}</div>
                <div className="error-fallback__message-contact">
                    {t('global.error-fallback.contact', { contact_number: GIRO_PHONE_CONTACT })}
                </div>
            </div>

            <div className="error-fallback__error-details">
                {isDetailsVisible ? (
                    <div className="error-fallback__error-details--visible">
                        <div className="error-fallback__pointer" onClick={toggleDetails}>
                            <ExpandLessIcon />
                        </div>

                        <table className="error-fallback__details-table">
                            <tbody>
                                <tr className="error-fallback__details-row">
                                    <td className="error-fallback__details-property">{t('global.error-fallback.error')}</td>
                                    <td className="error-fallback__details-info">{props.error?.message ?? '-'}</td>
                                </tr>
                                <tr className="error-fallback__details-row">
                                    <td className="error-fallback__details-property">{t('global.error-fallback.origin')}</td>
                                    <td className="error-fallback__details-info">
                                        {(props.error?.stack &&
                                            stackTraceParser
                                                .parse(props.error.stack)
                                                ?.map(it => it.methodName)
                                                ?.filter(it => it !== undefined && it !== null)
                                                ?.find(it => !it.includes('anonymous') && !it.includes('unknown'))) ??
                                            '-'}
                                    </td>
                                </tr>
                                <tr className="error-fallback__details-row">
                                    <td className="error-fallback__details-property">{t('global.error-fallback.agent')}</td>
                                    <td className="error-fallback__details-info">{window?.navigator?.userAgent ?? '-'}</td>
                                </tr>
                                <tr className="error-fallback__details-row">
                                    <td className="error-fallback__details-property">{t('global.error-fallback.event')}</td>
                                    <td className="error-fallback__details-info">{props.eventId ?? '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="error-fallback__error-details--hidden error-fallback__pointer" onClick={toggleDetails}>
                        <div className="error-fallback__details-title">{t('global.error-fallback.details')}</div>
                        <ExpandMoreIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ErrorFallbackPage;
