
export const INVOICE_SELECTION_PAGE_SIZE = 30;
export const INVOICE_SELECTION_THRESHOLD = 15;

export const INVOICE_SELECTION_HEIGHT = 200;
export const INVOICE_SELECTION_WIDTH = 1110;
export const INVOICE_SELECTION_TOP_PADDING = 4;

export const INVOICE_SELECTION_ITEM_HEIGHT = 52;
export const INVOICE_SELECTION_ITEM_HEIGHT_PADDING = 4;
export const INVOICE_SELECTION_ITEM_LEFT_PADDING = 4;
export const INVOICE_SELECTION_ITEM_WIDTH = '99%';
