import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { providerCreditsReducer } from './provider/reducer';
import providerCreditsSaga from './provider/sagas';
import { ProviderCreditsState } from './provider/types';
import { uploadCreditInvoiceReducer } from './upload-invoice-document/reducer';
import uploadCreditInvoiceSaga from './upload-invoice-document/sagas';
import { UploadCreditInvoiceState } from './upload-invoice-document/types';

export interface CreditState {
    readonly providerCredits: ProviderCreditsState;
    readonly invoice: UploadCreditInvoiceState;
}

export const CreditReducer: ReducersMapObject<CreditState, AnyAction> = {
    providerCredits: providerCreditsReducer,
    invoice: uploadCreditInvoiceReducer
};

export function* creditSagas() {
    yield all([fork(providerCreditsSaga)]);
    yield all([fork(uploadCreditInvoiceSaga)]);
}
