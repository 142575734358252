import React from 'react';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { NotificationToListActive } from '../../../../../model/notification';
import { ProgramToList } from '../../../../../model/program';
import { useActiveNotificationsState, useRootDispatch } from '../../../../../reducer/hooks';
import { activeNotificationsRequest } from '../../../../../reducer/notification/list-active/actions';
import notificationStorage from '../../../../../shared/notification/notification-storage';
import './organization-notifications.scss';

export interface OrganizationNotificationsProps {
    program: ProgramToList;
}

export const OrganizationNotifications = (props: OrganizationNotificationsProps) => {
    const dispatch = useRootDispatch();
    const { notifications, status } = useActiveNotificationsState();
    const [seen, setSeen] = React.useState<string[]>(notificationStorage.getNotifications());

    React.useEffect(() => {
        dispatch(activeNotificationsRequest(props.program.id));
    }, [dispatch, props.program.id]);

    if (status !== HttpRequestStatus.SUCCESS || !notifications || notifications.length < 1) {
        return <></>;
    }

    const handleCloseNotification = (notification: NotificationToListActive) => {
        const update = notificationStorage.addNotification(notification.id);
        setSeen(update);
    };

    return (
        <div className="organization-notification__container">
            {notifications
                .filter(notification => !seen.some(it => it === notification.id))
                .map(notification => (
                    <div
                        className={`organization-notification__item organization-notification__item--${notification.type}`}
                        key={notification.id}
                    >
                        <div className="organization-notification__icon--close" onClick={() => handleCloseNotification(notification)} />
                        <strong className="organization-notification__title">{notification.title}</strong>
                        <div className="organization-notification__message">{notification.message}</div>
                    </div>
                ))}
        </div>
    );
};

export default OrganizationNotifications;
