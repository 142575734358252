import React from 'react';
import HeaderProvider from './header-provider';
import SimpleHeader from './simple-header';

export enum HeaderType {
    NONE = 'NONE',
    SIMPLE = 'SIMPLE',
    PROVIDER = 'PROVIDER'
}

export interface HeaderProps {
    type: HeaderType;
}

export const Header = (props: HeaderProps) => {
    switch (props.type) {
        case HeaderType.PROVIDER:
            return <HeaderProvider />;
        case HeaderType.SIMPLE:
            return <SimpleHeader />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};

export default Header;
