import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { defaultCompanyReducer } from './default/reducer';
import defaultCompanySaga from './default/sagas';
import { DefaultCompanyState } from './default/types';
import { myCompaniesReducer } from './myCompanies/reducer';
import myCompaniesSaga from './myCompanies/sagas';
import { MyCompaniesState } from './myCompanies/types';

export interface CompanyState {
    readonly companyDefault: DefaultCompanyState;
    readonly myCompanies: MyCompaniesState;
}

export const CompanyReducer: ReducersMapObject<CompanyState, AnyAction> = {
    companyDefault: defaultCompanyReducer,
    myCompanies: myCompaniesReducer
};

export function* companySagas() {
    yield all([fork(defaultCompanySaga), fork(myCompaniesSaga)]);
}
