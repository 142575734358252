import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { useCompanyDefaultState, useUserAccountState } from '../../reducer/hooks';

export const useIsHeaderProviderLoading = () => {
    const accountState = useUserAccountState();
    const companyState = useCompanyDefaultState();

    return (
        accountState.status !== HttpRequestStatus.ERROR &&
        !(
            accountState.status === HttpRequestStatus.SUCCESS &&
            (companyState.status === HttpRequestStatus.ERROR || companyState.status === HttpRequestStatus.SUCCESS)
        )
    );
};
