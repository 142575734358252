import { action } from "typesafe-actions";
import { CreditInvoiceUploadRequest } from "../../../model/credit";
import { GiroWebappError } from "../../../model/error";
import { FileToSimple } from "../../../model/File";
import { UploadCreditInvoiceTypes } from "./types";

export const uploadCreditInvoiceRequest = (request: CreditInvoiceUploadRequest) => action(UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_REQUEST, request);
export const uploadCreditInvoiceSuccess = (invoice: FileToSimple) => action(UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_SUCCESS, invoice);
export const uploadCreditInvoiceError = (error: GiroWebappError) => action(UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_ERROR, error);
export const uploadCreditInvoiceResetState = () => action(UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_RESET_STATE);
