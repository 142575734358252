import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './context-ribbon.scss';

export const ContextRibbon: React.FunctionComponent = props => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    return (
        <div className="context-ribbon">
            <Container>
                <Row>
                    <Col>
                        <div className="context-ribbon__back" onClick={handleBack}>
                            <div className="context-ribbon__back-icon" />
                            <span>{t('global.back')} </span>
                        </div>
                    </Col>
                    <Col>{props.children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContextRibbon;
