import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { activeNotificationsReducer } from './list-active/reducer';
import activeNotificationsSaga from './list-active/sagas';
import { ActiveNotificationsState } from './list-active/types';

export interface NotificationState {
    activeNotifications: ActiveNotificationsState;
}

export const NotificationReducer: ReducersMapObject<NotificationState, AnyAction> = {
    activeNotifications: activeNotificationsReducer
};

export function* notificationSagas() {
    yield all([fork(activeNotificationsSaga)]);
}
