import React from 'react';
import './sortable-column.scss';

export type Order = 'asc' | 'desc' | undefined;

const SortIcon = ({ active, order }) => {
    if (!active || !order) {
        return <div className="icon-flechas" />;
    }
    if (order === 'asc') {
        return <div className="icon-flechas1" />;
    }
    return <div className="icon-flechas2" />;
};

export interface Sortable {
    active: boolean;
    order: Order;
    property: string;
    isLoading: boolean;
    disabled: boolean;
    onSort: (property: string, order: Order) => void;
}

export interface SortableColumnProps {
    label: string;
    sortable?: Sortable;
}

export const SortableColumn = (props: SortableColumnProps) => {
    if (!props.sortable) {
        return (
            <div className="sortable-column__items">
                <div className="sortable-column__items--text">{props.label}</div>
            </div>
        );
    }

    const { active, order, property, isLoading, disabled, onSort } = props.sortable;

    const handleSort = () => {
        if (!isLoading && !disabled) {
            onSort(property, order);
        }
    };

    return (
        <div className="sortable-column__items" onClick={handleSort}>
            <div className="sortable-column__items--text">{props.label}</div>
            <div className="sortable-column__items--icon">
                <SortIcon active={active} order={order} />
            </div>
        </div>
    );
};

export default SortableColumn;
