import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { inviteToAcceptReducer } from './toAccept/reducer';
import inviteToAcceptSaga from './toAccept/sagas';
import { InviteToAcceptState } from './toAccept/types';

export interface InviteState {
    readonly inviteToAccept: InviteToAcceptState;
}

export const InviteReducer: ReducersMapObject<InviteState, AnyAction> = {
    inviteToAccept: inviteToAcceptReducer
};

export function* inviteSagas() {
    yield all([fork(inviteToAcceptSaga)]);
}
