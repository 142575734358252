import React, { ReactText, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const useToast = () => {
    const toastId = useRef<ReactText>(0);
    const { t } = useTranslation();

    const toastSuccess = useCallback((value: string) => {
        return toast(value, {
            autoClose: 2500,
            isLoading: false,
            type: 'success',
            icon: false,
        });
    }, []);

    const toastLoading = useCallback(
        (value?: string) => {
            return (toastId.current = toast.loading(value ?? t('toast.request.loading'), {
                autoClose: false,
                closeButton: false,
            }));
        },
        [t]
    );

    const closeToast = useCallback(() => {
        return toast.update(toastId.current, {
            autoClose: 400,
            isLoading: false,
        });
    }, []);

    const toastInfo = useCallback((message: string, icon?: React.ReactNode) => {
        return (toastId.current = toast.info(message, {
            autoClose: 2500,
            closeButton: false,
            icon: icon ?? <InfoOutlinedIcon fontSize="large" />,
        }));
    }, []);

    const toastError = useCallback((value: string) => {
        return toast(value, {
            autoClose: 2000,
            isLoading: false,
            type: 'error',
            icon: false,
        });
    }, []);

    return { toastSuccess, toastLoading, closeToast, toastInfo, toastError };
};
