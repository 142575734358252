import { FilledInputProps } from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Check from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyCodeUtils } from '../../shared/util/keypress-utils';
import { ValidationResult } from '../../shared/util/validation-utils';
import './field-basic.scss';

type PartialTextFieldProps = Pick<
    TextFieldProps,
    'className' | 'margin' | 'autoFocus' | 'onFocus' | 'onBlur' | 'autoComplete' | 'type' | 'InputProps' | 'InputLabelProps'
>;

export interface FieldBasicsProps extends PartialTextFieldProps {
    label: string;
    value?: string;
    onChange?: (value: string) => void;
    validate?: (value: string) => ValidationResult;
    showValidation?: boolean;
    readOnly?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    onEnter?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    helpText?: string;
}

export const FieldBasic = (props: FieldBasicsProps) => {
    const { value: propValue, onChange, validate, readOnly, onEnter, showValidation, helpText, InputProps, ...rest } = props;
    const { t } = useTranslation();

    const [value, setValue] = React.useState<string>(props.value ?? '');
    const [isFocused, setFocused] = React.useState<boolean>(false);

    const [validation, setValidation] = React.useState<ValidationResult>(
        validate && props.value ? validate(props.value) : { isValid: true }
    );

    React.useEffect(() => {
        if (showValidation && validate) {
            const _validation = validate(value);
            setValidation(_validation);
        }
    }, [validate, value, showValidation]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event.target.value;
        if (_value === value) {
            return;
        }
        if (onChange) {
            onChange(_value);
        }
        setValue(_value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyCodeUtils.isEnterKey(event)) {
            event.preventDefault();
            if (onEnter) {
                onEnter(event);
            }
        }
    };

    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);

    const validAdornment = (
        <InputAdornment className="text__field--valid-adornment" position="end">
            <Check />
        </InputAdornment>
    );
    const adornment: Pick<FilledInputProps, 'endAdornment'> | undefined =
        showValidation && validation.isValid ? { endAdornment: validAdornment } : undefined;

    const className = [
        'text__field',
        props.className,
        value && 'text__has-value',
        isFocused && 'text__field--focused',
        showValidation && validation.isValid && 'text__field--valid',
        showValidation && !validation.isValid && 'text__field--has-error'
    ]
        .filter(it => !!it)
        .join(' ');

    return (
        <TextField
            fullWidth
            {...rest}
            // caution with props order
            // https://github.com/mui-org/material-ui/issues/15697
            variant="filled"
            InputProps={{ readOnly: props.readOnly, ...adornment, ...InputProps }}
            onChange={handleChange}
            className={className}
            value={value}
            autoComplete={props.autoComplete}
            error={showValidation && !!validate && !validation.isValid}
            helperText={showValidation && !!validate && !validation.isValid ? t(validation.errorMessage) : helpText}
            inputRef={props.inputRef}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
};

export default FieldBasic;
