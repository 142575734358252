import Modal from '@material-ui/core/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Currency from '../../../../../components/format/currency/Currency';
import { useBatchRequestState } from '../../../../../reducer/hooks';
import './anticipation-success.scss';

export interface AnticipationSuccessProps {
    open: boolean;
    onClose: () => void;
}

export const AnticipationSuccess = (props: AnticipationSuccessProps) => {
    const { t } = useTranslation();
    const { batch } = useBatchRequestState();

    return (
        <div className="container">
            <div className="body__anticipation-success">
                <Modal open={props.open} disableBackdropClick disableEscapeKeyDown>
                    <div id="ref-base-component">
                        <Row style={{ background: 'white', height: '100vh' }} className="align-items-center">
                            <Col md="5" style={{ margin: 'auto' }}>
                                <div className="body__anticipation-success--itens">
                                    <div className="body__anticipation-success--image" />
                                    {/* <div className="body__anticipation-success--title">
                                        <div style={{ fontWeight: 400 }}>
                                            {t('anticipation-simulation.anticipation-success.title-start').toUpperCase()}
                                        </div>
                                        <span>XX days</span>
                                        {t('anticipation-simulation.anticipation-success.title-end').toUpperCase()}
                                    </div>
                                    <div className="body__anticipation-success--line" /> */}
                                    <div className="body__anticipation-success--subtitle">
                                        {t('anticipation-simulation.anticipation-success.subtitle')}
                                    </div>
                                    <div className="body__anticipation-success--final-text">
                                        {t('anticipation-simulation.anticipation-success.text-start')}
                                        <span>
                                            {t('anticipation-simulation.anticipation-success.days-to-pay', {
                                                count: batch?.daysToPay ?? 0
                                            })}
                                        </span>
                                        {t('anticipation-simulation.anticipation-success.text-value')}
                                        <span>
                                            <Currency value={batch?.value} />
                                        </span>
                                        <span> {t('anticipation-simulation.anticipation-success.text-middle')}</span>
                                        {t('anticipation-simulation.anticipation-success.text-end')}
                                    </div>
                                    <button className="body__anticipation-success--button" onClick={props.onClose}>
                                        {t('anticipation-simulation.anticipation-success.button.ok').toUpperCase()}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default AnticipationSuccess;
