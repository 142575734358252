import Arrow from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import BackButton from '../../../../components/buttons/back-button/back-button';
import ProgramSelectionItem from '../../../../components/giro/program/program-selection-item/program-selection-item';
import Loading from '../../../../components/loading/loading';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { ProgramToList } from '../../../../model/program';
import { useCompanyDefaultState, useListProgramsState, useRootDispatch } from '../../../../reducer/hooks';
import { listProgramsRequest } from '../../../../reducer/program/list/actions';
import { ListProgramsState } from '../../../../reducer/program/list/types';
import FormatUtils from '../../../../shared/util/format-utils';
import { AnticipationInvoicesLocation } from '../../../anticipation/invoices/anticipation-invoices';
import './dashboard-programs.scss';

const useListProgramsRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(listProgramsRequest(3));
    }, [dispatch]);
};

const useProgramsAwareness = () => {
    const state: ListProgramsState = useListProgramsState();
    const isLoading = state.status !== HttpRequestStatus.SUCCESS && state.status !== HttpRequestStatus.ERROR;
    const hasError = state.status === HttpRequestStatus.ERROR;
    return { isLoading, hasError };
};

export const DashboardPrograms = () => {
    const history = useHistory();
    const [t] = useTranslation();
    const dispatch = useRootDispatch();

    useListProgramsRequest();

    const { programs } = useListProgramsState();
    const { company } = useCompanyDefaultState();
    const { isLoading, hasError } = useProgramsAwareness();

    const handleSelect = (_program: ProgramToList) => {
        const params: AnticipationInvoicesLocation = { program: _program };
        history.push('/anticipation/invoices', params);
    };

    const handleTryAgain = () => {
        dispatch(listProgramsRequest());
    };

    if (isLoading) {
        return (
            <div className="dashboard__program-selection--loading">
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="dashboard__program-selection--error">
                <div className="program-selection__error-message">{t('global.program-selection.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    if (!programs || programs.length === 0) {
        return (
            <div className="dashboard__program-selection--empty">
                {t('global.program-selection.no-programs', {
                    name: company?.name ?? '',
                    identification: FormatUtils.formatIdentification(company?.type, company?.identification)
                })}
            </div>
        );
    }

    return (
        <>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
                <Col>
                    <div className="dashboard--title">{t('dashboard.programs.title')}</div>
                </Col>
            </Row>
            {programs.map(p => (
                <Row key={p.id} style={{ marginBottom: 5 }}>
                    <Col>
                        <ProgramSelectionItem program={p} onSelect={handleSelect} />
                    </Col>
                </Row>
            ))}
            <Row style={{ marginBottom: 15 }}>
                <Col>
                    <div className="dashboard--link">
                        <Link to="/program-selection">
                            {t('dashboard.programs.link')}
                            <Arrow className="link--arrow" />
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default DashboardPrograms;
