import React from 'react';
import './program-chart.scss';

export interface ProgramChartProps {
    color?: string;
}

export const ProgramChart = (props: ProgramChartProps) => {
    const barColorStyle = props.color ? { backgroundColor: props.color } : {};

    return (
        <div className="program-chart">
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div style={{ marginRight: '2px' }} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
            <div className="program-chart__chart-bar" style={barColorStyle} />
        </div>
    );
};

export default ProgramChart;
