import { AxiosResponse } from 'axios';
import { CreditEstimateToRequest, EstimateRequest } from '../../model/estimate';
import { api } from './api';

const BASE_URL = 'api/webapp/estimates';

export const estimates = () => {

    const estimateRequest = (request: EstimateRequest): Promise<AxiosResponse<CreditEstimateToRequest>> => {
        return api.put<CreditEstimateToRequest>(`${BASE_URL}/request`, request);
    }

    const estimateAllRequest = (providerId: string): Promise<AxiosResponse<CreditEstimateToRequest>> => {
        return api.put<CreditEstimateToRequest>(`${BASE_URL}/programProvider/${providerId}/request`);
    }

    return {
        estimateRequest,
        estimateAllRequest
    };
};

export default estimates();
