import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSteps } from '../invite-steps/invite-steps-context';
import './step-progress.scss';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    // active: {
    //     '& $line': {
    //         borderColor: '#784af4'
    //     }
    // },
    // completed: {
    //     '& $line': {
    //         borderColor: '#784af4'
    //     }
    // },
    line: {
        // borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    if (active) {
        return <div className="step--active" />;
    }
    if (completed) {
        return <div className="step--complete" />;
    }
    return <div className="step--disabled" />;
};

export const StepProgress = (): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();

    if (steps.shouldHideStepper()) return <></>;

    return (
        <Stepper alternativeLabel activeStep={steps.activeIndex()} connector={<QontoConnector />}>
            <Step key="Identificacao">
                <StepLabel StepIconComponent={QontoStepIcon}>{t('accept-invite.step.identification')}</StepLabel>
            </Step>
            <Step key="Senha">
                <StepLabel StepIconComponent={QontoStepIcon}>{t('accept-invite.step.password')}</StepLabel>
            </Step>
            <Step key="Contato">
                <StepLabel StepIconComponent={QontoStepIcon}>{t('accept-invite.step.contact')}</StepLabel>
            </Step>
            <Step key="Finalizacao">
                <StepLabel StepIconComponent={QontoStepIcon}>{t('accept-invite.step.terms-of-use')}</StepLabel>
            </Step>
        </Stepper>
    );
};

export default StepProgress;
