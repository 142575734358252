import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { WhatsAppStatus } from '../../model/enums/whatsapp-status';
import './whatsapp-options.scss';

export interface WhatsappOptionsProps {
    whatsappStatus: WhatsAppStatus;
    onChange: (status: WhatsAppStatus) => void;
}

export const WhatsappOptions = (props: WhatsappOptionsProps) => {
    const { t } = useTranslation();
    const [status, setStatus] = React.useState<WhatsAppStatus>(props.whatsappStatus ?? WhatsAppStatus.NOT_KNOWN);

    const handdleClick = (newStatus: WhatsAppStatus) => {
        if (status !== newStatus) {
            props.onChange(newStatus);
            setStatus(newStatus);
        }
    };

    return (
        <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Col md="6">
                <div className="body__contact--button-yes" onClick={() => handdleClick(WhatsAppStatus.RECEIVE)}>
                    {status === WhatsAppStatus.RECEIVE ? (
                        <div className="button__contact--full" />
                    ) : (
                        <div className="button__contact--empty" />
                    )}
                    <span>{t('accept-invite.buttons.whats-yes').toUpperCase()}</span>
                </div>
            </Col>
            <Col md="6">
                <div className="body__contact--button-no" onClick={() => handdleClick(WhatsAppStatus.NOT_RECEIVE)}>
                    {status === WhatsAppStatus.NOT_RECEIVE ? (
                        <div className="button__contact--full" />
                    ) : (
                        <div className="button__contact--empty" />
                    )}
                    <span>{t('accept-invite.buttons.whats-no').toUpperCase()}</span>
                </div>
            </Col>
        </Row>
    );
};

export default WhatsappOptions;
