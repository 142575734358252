import { useCompanyDefaultState } from '../../reducer/hooks';
import { useDoesHeaderProviderHasError } from './header-provider-has-error';
import { useIsHeaderProviderLoading } from './header-provider-is-loading';

export const useIsCompanySelectionRequired = () => {
    const companyState = useCompanyDefaultState();

    const isLoading = useIsHeaderProviderLoading();
    const hasError = useDoesHeaderProviderHasError();

    return !isLoading && !hasError && !companyState.company?.id;
};
