import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { NotificationToListActive } from '../../../model/notification';
import notificationApi from '../../../services/api/notificationApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { activeNotificationsError, activeNotificationsRequest, activeNotificationsSuccess } from './actions';
import { ActiveNotificationsActionTypes } from './types';

function* handleActiveNotificationsRequest(action: ReturnType<typeof activeNotificationsRequest>) {
    try {
        const result: AxiosResponse<NotificationToListActive[]> = yield call(notificationApi.getAllActive, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(activeNotificationsError(mapped));
            return;
        }
        yield put(activeNotificationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(activeNotificationsError(mapped));
    }
}

function* watchActiveNotificationsRequest() {
    yield takeEvery(ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_REQUEST, handleActiveNotificationsRequest);
}

function* activeNotificationsSaga() {
    yield all([fork(watchActiveNotificationsRequest)]);
}

export default activeNotificationsSaga;
