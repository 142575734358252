import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { hasAcceptedTermsResetState } from '../../../../reducer/account/terms-of-use/actions';
import { userAccountResetState } from '../../../../reducer/account/user-account/actions';
import { logoutRequest } from '../../../../reducer/authentication/actions';
import { defaultCompanyResetState } from '../../../../reducer/company/default/actions';
import { useRootDispatch } from '../../../../reducer/hooks';
import authUtil from '../../../../services/api/authUtil';
import notificationStorage from '../../../../shared/notification/notification-storage';

export const ProfileLogout = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const handleLogoff = () => {
        dispatch(logoutRequest());
        authUtil.removeToken();
        history.push('/');
        dispatch(userAccountResetState());
        dispatch(hasAcceptedTermsResetState());
        dispatch(defaultCompanyResetState());
        notificationStorage.reset();
    };

    return (    
        <div className="personal-data__items--sidebar-subtitle"  onClick={handleLogoff} >
            {t('profile.exit')}
            <div className="personal-data__items--sidebar-icon" />
        </div>
    );
};

export default ProfileLogout;
