import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './responsive-step.scss';

export interface ResponsiveStepProps {
    label: string;
    isOpen: boolean;
    isCompleted: boolean;
    onEdit: () => void;
}

export const ResponsiveStep: FunctionComponent<ResponsiveStepProps> = props => {
    const { t } = useTranslation();

    const handleEditClick = () => {
        if (!props.isOpen && props.isCompleted) {
            props.onEdit();
        }
    };

    return (
        <>
            <div className="step-images">
                <div className="step-images--actions" onClick={handleEditClick}>
                    {t(props.label).toUpperCase()}
                    {!props.isOpen && props.isCompleted && (
                        <>
                            <div className="step-edit">
                                <span>{t('accept-invite.step-edit')}</span>
                            </div>
                        </>
                    )}
                    {!props.isOpen && props.isCompleted && (
                        <>
                            <div className="step-check" />
                        </>
                    )}

                    {props.isOpen && !props.isCompleted && (
                        <>
                            <div className="step-next" />
                        </>
                    )}
                    {!props.isOpen && !props.isCompleted && (
                        <>
                            <div className="step-close" />
                        </>
                    )}
                </div>
            </div>
            {props.children}
        </>
    );
};

export default ResponsiveStep;
