import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { batchRequestReducer } from './request/reducer';
import batchRequestSaga from './request/sagas';
import { BatchRequestState } from './request/types';

export interface BatchState {
    readonly batchRequest: BatchRequestState;
}

export const BatchReducer: ReducersMapObject<BatchState, AnyAction> = {
    batchRequest: batchRequestReducer
};

export function* batchSagas() {
    yield all([fork(batchRequestSaga)]);
}
