import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import CompanySelection from './components/company-selection/company-selection';
import './modal-company.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            width: '825px',
            height: '535px',
            borderRadius: '6px'
        }
    })
);

interface FadeProps {
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}

const Fade: React.FunctionComponent<FadeProps> = props => {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) onEnter();
        },
        onRest: () => {
            if (!open && onExited) onExited();
        }
    });

    return (
        <animated.div style={style} {...other}>
            {children}
        </animated.div>
    );
};

export interface ModalCompanyProps {
    open: boolean;
    required: boolean;
    closeModal: () => void;
    onAccess: () => void;
}

export const ModalCompany = (props: ModalCompanyProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const open = props.required || props.open;

    const handleClose = () => {
        props.closeModal();
    };

    const handleAccess = () => {
        props.onAccess();
    };

    return (
        <div className="modal-company__itens">
            <Modal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <div>
                    <Fade in={open}>
                        <div className={classes.paper}>
                            {!props.required && (
                                <img
                                    className="modal-company__itens-img"
                                    style={{ width: '40px', height: '40px' }}
                                    src={require('../../images/fechar.svg')}
                                    onClick={handleClose}
                                    alt="close"
                                />
                            )}
                            <div className="modal-company__itens-title">
                                <span>{t('modal-company.title')}</span>
                            </div>

                            <CompanySelection onAccess={handleAccess} />
                        </div>
                    </Fade>
                </div>
            </Modal>
        </div>
    );
};

export default ModalCompany;
