import React from 'react';
import FormatUtils from '../../../shared/util/format-utils';

export interface CurrencyProps {
    value?: number;
}

export const Currency = (props: CurrencyProps) => {
    return <>{FormatUtils.formatCurrency(props.value ?? 0)}</>;
};

export default Currency;
