import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/buttons/action-button/action-button';
import Currency from '../../../../components/format/currency/Currency';
import Idenfitication from '../../../../components/format/identification/Identification';
import { CompanyToList } from '../../../../model/company';
import './company-item.scss';

export interface CompanyItemProps {
    company: CompanyToList;
    onAccess: (company: CompanyToList) => void;
}

export const CompanyItem = (props: CompanyItemProps) => {
    const { t } = useTranslation();
    const { company } = props;

    const handleAccess = () => {
        props.onAccess(company);
    };

    return (
        <div className="company-selection-item">
            <div className="company-selection-item__company-identification">
                <Idenfitication type={company.type} value={company.identification} />
                <span>{company.name}</span>
            </div>
            <div className="company-selection-item__selection-border" />
            <div className="company-selection-item__company-credits">
                <span>{t('modal-company.available-credits').toUpperCase()}</span>
                <Currency value={company.value} />
            </div>
            <div className="company-selection-item__actions">
                <ActionButton label={t('modal-company.access').toUpperCase()} onClick={handleAccess} />
            </div>
        </div>
    );
};

export default CompanyItem;
