import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import BackButton from '../../components/buttons/back-button/back-button';
import Loading from '../../components/loading/loading';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { ProgramToList } from '../../model/program';
import { useCompanyDefaultState, useListProgramsState, useRootDispatch } from '../../reducer/hooks';
import { listProgramsRequest } from '../../reducer/program/list/actions';
import { ListProgramsState } from '../../reducer/program/list/types';
import FormatUtils from '../../shared/util/format-utils';
import { AnticipationInvoicesLocation } from '../anticipation/invoices/anticipation-invoices';
import ProgramSelectionList from './components/program-selection-list/program-selection-list';
import './program-selection.scss';

const useProgramsRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(listProgramsRequest());
    }, [dispatch]);
};

const useProgramsAwareness = () => {
    const state: ListProgramsState = useListProgramsState();
    const isLoading = state.status !== HttpRequestStatus.SUCCESS && state.status !== HttpRequestStatus.ERROR;
    const hasError = state.status === HttpRequestStatus.ERROR;
    return { isLoading, hasError };
};

export const ProgramSelection = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    useProgramsRequest();
    const { programs } = useListProgramsState();
    const { company } = useCompanyDefaultState();
    const { isLoading, hasError } = useProgramsAwareness();

    const handleSelect = (_program: ProgramToList) => {
        const params: AnticipationInvoicesLocation = { program: _program };
        history.push('/anticipation/invoices', params);
    };

    const handleTryAgain = () => {
        dispatch(listProgramsRequest());
    };

    if (isLoading) {
        return (
            <div className="container__program-selection--loading">
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="container__program-selection--error">
                <div className="program-selection__error-message">{t('global.program-selection.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    if (!programs || programs.length === 0) {
        return (
            <div className="container__program-selection--empty">
                {t('global.program-selection.no-programs', {
                    name: company?.name ?? '',
                    identification: FormatUtils.formatIdentification(company?.type, company?.identification)
                })}
            </div>
        );
    }

    return (
        <div className="container__program-selection">
            <Container>
                <Row style={{ marginTop: 30, marginBottom: 20 }}>
                    <Col>
                        <div className="program-selection--title">{t('program-selection.title')}</div>
                    </Col>
                </Row>
                <ProgramSelectionList programs={programs} onSelect={handleSelect} />
            </Container>
        </div>
    );
};

export default ProgramSelection;
