import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import Footer, { FooterType } from '../../features/footer/footer';
import Header, { HeaderType } from '../../features/header/header';
import HeaderAwareness from '../../features/header/header-awareness';
import AuthUtil from '../../services/api/authUtil';
import ErrorHandler from '../error/error-handler';

export interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    headerType?: HeaderType;
    footerType?: FooterType;
    DISABLED?: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    if (props.DISABLED) {
        return <Redirect to="/page-not-found" />;
    }
    if (!AuthUtil.isAuthenticated()) {
        return <Redirect to="/" />;
    }
    const header = props.headerType ?? HeaderType.PROVIDER;
    return (
        <Route
            {...rest}
            render={routeProps => (
                <>
                    <ErrorHandler />
                    <Header type={header} />
                    {header === HeaderType.PROVIDER ? (
                        <HeaderAwareness>
                            <Component {...routeProps} />
                        </HeaderAwareness>
                    ) : (
                        <Component {...routeProps} />
                    )}
                    <Footer type={props.footerType ?? FooterType.SIMPLE} />
                </>
            )}
        />
    );
};

export default PrivateRoute;
