import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { NotificationToListActive } from '../../../model/notification';
import { ActiveNotificationsActionTypes } from './types';

export const activeNotificationsRequest = (programId: string) =>
    action(ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_REQUEST, programId);
export const activeNotificationsSuccess = (notifications: NotificationToListActive[]) =>
    action(ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_SUCCESS, notifications);
export const activeNotificationsError = (error: GiroWebappError) =>
    action(ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_ERROR, error);
export const activeNotificationResetState = () => action(ActiveNotificationsActionTypes.ACTIVE_NOTIFICATIONS_RESET_STATE);
