import { AxiosResponse } from 'axios';
import { BatchRequested, BatchToRequest } from '../../model/batch';
import { api } from './api';

const BASE_URL = 'api/webapp/batches';

export const estimates = () => {

    const batchRequest = (request: BatchToRequest): Promise<AxiosResponse<BatchRequested>> => {
        return api.post<BatchRequested>(`${BASE_URL}/request`, request);
    }

    return {
        batchRequest,
    };
};

export default estimates();
