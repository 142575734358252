import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { userByResetKeyReducer } from './reset-key/reducer';
import userByResetKeySaga from './reset-key/sagas';
import { UserByResetKeyState } from './reset-key/types';
import { validateEmailReducer } from './validate-email/reducer';
import validateEmailSaga from './validate-email/sagas';
import { ValidateEmailState } from './validate-email/types';

export interface UserState {
    readonly userByResetKey: UserByResetKeyState;
    readonly validateEmail: ValidateEmailState;
}

export const UserReducer: ReducersMapObject<UserState, AnyAction> = {
    userByResetKey: userByResetKeyReducer,
    validateEmail: validateEmailReducer
};

export function* userSagas() {
    yield all([fork(userByResetKeySaga), fork(validateEmailSaga)]);
}
