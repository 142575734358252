import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNext from '../../../../components/button-next/button-next';
import ButtonPrevious from '../../../../components/button-previous/button-previous';
import { Card } from '../../../../components/card/card';
import PhoneInput from '../../../../components/phone-input/phone-input';
import WhatsappOptions from '../../../../components/whatsapp-options/whatsapp-options';
import { WhatsAppStatus } from '../../../../model/enums/whatsapp-status';
import { InviteToCreateProvider } from '../../../../model/invite';
import { COLLAPSE_TIMEOUT } from '../animation/animation-constants';
import { useSteps } from '../invite-steps/invite-steps-context';
import { validatePhoneNumber } from '../validation/validation-constants';
import { CardVisibility, HIDE_CARDS, useContactContext } from './contact-context';
import './contact.scss';

export interface ContactProps {
    phoneNumber: string;
    whatsappStatus: WhatsAppStatus;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const Contact = (props: ContactProps) => {
    const { t } = useTranslation();
    const steps = useSteps();
    const { showCard, updateCards } = useContactContext();
    const [status, setStatus] = React.useState<WhatsAppStatus>(props.whatsappStatus ?? WhatsAppStatus.NOT_KNOWN);
    const [phone, setPhone] = React.useState<string>(props.phoneNumber ?? '');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const handleWhatsappStatusChange = (newStatus: WhatsAppStatus) => {
        if (status !== newStatus) {
            setStatus(newStatus);
        }
    };

    const handleCardClose = () => {
        updateCards(HIDE_CARDS, { INFORMATIVE: CardVisibility.SEEN });
    };

    const haltProgression =
        !status || status === WhatsAppStatus.NOT_KNOWN || (status === WhatsAppStatus.RECEIVE && !validatePhoneNumber(phone).isValid);

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) {
            return;
        }
        if (status !== props.whatsappStatus || phone !== props.phoneNumber) {
            const update = status === WhatsAppStatus.RECEIVE ? { whatsAppStatus: status, phoneNumber: phone } : { whatsAppStatus: status };
            props.onChange(update);
        }
        steps.toNext();
    };

    return (
        <div className="body__contact">
            <Row>
                <Col md="12" lg={{ size: 6, offset: 3 }}>
                    <Row>
                        <Col>
                            <div className="body__contact--title">
                                {t('accept-invite.contact.start')}
                                {t('accept-invite.contact.end')}
                            </div>
                        </Col>
                    </Row>
                    <WhatsappOptions whatsappStatus={props.whatsappStatus} onChange={handleWhatsappStatusChange} />
                    <Collapse in={status === WhatsAppStatus.RECEIVE} timeout={COLLAPSE_TIMEOUT} unmountOnExit>
                        <div className="field--background">
                            <Row style={{ justifyContent: 'center' }}>
                                <Col>
                                    <PhoneInput
                                        value={props.phoneNumber}
                                        label={t('accept-invite.contact.phone.label')}
                                        onChange={setPhone}
                                        validate={validatePhoneNumber}
                                        autoFocus
                                        showValidation={showValidation}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                    <Row>
                        <div className="body__contact--buttons">
                            <Col>
                                <div className="body__contact--button-previous">
                                    <ButtonPrevious onClick={steps.toPrevious} />
                                </div>
                            </Col>
                            <Col>
                                <div className="body__contact--button-next">
                                    <ButtonNext
                                        onClick={handleNext}
                                        disabled={status === WhatsAppStatus.NOT_KNOWN || (showValidation && haltProgression)}
                                    />
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Col>
                <Col>
                    {
                        {
                            INFORMATIVE: (
                                <Card onClose={handleCardClose}>
                                    {t('accept-invite.contact.card.whatsapp.start')}
                                    <p>{t('accept-invite.contact.card.whatsapp.end')}</p>
                                </Card>
                            ),
                            HIDE_CARDS: <></>
                        }[showCard]
                    }
                </Col>
            </Row>
        </div>
    );
};

export default Contact;
