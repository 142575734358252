import { ProgramSimulationExample } from '../../../model/program';
import { BaseStoreState } from '../../baseStoreState';

export enum ProgramSimulationExampleActionTypes {
  PROGRAM_SIMULATION_EXAMPLE_REQUEST = '@@programSimulationExample/REQUEST_BATCH_REQUEST',
  PROGRAM_SIMULATION_EXAMPLE_SUCCESS = '@@programSimulationExample/REQUEST_BATCH_SUCCESS',
  PROGRAM_SIMULATION_EXAMPLE_ERROR = '@@programSimulationExample/REQUEST_BATCH_ERROR',
  PROGRAM_SIMULATION_EXAMPLE_RESET_STATE = '@@programSimulationExample/REQUEST_BATCH_RESET_STATE',
}

export interface ProgramSimulationExampleState extends BaseStoreState {
  readonly example?: ProgramSimulationExample;
}
