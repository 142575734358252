import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/buttons/back-button/back-button';
import Loading from '../../components/loading/loading';
import { useIsCompanySelectionRequired } from '../../shared/hooks/company-selection-required';
import { useDoesHeaderProviderHasError } from '../../shared/hooks/header-provider-has-error';
import { useIsHeaderProviderLoading } from '../../shared/hooks/header-provider-is-loading';
import './header-awareness.scss';

export const HeaderAwareness: React.FunctionComponent = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const isLoading = useIsHeaderProviderLoading();
    const hasError = useDoesHeaderProviderHasError();
    const requireCompanySelection = useIsCompanySelectionRequired();

    const handleRefresh = () => {
        history.go(0);
    };

    if (isLoading) {
        return (
            <div className="header-awareness__container--loading">
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="header-awareness__container--has-error">
                <div className="header-awareness__text">{t('header.error.message')}</div>
                <BackButton label={t('header.error.refresh').toUpperCase()} onClick={handleRefresh} />
            </div>
        );
    }

    if (requireCompanySelection) {
        // modal should pop up
        // wait for company selection
        return <></>;
    }

    return <>{props.children}</>;
};

export default HeaderAwareness;
