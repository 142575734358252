import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Currency from '../../../../../components/format/currency/Currency';
import FormattedDate from '../../../../../components/format/date/formatted-date';
import { useEstimateRequestState } from '../../../../../reducer/hooks';
import FormatUtils from '../../../../../shared/util/format-utils';
import './simulation-header.scss';

export interface SimulationHeaderProps {
    onRequest: () => void;
    onExample: () => void;
}

export const SimulationHeader = (props: SimulationHeaderProps) => {
    const { t } = useTranslation();

    const { estimate } = useEstimateRequestState();

    return (
        <div className="container__scroll">
            <Container>
                <Row>
                    <Col>
                        <div className="scroll--text">
                            <div className="scroll--text-title">
                                <div>{t('anticipation-simulation.simulation-details.title-start').toUpperCase()} </div>
                                <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                    <FormattedDate date={estimate?.date} />
                                </div>
                                <div>{t('anticipation-simulation.simulation-details.title-end').toUpperCase()} </div>
                            </div>
                            <div className="scroll--text-price">
                                <Currency value={estimate?.total} />
                            </div>
                            <button className="scroll--text-button" onClick={props.onRequest}>
                                {t('anticipation-simulation.simulation-details.request').toUpperCase()}
                            </button>
                            <div className="scroll--text-value">
                                <div className="scroll--value-original">
                                    <div>{t('anticipation-simulation.simulation-details.original-value')} </div>
                                    <span>
                                        <Currency value={estimate?.value} />
                                    </span>
                                </div>
                                <div className="scroll--value-separator" />

                                <div className="scroll--average-timeframe">
                                    <div style={{ maxWidth: '103px' }}>
                                        {t('anticipation-simulation.simulation-details.average-timeframe')}{' '}
                                    </div>
                                    <span>{FormatUtils.formatDecimal(estimate?.averageTimeframe)}</span>
                                </div>
                                <div className="scroll--value-separator" />
                                <div className="scroll--value-discount">
                                    <div className="scroll--value-itens">
                                        <div>{t('anticipation-simulation.simulation-details.discount-value')}</div>
                                        <div className="scroll--value-img" onClick={props.onExample} />
                                    </div>
                                    <span>
                                        <Currency value={estimate?.discount} />
                                    </span>
                                </div>
                            </div>
                            <div className="scroll--text-terms">
                                {t('anticipation-simulation.simulation-details.fees')}
                                <Currency value={estimate?.fees} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SimulationHeader;
