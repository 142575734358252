import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ProgramConfigurationToProvider } from '../../../model/program';
import programApi from '../../../services/api/programApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { programConfigurationToProviderError, programConfigurationToProviderRequest, programConfigurationToProviderSuccess } from './actions';
import { ProgramConfigurationToProviderActionTypes } from './types';


function* handleProgramConfigurationToProviderRequest(action: ReturnType<typeof programConfigurationToProviderRequest>) {
  try {
    const result: AxiosResponse<ProgramConfigurationToProvider> = yield call(programApi.getConfigurationToProvider, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(programConfigurationToProviderError(mapped));
      return;
    }
    yield put(programConfigurationToProviderSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(programConfigurationToProviderError(mapped));
  }
}

function* watchProgramConfigurationToProviderRequest() {
  yield takeEvery(ProgramConfigurationToProviderActionTypes.PROGRAM_CONFIGURATION_TO_PROVIDER_REQUEST, handleProgramConfigurationToProviderRequest);
}



function* programConfigurationToProviderSaga() {
    yield all([fork(watchProgramConfigurationToProviderRequest)]);
}

export default programConfigurationToProviderSaga;
