import { AxiosResponse } from 'axios';
import { CompanyDefault, CompanyToList } from '../../model/company';
import { api } from './api';

const BASE_URL = 'api/webapp/companies';

export const companies = () => {

    const getDefault = (): Promise<AxiosResponse<CompanyDefault>> => {
        return api.get<CompanyDefault>(`${BASE_URL}/default`);
    }

    const changeDefault = (id: string): Promise<AxiosResponse<CompanyDefault>> => {
        return api.put<CompanyDefault>(`${BASE_URL}/${id}/default`);
    }

    const getMine = (): Promise<AxiosResponse<CompanyToList[]>> => {
        return api.get<CompanyToList[]>(`${BASE_URL}/me`);
    }


    return {
        getDefault,
        changeDefault,
        getMine
    };
};

export default companies();
