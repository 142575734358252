import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ProgramSelectionItem from '../../../../components/giro/program/program-selection-item/program-selection-item';
import { ProgramToList } from '../../../../model/program';

export interface ProgramSelectionListProps {
    programs: ProgramToList[];
    onSelect: (program: ProgramToList) => void;
}

export const ProgramSelectionList = (props: ProgramSelectionListProps) => {
    const { programs, onSelect } = props;
    return (
        <>
            {programs.map(p => (
                <Row key={p.id} style={{ marginBottom: 5 }}>
                    <Col>
                        <ProgramSelectionItem program={p} onSelect={onSelect} />
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default ProgramSelectionList;
