import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CompanyToList } from '../../../model/company';
import HttpStatus from '../../../model/enums/httpStatus';
import companyApi from '../../../services/api/companyApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { getMyCompaniesError, getMyCompaniesSuccess } from './actions';
import { MyCompaniesActionTypes } from './types';


function* handleGetMyCompanies() {
  try {
    const result: AxiosResponse<CompanyToList[]> = yield call(companyApi.getMine);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(getMyCompaniesError(mapped));
      return;
    }
    yield put(getMyCompaniesSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(getMyCompaniesError(mapped));
  }
}

function* watchGetMyCompanies() {
  yield takeEvery(MyCompaniesActionTypes.GET_MY_COMPANIES_REQUEST, handleGetMyCompanies);
}

function* myCompaniesSaga() {
  yield all([fork(watchGetMyCompanies)]);
}

export default myCompaniesSaga;
