import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { organizationChatReducer } from './chat/reducer';
import organizationChatSaga from './chat/sagas';
import { OrganizationChatState } from './chat/types';

export interface OrganizationState {
    readonly organizationChat: OrganizationChatState;
}

export const OrganizationReducer: ReducersMapObject<OrganizationState, AnyAction> = {
    organizationChat: organizationChatReducer
};

export function* organizationSagas() {
    yield all([fork(organizationChatSaga)]);
}
