import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BackButton from '../../../components/buttons/back-button/back-button';
import Loading from '../../../components/loading/loading';
import { ErrorConstants, ErrorType } from '../../../model/enums/error-constants';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../../model/error';
import { ProgramToList } from '../../../model/program';
import { customizeErrorHandling } from '../../../reducer/application/error/actions';
import { requestBatchResetState } from '../../../reducer/batch/request/actions';
import { reqeustEstimateResetState, requestAllEstimateRequest, requestEstimateRequest } from '../../../reducer/estimate/request/actions';
import { useEstimateRequestState, useRootDispatch } from '../../../reducer/hooks';
import ContextRibbon from '../../context-ribbon/context-ribbon';
import './anticipation-simulation.scss';
import AnticipationRequestConfirmation from './components/anticipation-request-confirmation/anticipation-request-confirmation';
import AnticipationSuccess from './components/anticipation-success/anticipation-success';
import SimulationExample from './components/simulation-example/simulation-example';
import SimulationInfo from './components/simulation-info/simulation-info';

export interface AnticipationSimulationLocation {
    program: ProgramToList;
    selected: string[] | undefined;
}

const useEstimateRequest = () => {
    const location = useLocation<AnticipationSimulationLocation>();
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const providerId = location.state?.program.providerId;
        const selected = location.state?.selected;
        if (selected && selected.length > 0) {
            dispatch(requestEstimateRequest({ credits: selected }));
        } else if (providerId) {
            dispatch(requestAllEstimateRequest(providerId));
        }
    }, [location, dispatch]);
};

const useEstimateStateAwareness = () => {
    const state = useEstimateRequestState();
    console.log(state.estimate?.anticipationMaxTime)

    const isLoading = state.status !== HttpRequestStatus.SUCCESS && state.status !== HttpRequestStatus.ERROR;
    const hasError = state.status === HttpRequestStatus.ERROR;

    return { isLoading, hasError, state };
};

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(reqeustEstimateResetState());
            dispatch(requestBatchResetState());
        };
    }, [dispatch]);
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.credit.notfound': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'anticipation-simulation.no-credits.action',
                    handler: history => history.push('/program-selection')
                },
                message: {
                    key: 'anticipation-simulation.no-credits.message'
                }
            }
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

export const AnticipationSimulation = () => {
    const history = useHistory();
    const location = useLocation<AnticipationSimulationLocation>();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    useCleanState();
    useErrorConfiguration();

    const program = location.state?.program;

    const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showExample, setShowExample] = React.useState<boolean>(false);

    const { isLoading, hasError, state } = useEstimateStateAwareness();

    React.useEffect(() => {
        if (!program) {
            history.replace('/dashboard');
        }
    }, [program, history]);

    useEstimateRequest();

    const handleTryAgain = () => {
        const providerId = program?.providerId;
        const selected = location.state?.selected;
        if (selected && selected.length > 0) {
            dispatch(requestEstimateRequest({ credits: selected }));
        } else if (providerId) {
            dispatch(requestAllEstimateRequest(providerId));
        }
    };

    const handleOpenExample = () => setShowExample(true);
    const handleCloseExample = () => setShowExample(false);
    const handleAnticipationRequest = () => setShowConfirmation(true);
    const handleCancelAnticipation = () => setShowConfirmation(false);

    const handleConfirmation = () => {
        setShowConfirmation(false);
        setShowSuccess(true);
    };

    const handleSuccessClose = () => {
        history.push('/dashboard');
    };

    if (!location.state?.program) {
        return <></>;
    }

    if (isLoading) {
        return (
            <div className="anticipation-simulation__container--loading">
                <ContextRibbon />
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="anticipation-simulation__container--error">
                <ContextRibbon />
                <div className="anticipation-simulation__error-message">{t('anticipation-simulation.error-message')}</div>
                <BackButton label={t('global.try-again').toUpperCase()} onClick={handleTryAgain} />
            </div>
        );
    }
    console.log(moment(state.estimate?.anticipationMaxTime).format('HH:mm'))
    console.log(moment(new Date()).format('HH:mm'))
    console.log(moment(new Date()).format('HH:mm') > moment(state.estimate?.anticipationMaxTime).format('HH:mm'))
    return (
        <div className="container__simulation">
            <div className="container__simulation--content">
                <ContextRibbon />
                { !showConfirmation && (
                    <SimulationInfo program={location.state.program} onRequest={handleAnticipationRequest} onExample={handleOpenExample} />
                )}

            </div>
            <AnticipationRequestConfirmation time={state.estimate?.anticipationMaxTime ?? undefined} disabled={state.estimate?.anticipationMaxTime == null ? false : moment(new Date()).format('HH:mm') > moment(state.estimate?.anticipationMaxTime).format('HH:mm')} open={showConfirmation} onCancel={handleCancelAnticipation} onConfirm={handleConfirmation} />
            <AnticipationSuccess open={showSuccess} onClose={handleSuccessClose} />
            <Modal open={showExample} disableAutoFocus disableBackdropClick>
                <div>
                    <SimulationExample onClose={handleCloseExample} program={program} />
                </div>
            </Modal>
        </div>
    );
};

export default AnticipationSimulation;
