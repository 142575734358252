import { Validations, ValidationUtils } from '../../../shared/util/validation-utils';

const PASSWORD_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.user.password.validation.required'
    },
    min_length: {
        predicate: it => !!it?.length && it.length >= 4,
        errorMessage: 'profile.user.password.new-password.validation.min-length'
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'profile.user.password.new-password.validation.max-length'
    }
}
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string,
    match: string
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch> = {
    required: {
        predicate: it => !!it && !!it.match,
        errorMessage: 'profile.user.password.password-confirm.validation.required'
    },
    min_length: {
        predicate: it => !!it?.match?.length && it.match.length >= 4,
        errorMessage: 'profile.user.password.password-confirm.validation.min-length'
    },
    max_length: {
        predicate: it => !it?.match?.length || it.match.length <= 100,
        errorMessage: 'profile.user.password.password-confirm.validation.max-length'
    },
    match: {
        predicate: it => !!it && !!it.password && !!it.match && it.password === it.match,
        errorMessage: 'profile.user.password.password-confirm.validation.match'
    },
}

export const validatePasswordMatch = (value: PasswordMatch) => ValidationUtils.validate(value, PASSWORD_MATCH_VALIDATION);