import Slide from '@material-ui/core/Slide';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { AnticipationSimulationLocation } from '../../../simulation/anticipation-simulation';
import { AnticipationInvoicesLocation } from '../../anticipation-invoices';
import { useInvoiceSelectionContext } from '../invoice-selection/invoice-selection-context';
import './simulate-invoices.scss';

export const SimulateInvoices = () => {
    const location = useLocation<AnticipationInvoicesLocation>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isProviderActivated, isAllSelected, selected, handleUnselectAll } = useInvoiceSelectionContext();

    const handleSimulate = () => {
        if (location.state?.program) {
            const _selected = selected;
            const params: AnticipationSimulationLocation = { program: location.state?.program, selected: _selected };
            history.push('/anticipation/simulation', params);
        }
    };

    return (
        <Slide in={isAllSelected || (selected && selected.length > 0)} direction="up" timeout={750}>
            <div className="container__simulate-invoices">
                <Container>
                    <Row>
                        {isProviderActivated ? (
                            <Col md={{ size: '6', offset: '6' }}>
                                <div className="container__simulate-invoices--buttons">
                                    <button className="container__simulate-invoices--buttons-cancel" onClick={handleUnselectAll}>
                                        {t('anticipation-invoices.invoices.simulation.cancel').toUpperCase()}
                                    </button>
                                    <button className="container__simulate-invoices--buttons-simulate" onClick={handleSimulate}>
                                        {t('anticipation-invoices.invoices.simulation.simulate').toUpperCase()}
                                    </button>
                                </div>
                            </Col>
                        ) : (
                            <div className="container_simulate-invoices--inactivated">
                                {t('anticipation-invoices.invoices.simulation.inactivated')}
                            </div>
                        )}
                    </Row>
                </Container>
            </div>
        </Slide>
    );
};

export default SimulateInvoices;
