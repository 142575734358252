import { Invite, InviteCompany } from '../../../model/invite';
import { BaseStoreState } from '../../baseStoreState';

export enum InviteToAcceptActionTypes {
  INVITE_TO_ACCEPT_REQUEST = '@@inviteToAccept/INVITE_TO_ACCEPT_REQUEST',
  INVITE_TO_ACCEPT_SUCCESS = '@@inviteToAccept/INVITE_TO_ACCEPT_SUCCESS',
  INVITE_TO_ACCEPT_ERROR = '@@inviteToAccept/INVITE_TO_ACCEPT_ERROR',
  INVITE_TO_ACCEPT_COMPANY_UPDATE = '@@inviteToAccept/INVITE_TO_ACCEPT_COMPANY_UPDATE'
}

export interface InviteToAcceptState extends BaseStoreState {
  readonly invite?: Invite;
  readonly company?: InviteCompany;
}
