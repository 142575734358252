import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { ProgramToList } from '../../../../model/program';
import Currency from '../../../format/currency/Currency';
import Idenfitication from '../../../format/identification/Identification';
import ProgramChart from '../chart/program-chart';
import './program-selection-item.scss';

export interface ProgramSelectionItemProps {
    program: ProgramToList;
    onSelect?: (program: ProgramToList) => void;
}

export const ProgramSelectionItem = (props: ProgramSelectionItemProps) => {
    const { t } = useTranslation();
    const { program, onSelect } = props;

    const handleSelection = () => {
        if (onSelect) onSelect(program);
    };

    return (
        <div className="program-selection-item" onClick={handleSelection}>
            <Row>
                <Col md="2">
                    <div className="program-selection-item__program-details">
                        <div className="program-selection-item__program-details--color" style={{ backgroundColor: program.color ?? '' }} />
                        <ProgramChart color={program.color} />
                    </div>
                </Col>
                <Col md="3">
                    <div className="program-selection-item__program-name">{program.name}</div>
                </Col>
                <Col md="3">
                    <div className="program-selection-item__program-buyer">
                        <Idenfitication type={program.buyerType} value={program.buyerIdentification} />
                        <span>{program.buyerName}</span>
                    </div>
                </Col>
                <Col md="4">
                    <div className="program-selection-item__program-credits">
                        <div className="program-selection-item__program-credits--border" />
                        <div className="program-selection-item__program-credits--value">
                            <span>{t('global.program-selection.available-credits').toUpperCase()}</span>
                            <Currency value={program.value} />
                        </div>
                        <div className="program-selection-item__program-credits--image"/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ProgramSelectionItem;
