import React from 'react';
import './selectable-column.scss';

export interface SelectableColumnProps {
    label?: string;
    isLoading?: boolean;
    disabled?: boolean;
    isSelected?: boolean;
    onSelect: () => void;
}

const CheckIcon = ({ isSelected }) => {
    if (isSelected) {
        return <div className="icon-check2" />;
    }
    return <div className="icon-check" />;
};

export const SelectableColumn = (props: SelectableColumnProps) => {
    const handleSelect = () => {
        if (props.isLoading || props.disabled) {
            return;
        }
        props.onSelect();
    };

    return (
        <>
            <div className="selectable-column__items">
                <div className="selectable-column__items--text">{props.label}</div>

                <div className="selectable-column__items--icon" onClick={handleSelect}>
                    <CheckIcon isSelected={props.isSelected} />
                </div>
            </div>
        </>
    );
};

export default SelectableColumn;
