import { action } from 'typesafe-actions';
import { CompanyDefault } from '../../../model/company';
import { GiroWebappError } from '../../../model/error';
import { DefaultCompanyActionTypes } from './types';

export const getDefaultCompanyRequest = () => action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_REQUEST);
export const getDefaultCompanySuccess = (company: CompanyDefault) => action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_SUCCESS, company);
export const getDefaultCompanyError = (error: GiroWebappError) => action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_ERROR, error);

export const changeDefaultCompanyRequest = (id: string) => action(DefaultCompanyActionTypes.CHANGE_COMPANY_DEFAULT_REQUEST, id);
export const changeDefaultCompanySuccess = (company: CompanyDefault) => action(DefaultCompanyActionTypes.CHANGE_COMPANY_DEFAULT_SUCCESS, company);
export const changeDefaultCompanyError = (error: GiroWebappError) => action(DefaultCompanyActionTypes.CHANGE_COMPANY_DEFAULT_ERROR, error);

export const defaultCompanyResetState = () => action(DefaultCompanyActionTypes.DEFAULT_COMPANY_RESET_STATE);
