import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './page-not-found.scss';

export const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="body__page-not-found">
            <Row style={{ height: '84vh' }}>
                <Col md={{ size: '8', offset: '2' }}>
                    <div className="page-not-found--itens">
                        <div className="page-not-found--image" />
                        <div className="page-not-found--text">
                            <span>{t('page-not-found.title')}</span>
                            <div className="page-not-found--subtitle">{t('page-not-found.subtitle')}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PageNotFound;
