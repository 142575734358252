import values from 'lodash/values';
import toPairs from 'lodash/toPairs';

export const isObjectDefined = <T extends any>(obj: T): boolean => obj !== null && obj !== undefined;

export const valuesOfObject = <T extends any>(obj: { [s: string]: T }): T[] => {
    if (obj === null || obj === undefined) return [];
    if (Object.values) {
        return Object.values(obj);
    }
    if (Object.keys) {
        return Object.keys(obj).map(key => obj[key]);
    }
    return values(obj);
};

export const entriesOfObject = <T extends any>(obj: { [s: string]: T }): [string, T][] => {
    if (obj === null || obj === undefined) return [];
    if (Object.entries) {
        return Object.entries(obj);
    }
    if (Object.keys) {
        return Object.keys(obj).map(key => [key, obj[key]]);
    }
    return toPairs(obj);
};
