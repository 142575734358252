import React from 'react';

export enum IdentificationCards {
    EMAIL_CARD = 'EMAIL_CARD',
    NEW_EMAIL_CARD = 'NEW_EMAIL_CARD'
}
export const HIDE_CARDS = 'HIDE_CARDS';
export type IdentificationCardDisplay = IdentificationCards | typeof HIDE_CARDS;
export enum CardVisibility {
    SEEN,
    NOT_SEEN
}
const INITIAL_CARD_RECORD: Record<IdentificationCards, CardVisibility> = {
    EMAIL_CARD: CardVisibility.NOT_SEEN,
    NEW_EMAIL_CARD: CardVisibility.NOT_SEEN
};

export interface IdentificationContextProps {
    showCard: IdentificationCardDisplay;
    cardRecord: Record<IdentificationCards, CardVisibility>;
    updateCards: (display: IdentificationCardDisplay, record?: Partial<Record<IdentificationCards, CardVisibility>>) => void;
}

export const IdentificationContext = React.createContext<IdentificationContextProps>({} as IdentificationContextProps);

export const IdentificationProvider = props => {
    const [showCard, setShowCard] = React.useState<IdentificationCardDisplay>(IdentificationCards.EMAIL_CARD);
    const [cardRecord, setCardRecord] = React.useState<Record<IdentificationCards, CardVisibility>>(INITIAL_CARD_RECORD);
    const updateCards = (display: IdentificationCardDisplay, record?: Partial<Record<IdentificationCards, CardVisibility>>) => {
        if (showCard !== display) {
            setShowCard(display);
        }
        if (record && (record.EMAIL_CARD !== cardRecord.EMAIL_CARD || record.NEW_EMAIL_CARD !== cardRecord.NEW_EMAIL_CARD)) {
            setCardRecord({ ...cardRecord, ...record });
        }
    };

    const value: IdentificationContextProps = {
        showCard,
        cardRecord,
        updateCards
    };
    return <IdentificationContext.Provider value={value}>{props.children}</IdentificationContext.Provider>;
};

export const useIdentification = () => React.useContext(IdentificationContext);
export const withIdentificationContext = () => <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <IdentificationProvider>
            <WrappedComponent {...props} />
        </IdentificationProvider>
    );
};