import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ProviderCreditsActionTypes, ProviderCreditsState } from './types';

export const initialState: ProviderCreditsState = {
  status: HttpRequestStatus.NOOP,
  creditsPage: undefined,
  error: undefined
};

const reducer: Reducer<ProviderCreditsState> = (state = initialState, action): ProviderCreditsState => {
  switch (action.type) {
    case ProviderCreditsActionTypes.PROVIDER_CREDITS_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
    }
    case ProviderCreditsActionTypes.PROVIDER_CREDITS_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, creditsPage: action.payload, error: undefined };
    }
    case ProviderCreditsActionTypes.PROVIDER_CREDITS_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case ProviderCreditsActionTypes.PROVIDER_CREDTIS_RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as providerCreditsReducer };

