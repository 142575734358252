import Modal from '@material-ui/core/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './error-modal.scss';

export interface ErrorModalProps {
    open: boolean;
    message: string;
    messageParams?: { [key: string]: string };
    actionLabel?: string;
    closable?: boolean;
    onClose: () => void;
}

export const ErrorModal: React.FunctionComponent<ErrorModalProps> = props => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="body__modal-anticipation">
                <Modal open={props.open} disableBackdropClick disableEscapeKeyDown>
                    <div id="ref-base-component">
                        <Row style={{ background: 'white', height: '100vh' }} className="align-items-center">
                            <Col md="5" style={{ margin: 'auto' }}>
                                <div className="error-modal__itens">
                                    <div className="error-modal__itens--img" />
                                    <div className="error-modal__itens--text">{t(props.message, props.messageParams)}</div>
                                    {props.closable && (
                                        <button className="error-modal__itens--button" onClick={props.onClose}>
                                            {t(props.actionLabel ?? 'global.closeError').toUpperCase()}
                                        </button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ErrorModal;
