import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ProgramCard from '../program-card';

export const ProgramSelection = () => {
    return (
        <>
            <Row className="container__invoices--cards">
                <Col md="3">
                    <ProgramCard />
                </Col>
                <Col md="3">
                    <ProgramCard />
                </Col>
                <Col md="3">
                    <ProgramCard />
                </Col>
                <Col md="3">
                    <ProgramCard />
                </Col>
            </Row>
        </>
    );
};

export default ProgramSelection;
