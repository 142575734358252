import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { UploadCreditInvoiceState, UploadCreditInvoiceTypes } from './types';

export const initialState: UploadCreditInvoiceState = {
    status: HttpRequestStatus.NOOP,
    invoice: undefined,
    error: undefined
};

const reducer: Reducer<UploadCreditInvoiceState> = (state = initialState, action): UploadCreditInvoiceState => {
    switch (action.type) {
        case UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, invoice: action.payload, error: undefined };
        }
        case UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case UploadCreditInvoiceTypes.UPLOAD_CREDIT_INVOICE_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as uploadCreditInvoiceReducer };
